import i18n from '@/plugins/i18n'
import axios from 'axios'
import Prismic from 'prismic-javascript'

const PrismicApi = Prismic.api(process.env.VUE_APP_API_PRISMIC!)

import { getInstance } from '../../auth/authWrapper'

export default {
  getCMSContent(type: string, tag: string, lang: string = i18n.locale) {
    const response = PrismicApi.then(function get(api) {
      return api.query(
        [
          Prismic.Predicates.at('document.type', type),
          Prismic.Predicates.at('document.tags', [tag])
        ],
        { lang }
      )
    })

    return response
  },

  getAllConfiuredResources() {
    return PrismicApi.then(api => api.getSingle('resources'))
  },

  loadLangContent(lang: string) {
    return PrismicApi.then(function(api) {
      return api
        .query(Prismic.Predicates.at('document.type', 'content'), {
          lang
        })
        .then(response => {
          return Object.assign(
            {},
            ...response.results.map(item => ({
              [item.tags[0]]: Object.assign(
                {},
                {
                  txt: Object.assign(
                    {},
                    ...item.data.labels.map(
                      (elem: {
                        externalkey: { text: string }[]
                        label: { text: string }[]
                      }) => ({
                        [elem.externalkey[0].text]: elem.label[0].text
                      })
                    )
                  ),
                  img: Object.assign(
                    {},
                    ...item.data.images.map(
                      (elem: {
                        externalkey: { text: string }[]
                        alt: { text: string }[]
                        img: { url: string }
                        disabled: { url: string }
                      }) => ({
                        [elem.externalkey[0].text]: Object.assign(
                          {},
                          {
                            alt: elem.alt[0].text,
                            img: elem.img.url,
                            disabled: elem.disabled.url
                          }
                        )
                      })
                    )
                  )
                }
              )
            }))
          )
        })
    })
  },

  async getForUrl(url: string) {
    const token = await getInstance().getTokenSilently()
    const result = axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
        'X-PaccarUsers': getInstance().getTokenHeaders()
      }
    })
    return result
  },
  async get(url: string, tag: string, lang: string) {
    const token = await getInstance().getTokenSilently()
    const auxUrl = url + '/' + tag + '/' + lang
    const result = axios.get(
      process.env.VUE_APP_API + auxUrl.replace('//', '/'),
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'X-PaccarUsers': getInstance().getTokenHeaders()
        }
      }
    )
    return result
  },

  async getWithFetch(url: string) {
    return fetch(process.env.VUE_APP_API + url.replace('//', '/'))
  },

  async post(url: string, tag: string, lang: string, body: unknown) {
    const token = await getInstance().getTokenSilently()
    const auxUrl = url + '/' + tag + '/' + lang
    const result = axios.post(
      process.env.VUE_APP_API + auxUrl.replace('//', '/'),
      body,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'X-PaccarUsers': getInstance().getTokenHeaders()
        }
      }
    )
    return result
  },

  async put(url: string, tag: string, lang: string, body: unknown) {
    const token = await getInstance().getTokenSilently()
    const auxUrl = url + '/' + tag + '/' + lang
    const result = axios.put(
      process.env.VUE_APP_API + auxUrl.replace('//', '/'),
      body,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'X-PaccarUsers': getInstance().getTokenHeaders()
        }
      }
    )
    return result
  },

  async delete(url: string, tag: string, lang: string, body: unknown) {
    const token = await getInstance().getTokenSilently()
    const auxUrl = url + '/' + tag + '/' + lang
    const result = axios.delete(
      process.env.VUE_APP_API + auxUrl.replace('//', '/'),
      {
        data: body,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'X-PaccarUsers': getInstance().getTokenHeaders()
        }
      }
    )
    return result
  },

  async downloadFile(url: string, tag: string, lang: string) {
    const token = await getInstance().getTokenSilently()
    const auxUrl = url + '/' + tag + '/' + lang
    const result = axios.get(
      process.env.VUE_APP_API + auxUrl.replace('//', '/'),
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/octet-stream',
          'X-PaccarUsers': getInstance().getTokenHeaders()
        }
      }
    )
    return result
  },

  authentication(url: string, body: unknown) {
    const auxUrl = url
    const result = axios.post(
      process.env.VUE_APP_API + auxUrl.replace('//', '/'),
      body,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    return result
  },
  async getUserRoleFromTrimble(url: string, body: unknown) {
    const token = await getInstance().getTokenSilently()
    const result = axios
      .post(process.env.VUE_APP_TRIMBLE_API + url, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      .catch(function(error) {
        return error.response
      })
    return result
  },
  async getCustomersFromTrimble(url: string, body: unknown) {
    const token = await getInstance().getTokenSilently()
    const result = axios
      .post(process.env.VUE_APP_TRIMBLE_API + url, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      .catch(function(error) {
        return error.response
      })
    return result
  }
}
