import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        /* ---------------------------------------------------
        name: hex code, // zeplin color name
        --------------------------------------------------- */
        anchor: '#005ca9', // peacock-blue
        primary: '#005ca9', // peacock-blue
        secondary: '#f0f5f9', // pale-grey
        accent: '#1a202c', // dark
        error: '#c53030',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        /* ---------------------------------------------------
        custom name colors
        --------------------------------------------------- */
        dark: '#1a202c', // dark
        slate: '#4a5568', // slate
        'darkgrey-blue': '#2a4365', // dark-grey-blue
        navy: '#2c5282', // light-navy-blue
        peacock: '#005ca9', // peacock-blue
        mid: '#2b6cb0', // mid-blue
        windows: '#3182ce', // windows-blue
        light: '#90cdf4', // lightblue
        ice: '#ebf8ff', // ice-blue
        bluegrey: '#a0aec0', // blue-grey
        cloudy: '#cbd5e0', // cloudy-blue
        'bluegrey-light': '#d7dfea', // light-blue-grey
        'pale-three': '#e2e8f0', // pale-grey-three
        'pale-four': '#edf2f7', // pale-grey-four
        pale: '#f0f5f9', // pale-grey
        'pale-two': '#f7fafc', // pale-grey-two
        reddish: '#c53030', // reddish
        'pink-light': '#fed7d7', // very-light-pink
        'darkgrey-blue-two': '#3c366b', // dark-grey-blue-two
        steel: '#718096', // steel
        oasis: '#feebc8', // yellow/orange, renew
        'duck-egg': '#bee3f8' // duck-egg-blue
      }
    }
  }
})
