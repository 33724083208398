import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import apiCall from '../src/helpers/apiCall'
import api from '../src/helpers/api'
import parseJwt from '@/helpers/parseJwt'
import { mapState, mapMutations } from 'vuex'
import store from '../src/store'
import router from '../src/router'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

export const getInstance = () => instance

export const getRoleClaim = user => {
  if (user) {
    const role = store.state.account.roleDetails.role
    if (role) {
      return role
    }
  }

  return null
}
export const userIsDealerOrOEM = user => {
  if (user) {
    const hasOEMOrDealerAccess =
      store.state.account.roleDetails.hasOEMOrDealerAccess
    if (hasOEMOrDealerAccess) {
      return hasOEMOrDealerAccess
    }
  }

  return false
}
export const getUserEmail = user => {
  let email = ''
  if (user) {
    email = user.email
  }
  return email
}
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      }
    },
    computed: {
      ...mapState('account', [
        'currency',
        'accountDetails',
        'accountInformation',
        'appContext'
      ]),
      ...mapMutations('account', ['setAppContext'])
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        ...options,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: options.clientId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: redirectUri,

        useRefreshTokens: true,

        cacheLocation: 'localstorage'
      })

      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          this.error = null
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()

        if (this.isAuthenticated) {
          const canaccess = await this.canUserRoleAccessApp(
            getUserEmail(this.user)
          )
          if (canaccess) {
            await this.getUserAccountDetails()
          } else {
            router
              .push({
                path: '/no-access',
                query: { errorType: '100' }
              })
              .catch(_ => {
                // do nothing
              })
          }
        }
        this.loading = false
      }
    },
    methods: {
      async loginWithPopup(options, config) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(options, config)
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.error = null
        } catch (e) {
          console.error(e)
          this.error = e
        } finally {
          this.popupOpen = false
        }
      },
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      logout({ domain, clientId, returnTo }) {
        if (domain && clientId && returnTo) {
          this.auth0Client.cacheManager.clearSync()
          this.auth0Client.cookieStorage.remove(
            this.auth0Client.orgHintCookieName
          )
          this.auth0Client.cookieStorage.remove(
            this.auth0Client.isAuthenticatedCookieName
          )
          window.location.assign(
            `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`
          )
        } else {
          this.auth0Client.logout({ returnTo })
        }
      },
      async getFakeUserRoles() {
        const role = getRoleClaim(getInstance().user)
        if (role !== 'OEM' && role !== 'Dealer') {
          // eslint-disable-next-line prettier-vue/prettier
          const res = await  apiCall.get(api.AppAccountsContext, '', '?childtck=tck1A00000230')
          if (res.status == 200) {
            store.commit('account/setAppContext', res.data)
          } else {
          }
        } else {
          const res = await apiCall.get(
            api.AppAccountsContext,
            '',
            '?childtck=1f5d0bac-6a0a-4f44-adbc-c3779084737d'
          )
          if (res.status == 200) {
            store.commit('account/setAppContext', res.data)
          } else {
          }
        }
      },
      async getUserAccountDetails() {
        try {
          const trimbleKey = store.state.account.roleDetails.trimbleEntityKey
          const res = await apiCall.get(
            api.AppAccountsContext,
            '',
            '?childtck=' +
              trimbleKey +
              '&workingtck=' +
              localStorage.getItem('uid') || ''
          )
          if (res.status == 200) {
            const ctxDetails = parseJwt(res.data)
            const model = {
              parentAccount: ctxDetails.ParentAccount,
              parentName: ctxDetails.ParentName,
              childAccount: ctxDetails.ChildAccount,
              workingAccount: ctxDetails.WorkingAccount,
              childId: ctxDetails.ChildId,
              childName: ctxDetails.ChildName,
              parentAccountId: ctxDetails.ParentAccountId,
              workingAccountId: ctxDetails.WorkingAccountId
            }
            store.commit('account/setAppContext', model)
            store.commit('account/setAppToken', res.data)
            store.commit('account/setAccountOnZuora', true)
            //save language to local storage
            if (ctxDetails.ChildAccountLanguage.toLowerCase() == 'en-us') {
              localStorage.setItem('locale', 'en-gb')
            } else {
              localStorage.setItem(
                'locale',
                ctxDetails.ChildAccountLanguage.toLowerCase()
              )
            }
            //end
          } else {
            store.commit('account/setAccountOnZuora', false)
            router
              .push({
                path: '/no-access',
                query: { errorType: '102' }
              })
              .catch(_ => {
                // do nothing
              })
          }
        } catch (e) {
          store.commit('account/setAccountOnZuora', false)
          router
            .push({
              path: '/no-access',
              query: { errorType: '102' }
            })
            .catch(_ => {
              // do nothing
            })
        }
      },
      async canUserRoleAccessApp(email) {
        let hasWebShopAccess = false
        let hasOEMOrDealerAccess = false
        try {
          let userRoleDetails = {}
          const res = await apiCall.getUserRoleFromTrimble(
            api.GetUserRolesFromTrimble,
            { email: email }
          )
          const roles = [
            'Customer Administrator',
            'Dealer Admin',
            'Dealer Owner Admin',
            'Dealer Region Admin',
            'OEM Policy'
          ]
          //for test probably remove later. This allows Brian to login
          roles.push('OEM Administrator')
          if (res.status == 200) {
            userRoleDetails = res.data

            if (userRoleDetails) {
              const role = userRoleDetails.role.toString()
              if (role) {
                localStorage.setItem('role', role)
                hasWebShopAccess = roles.includes(role)
                if (
                  hasWebShopAccess &&
                  (role.toLowerCase().includes('dealer') ||
                    role.toLowerCase().includes('oem'))
                ) {
                  hasOEMOrDealerAccess = true
                }
              }
            }
          } else {
            router
              .push({
                path: '/no-access',
                query: { errorType: '101' }
              })
              .catch(_ => {
                // do nothing
              })
          }
          const roleData = {
            ...userRoleDetails,
            hasWebShopAccess: hasWebShopAccess,
            hasOEMOrDealerAccess: hasOEMOrDealerAccess
          }
          store.commit('account/setRoleDetails', roleData)
        } catch (e) {
          router
            .push({
              path: '/no-access',
              query: { errorType: '101' }
            })
            .catch(_ => {
              // do nothing
            })
        }
        return hasWebShopAccess
      },
      getTokenHeaders() {
        return store.state.account.detailsToken || ''
      },
      getPaccarUserHeaders() {
        return {
          UserAccountNumber: store.state.account.appContext.childAccount,
          ParentAccountNumber: store.state.account.appContext.parentAccount,
          WorkingAccountNumber:
            store.state.account.appContextWorkingAccount.number,
          UserAccountId: store.state.account.appContext.childId,
          ParentAccountId: store.state.account.appContext.parentAccountId
        }
      }
    }
  })

  return instance
}

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
