import Vue from 'vue'
import VueI18n from 'vue-i18n'
import apiCalls from '@/helpers/apiCall'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en-gb',
  fallbackLocale: 'en-gb',
  silentTranslationWarn: true
})

export default i18n

function setI18nLanguage(locale: string) {
  i18n.locale = locale
  const htmlEL = document.querySelector('html') as HTMLElement
  htmlEL.setAttribute('lang', locale)
  localStorage.setItem('locale', locale)
}

export async function loadLanguage(locale: string = i18n.locale) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const LocaleMessages: any = await apiCalls.loadLangContent(locale)
  i18n.setLocaleMessage(locale, LocaleMessages)
  setI18nLanguage(locale)
}
