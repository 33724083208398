export const roleRatePlanService = {
  userRatePlan(role: string) {
    if (role.includes('Customer Administrator')) {
      return 'Customer'
    } else if (role.includes('OEM')) {
      return 'Policy'
    } else if (role.includes('Dealer')) {
      return 'Dealer'
    }
    return 'unknown rate plan'
  }
}
