<template>
  <v-app>
    <v-snackbar
      v-model="snackbar.showing"
      tile
      elevation="20"
      height="auto-height"
      multi-line="multi-line"
      :top="snackbar.position"
      :right="snackbar.right"
      :left="snackbar.left"
      :timeout="snackbar.timeout"
      :color="snackbar.type"
    >
      <v-layout
        align-center
        pr-4
      >
        <v-icon
          class="pr-3"
          dark
          large
        >
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div v-html="snackbar.text" />
        </v-layout>
        <div class="pl-6">
          <v-btn
            v-if="snackbar.timeout === 0"
            icon
            @click="snackbar.showing = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-snackbar>
    <v-progress-circular
      v-if="isLoading"
      class="loader-page"
      indeterminate
      color="primary"
      :size="50"
      :width="5"
    />

    <template v-else>
      <AppHeader />
      <router-view />
      <CookiesSnackbar />
    </template>  
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import CookiesSnackbar from '@/components/CookiesSnackbar.vue'
import { mapState, mapGetters } from 'vuex'
// import { component } from 'vue/types/umd'

export default {
  name: 'App',
  components: {
    AppHeader,
    CookiesSnackbar
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState('notification', ['snackbar']),
    ...mapGetters('notification', ['isShowing']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    authLoaded() {
      return this.$auth.loading
    }
  },
  watch: {
    authLoaded(newValue) {
      this.isLoading = newValue
    },
    isShowing(newState) {
      if (newState === false) {
        if (typeof this.snackbar.callback == 'function') {
          this.snackbar.callback()
        }
      }
    }
  },
  created() {
    this.isLoading = this.$auth.loading
  }
}
</script>

<style lang="scss">
@import './sass/app.scss';

.loader-page {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
