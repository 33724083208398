//#region #Paccar-POC
import store from '../store'

const basketStorageKey = 'local_basket'

const mapToBasketItem = (itemsToAdd: Array<any>, currentBasket: Array<any>) => {
  itemsToAdd.forEach(item => {
    let currProd = currentBasket.find(pr => pr.productID == item.productID)
    if (!currProd) {
      currProd = {
        metaData: item.metaData,
        productID: item.productID,
        rateplans: [],
        productName: item.productName
      }
      currentBasket.push(currProd)
    }

    let currPlan = currProd.rateplans.find(
      (rp: any) => (rp.productRateplanID = item.productRateplanID)
    )
    if (!currPlan) {
      currPlan = {
        productRateplanID: item.productRateplanID,
        rateplanID: item.rateplanID,
        subscriptions: []
      }

      currProd.rateplans.push(currPlan)
    }

    const currSubs = currPlan.subscriptions.find(
      (subs: any) =>
        subs.subscriptionID == item.subscriptionID &&
        subs.productRateplanChargeID == item.productRateplanChargeID
    )
    if (!currSubs) {
      currPlan.subscriptions.push({
        productRateplanChargeID: item.productRateplanChargeID,
        subscriptionID: item.subscriptionID,
        productRateplanID: item.productRateplanID,
        ratePlanID: item.rateplanID,
        addSubscription: item.addSubscription == 1,
        isRenew: item.isRenew,
        metaData: item.metaData
      })
    }
  })
}

class BasketService {
  async sendToCheckout(itemsToAdd: Array<any>) {
    const basket = JSON.parse(
      localStorage.getItem(basketStorageKey) || '{"products":[]}'
    )

    mapToBasketItem(itemsToAdd, basket.products)

    localStorage.setItem(basketStorageKey, JSON.stringify(basket))

    // console.log('current basket', basket)

    store.commit('checkout/setCheckout', basket)
    store.commit('checkout/addTotalCheckout', itemsToAdd.length)

    return {
      success: true
    }
  }
  async loadCheckout() {
    const basket = JSON.parse(
      localStorage.getItem(basketStorageKey) || '{"products":[]}'
    )
    return basket
  }

  async removeItem(item: any) {
    const basket = JSON.parse(
      localStorage.getItem(basketStorageKey) || '{"products":[]}'
    )
    const prod = basket.products.find(
      (basketItem: any) => basketItem.productID == item.productID
    )

    if (prod) {
      let rp = prod.rateplans.find(
        (rp: any) => rp.productRateplanID == item.productRateplanID
      )

      if (rp === undefined) {
        rp = prod.rateplans.find((rp: any) => rp.rateplanID == item.rateplanID)
      }

      if (rp) {
        rp.subscriptions = rp.subscriptions.filter(
          (subs: any) => subs.subscriptionID != item.subscriptionID
        )
      }

      if (rp.subscriptions.length === 0) {
        prod.rateplans = prod.rateplans.filter(
          (_rp: any) => _rp.subscriptions.length > 0
        )
      }

      if (prod.rateplans.length == 0) {
        basket.products = basket.products.filter(
          (_basketItem: any) => _basketItem.rateplans.length > 0
        )
      }
    }
    localStorage.setItem(basketStorageKey, JSON.stringify(basket))

    store.commit('checkout/setCheckout', basket)

    return { status: basket.products.length > 0 ? 200 : 204 }
  }

  async clearBasket() {
    localStorage.setItem(basketStorageKey, '{"products":[]}')
    store.commit('checkout/cleanCart')
    return { status: 200 }
  }

  async clearService(serviceId: any) {
    const basket = JSON.parse(localStorage.getItem(basketStorageKey) || '{}')
    const productIndex = basket.products.findIndex(
      (res: any) => res.productID == serviceId.id
    )
    if (productIndex != -1) {
      basket.products.splice(productIndex, 1)
    }
    // basketsarray = Array.prototype.slice.call(basket)
    localStorage.setItem(basketStorageKey, JSON.stringify(basket))
    store.commit('checkout/setCheckout', basket)
    return { status: basket.products.length > 0 ? 200 : 204 }
  }

  async handleFailedSubs(failedSubs: string[]) {
    const basket = await this.loadCheckout()

    basket.products.forEach((product: any) => {
      product.rateplans.forEach((ratePlan: any) => {
        ratePlan.subscriptions.forEach((cartSubs: any) => {
          if (failedSubs.includes(cartSubs.subscriptionID)) return

          this.removeItem({
            productID: product.productID,
            productRateplanID: ratePlan.productRateplanID,
            rateplanID: ratePlan.rateplanID,
            subscriptionID: cartSubs.subscriptionID
          })
        })
      })
    })

    store.commit('checkout/setTotalCheckout', failedSubs.length)
  }

  // async removeSuccessfulSubscriptionsFromCheckout(
  //   checkoutCollection: any,
  //   toRepeatSubs: any
  // ) {
  //   // checkoutCollection.forEach((x: string) => {
  //   //   if (
  //   //     toRepeatSubs.subscriptionDetails.find((tr: any) => {
  //   //       return x === tr.Number
  //   //     })
  //   //   ) {
  //   //     return
  //   //   }
  //   //   this.removeItem({
  //   //     subscriptionID: x.Number,
  //   //     productRateplanID: x.RatePlans[0].PlanId,
  //   //     productID: x.RatePlans[0].ProductId
  //   //   })
  //   // })

  //   toRepeatSubs.subscriptionsDetails.forEach((x: any) => {
  //     debugger
  //     if (
  //       checkoutCollection.find((tr: any) => {
  //         return tr === x.Number
  //       })
  //     ) {
  //       debugger
  //       this.removeItem({
  //         subscriptionID: x.Number,
  //         productRateplanID: x.RatePlans[0].PlanId,
  //         productID: x.RatePlans[0].ProductId
  //       })
  //     }
  //     debugger
  //     return
  //   })
  // }
}

const BasketServiceSingleton = new BasketService()
export default BasketServiceSingleton
