<template>
  <v-container
    class="main"
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
      >
        <h1>{{ $t('error.txt.title') }}</h1>

        <v-img
          :src="$t('error.img.error.img')"
          width="200"
          class="my-8 mx-auto"
        />
        <p>{{ $t('error.txt.text') }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<style lang="scss">
.main {
  text-align: center;
  h1 {
    font-size: 3rem;
    font-weight: normal;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: center;
  }
  p {
    font-size: 0.875rem;
    color: var(--v-slate-base);
    text-align: center;
  }
  .btn {
    background-color: #3182ce !important;
    color: white !important;

    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
