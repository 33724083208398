import Vue from 'vue'
import Vuex from 'vuex'
// import apiCalls from '@/helpers/apiCall'
// import api from '@/helpers/api'

Vue.use(Vuex)

const state = {
  products: []
}
const mutations = {
  setProducts(state, products) {
    state.products = products
  }
}
const getters = {
  getCatalogProducts: state => {
    return state.products
  }
}
// const actions = {
//   async getVehicles({ commit }) {
//     const res = await apiCalls.get(
//       api.TrucksGetAll.replace('//', '/'),
//       '',
//       `?pageIndex=1&productId=&accountNumber=`
//     )
//     commit('setTrucks', res.data)
//   }
// }

export default {
  namespaced: true,
  state,
  mutations,
  // actions,
  getters
}
