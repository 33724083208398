<template>
  <div class="header">
    <v-container>
      <v-toolbar
        class="toolbar"
        name="toolbar"
        flat
        height="84px"
      >
        <router-link :to="{ name: 'home'}">
          <v-img
            class="logo-img"
            :src="require('@/assets/imgs/logo.svg')"
            contain
            width="200px"
          />
        </router-link>
        <v-spacer />
        
        <div class="d-none d-lg-flex">
          <v-toolbar-items
            :class="[hideMenu ? 'toolbar_menu_hide' : '', 'toolbar_menu']"
          >
            <template v-for="item in navLinks">
              <span
                v-if="item.to"
                :key="`A${item.id}`"
                class="item"
              >
                <router-link
                  :to="item.to"
                >
                  {{ item.title }}
                </router-link>
              </span>
              <span
                v-else
                :key="`B${item.id}`"
                class="item"
                v-html="item.title"
              />
            </template>
          </v-toolbar-items>
          <v-menu
            v-model="showAccountMenu"
            offset-y
            close-on-click
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
              <section
                class="toolbar_account"
                :class="[hideMenu ? 'toolbar_account_hide' : '', 'toolbar_account']"
                v-bind="{attrs}"
                v-on="on"
              >
                <v-divider
                  class="d-none d-lg-inline-block mx-4 my-0 align-self-center"
                  inset
                  vertical
                />
                <div class="name">
                  <!-- <router-link :to="{ name: 'payment_information'}"> -->
                  <a class="d-inline pa-0 var(--v-pale-base) black--text">
                    <strong>{{ companyName }}</strong>
                  </a>
                  <a class="d-inline pa-0 var(--v-pale-base) black--text">
                    {{ nickName }} - {{ role }}
                  </a>
                  <!-- </router-link> -->
                  <span
                    v-if="dealersOrOems"
                    class="d-inline pa-2 deep-purple accent-4 white--text"
                  >
                    {{ workingAccount }}
                  </span>
                </div>
                <!-- <router-link :to="{ name: 'payment_information'}"> -->
              
                <v-icon
                  class="avatar"
                  color="primary"
                  size="38"
                >
                  mdi-account
                </v-icon>
                <!-- <v-avatar
                  v-else
                  color="blue"
                >
                  <span class="white--text text-h5">{{ nickName | avatarNickname }}</span>
                </v-avatar> -->
                <v-divider
                  class="d-none d-lg-inline-block mx-4 my-0 align-self-center"
                  inset
                  vertical
                />
                <!-- </router-link> -->
              </section>
            </template>
            <v-list>
              <v-list-item
                @click="handleMenuClick('payment_information')"
              >
                <v-list-item-title>{{ $t('header.txt.my_account') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="handleMenuClick('payment_method')"
              >
                <v-list-item-title>{{ $t('header.txt.payment_method') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="logout('logout')"
              >
                <v-list-item-title>{{ $t('header.txt.log_out') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="dealersOrOems"
                @click="switchAccount('switchAccount')"
              >
                <v-list-item-title>{{ 'Switch Account' }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
       
        <v-select
          v-model="locale"
          class="change-lang"
          background-color="var(--v-pale-base)"
          color="slate"
          :append-icon="'mdi-chevron-down'"
          :items="locales"
          :menu-props="{ offsetY: true }"
          dense
          filled
          return-object
          @change="showLanguagePreferenceDialog"
        />
        <v-divider
          :class="[hideMenu ? 'v-divider_hide' : '', 'mx-4 my-0 align-self-center']"
          inset
          vertical
        />
        <div
          v-if="totalItems>0"
          class="toolbar_basket"
          :class="[hideMenu ? 'toolbar_basket_hide' : '', 'toolbar_basket']"
          @click.stop="drawerCheckout = !drawerCheckout; openDrawer()"
        >
          <v-badge
            class="basket-badge"
            color="white"
            overlap
            bordered
            :content="totalItems"
            :value="totalItems"
          >
            <img
              :src="require('@/assets/imgs/cart.svg')"
              class="basket-icon"
            >
          </v-badge>
        </div>
        <div
          v-if="totalItems<=0"
          class="toolbar_basket"
          :class="[hideMenu ? 'toolbar_basket_hide' : '', 'toolbar_basket']"
        >
          <v-badge
            class="basket-badge"
            color="white"
            overlap
            bordered
            :content="totalItems"
            :value="totalItems"
          >
            <img
              :src="require('@/assets/imgs/cart-disabled.svg')"
              class="basket-icon"
            >
          </v-badge>
        </div>
        <v-divider
          class="d-lg-none ml-4 my-0 align-self-center"
          inset
          vertical
        />
        <v-app-bar-nav-icon
          class="d-block d-lg-none"
          @click.stop="drawerMenu = !drawerMenu"
        />
      </v-toolbar>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('header.txt.language_preference') }}
          </v-card-title>
          <v-card-text>{{ $t('header.txt.language_preference_warning') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary darken-1"
              text
              @click="acceptLanguageChange"
            >
              {{ $t('header.txt.confirm') }}
            </v-btn>
            <v-btn
              color="primary darken-1"
              text
              @click="cancelLanguageChange"
            >
              {{ $t('header.txt.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-navigation-drawer
      v-model="drawerMenu"
      fixed
      temporary
      right
      class="drawer"
    >
      <v-list-item class="drawer_account">
        <router-link :to="{ name: 'payment_information'}">
          <v-icon
            class="HeaderAccount_avatar"
            color="primary"
            size="20"
          >
            mdi-account
          </v-icon>
        </router-link>
        <div class="name">
          <router-link :to="{ name: 'payment_information'}">
            <span class="name-primary">
              {{ childAccount }}
            </span>
          </router-link>
          <span class="name-primary">
            {{ companyName }}
          </span>
        </div>
      </v-list-item>

      <v-divider />
      <v-list
        dense
        class="drawer_menu"
      >
        <v-list-item
          v-for="item in navLinks"
          :key="item.id"
          link
        >
          <v-list-item-content
            v-if="item.to"
          >
            <router-link
              :to="item.to"
              class="item"
            >
              {{ item.title }}
            </router-link>
          </v-list-item-content>
          <v-list-item-content
            v-else
            v-html="item.title"
          />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="drawerCheckout"
      v-model="drawerCheckout"
      fixed
      temporary
      right
      width="auto"
      class="checkout"
    >
      <AppCheckout
        :key="checkoutkey"
        @close-drawer="closeDrawer"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import AppCheckout from '@/components/AppCheckout.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import { loadLanguage } from '@/plugins/i18n'
import {
  getInstance,
  getRoleClaim,
  userIsDealerOrOEM
} from '../../auth/authWrapper'
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import store from '../../src/store'
export default {
  name: 'AppHeader',
  components: {
    AppCheckout
  },
  data() {
    return {
      role: '',
      drawerMenu: null,
      drawerCheckout: false,
      checkout: [],
      totalCheckout: 0,
      checkoutkey: 0,
      items: [],
      nickName: '',
      showAccountMenu: false,
      locale: localStorage.getItem('locale') || 'en-gb',
      initialLocale: localStorage.getItem('locale') || 'en-gb',
      // locales: ['en-gb', 'es-es'],
      locales: ['en-gb'],
      hideMenu: false,
      dialog: false
    }
  },
  computed: {
    dealersOrOems() {
      const user = getInstance().user
      if (!user) {
        return false
      }
      return userIsDealerOrOEM(user)
    },
    workingAccount() {
      // console.log('WORKING ACCOUNT HEADER', this.appContextWorkingAccount)
      if (this.appContextWorkingAccount && this.appContextWorkingAccount.name) {
        return this.appContextWorkingAccount.name
      }
      return '---'
    },
    childAccount() {
      if (this.appContext && this.appContext.childName) {
        return this.appContext.childName
      }
      return '---'
    },
    companyName() {
      // console.log('basicInfo', this.accountInformation)
      if (this.appContext && this.appContext.parentName) {
        return this.appContext.parentName
      }
      return '---'
    },
    ...mapState('checkout', {
      totalItems: state => state.totalCheckout
    }),
    ...mapState('account', [
      'currency',
      'accountDetails',
      'accountInformation',
      'appContextWorkingAccount',
      'payload',
      'appContext'
    ]),

    // ...mapState('locale', ['locales']),
    navLinks() {
      return [
        {
          id: 'information',
          to: null,
          title: this.$t('header.txt.information')
        },
        {
          id: 'my-invoices',
          to: { name: 'invoices' },
          title: this.$t('header.txt.my_invoices')
        }
      ]
    }
  },

  async created() {
    const meta = this.$route.meta
    if (meta) {
      const hideMenu = meta?.hideMenu
      if (hideMenu) {
        this.hideMenu = hideMenu
      }
    }

    const user = getInstance().user
    if (user) {
      this.nickName = user.nickname
      this.role = getRoleClaim(user)
    }

    if (
      this.$router.currentRoute.name !== 'search' &&
      !localStorage.getItem('uid')
    ) {
      await this.getAccountDetails()
    }

    // await this.getCurrencyInfo()
    await this.getCheckout()
    await this.setTotalItemsCheckout()
    await this.switchLocale()
    // await this.getPayload(localStorage.getItem('userToken'))
    // await this.setUserInformation()
  },
  methods: {
    ...mapMutations('checkout', ['setTotalItemsCheckout']),
    ...mapActions('checkout', ['getCheckout']),
    ...mapActions('account', [
      'getAccountDetails',
      'getCurrencyInfo',
      'getPayload'
    ]),

    async switchLocale() {
      const userLanguage = this.locale
      if (userLanguage !== this.$i18n.locale) {
        await loadLanguage(userLanguage)
      }
    },
    showLanguagePreferenceDialog() {
      this.dialog = true
    },
    async acceptLanguageChange() {
      localStorage.setItem('locale', this.locale)
      await this.switchLocale()
      this.initialLocale = this.locale
      //update zuora
      const zuoraLang =
        this.locale == 'en-gb' ? 'EN-US' : this.locale.toUpperCase()
      const trimbleKey = store.state.account.roleDetails.trimbleEntityKey
      apiCalls.put(api.AccountUpdate + '/' + trimbleKey, '', '', {
        // eslint-disable-next-line prettier-vue/prettier
        'language__c': zuoraLang
      })
      //end
      this.dialog = false
    },
    async cancelLanguageChange() {
      this.locale = this.initialLocale
      this.dialog = false
    },
    closeDrawer() {
      this.drawerCheckout = false
    },
    openDrawer() {
      this.checkoutkey = Math.random()
    },
    // setUserInformation() {
    //   this.userName = `${this.accountDetails.billToContact.lastName} ${this.accountDetails.billToContact.firstName}`
    //   this.companyName = this.accountInformation.basicInfo.name
    // },
    handleMenuClick(viewName) {
      this.$router.push({
        name: viewName
      })
    },
    logout() {
      localStorage.removeItem('local_basket')
      localStorage.removeItem('vuex')
      localStorage.removeItem('uid')
      this.$auth.logout({
        domain: process.env.VUE_APP_AUTH0_LOGOUT_DOMAIN,
        clientId: process.env.VUE_APP_AUTH0_LOGOUT_CLIENT_ID,
        returnTo: encodeURIComponent(window.location.origin)
      })
      this.$router.push({ path: '/' })
    },
    switchAccount() {
      //localStorage.removeItem('uid')
      this.$router.push({ name: 'search' })
    }
  }
}
</script>

<style lang="scss">
.header {
  .toolbar {
    background-color: var(--v-pale-base) !important;
    border-bottom: 1px solid var(--v-bluegrey-light-base) !important;
    hr {
      height: 24px;
    }
    &_menu {
      align-items: center;
      .item {
        margin-right: 33px;
        font-size: 0.75rem;
        font-weight: bold;
        color: var(--v-peacock-base) !important;
        text-transform: uppercase;
      }

      a {
        text-decoration: none;
      }
    }
    &_account {
      display: flex;
      align-items: flex-end;
      .name {
        display: flex;
        flex-direction: column;
        text-align: right;
        font-style: normal;
        font-size: 0.6rem;
        margin-right: 16px;
        text-transform: uppercase;
        padding: 2px 0;
        &-primary {
          font-weight: bold;
          color: black;
          text-decoration: none;
        }
        &-secondary {
          color: var(--v-dark-base);
        }
      }
      .avatar {
        align-self: center;
        text-decoration: none;
      }
      a {
        text-decoration: none;
      }
    }
    &_basket {
      cursor: pointer;
      .basket-badge {
        .basket-icon {
          width: 32px;
          height: 24px;
        }
        .v-badge__badge {
          color: var(--v-dark-base) !important;
          &::after {
            border-color: var(--v-bluegrey-base) !important;
          }
        }
      }
    }
    &_items {
      padding: 0;
    }
    .change-lang {
      max-width: 120px;
      height: 32px;
      font-size: 0.875rem;

      .v-input {
        &__slot {
          min-height: 32px !important;
        }

        &__append-inner {
          margin-top: 5px !important;
        }
      }
    }
    .logo-img {
      width: 400px;
      height: 48px;
    }
  }
  .drawer {
    &_menu {
      align-items: center;
      a {
        font-size: 0.75rem;
        font-weight: bold;
        color: var(--v-peacock-base) !important;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    &_account {
      display: flex;
      .name {
        display: flex;
        flex-direction: column;
        font-style: italic;
        font-size: 0.75rem;
        margin-left: 10px;
        padding: 2px 0;
        &-primary {
          font-weight: bold;
          color: var(--v-peacock-base);
          text-decoration: underline;
        }
        &-secondary {
          color: var(--v-dark-base);
        }
      }
    }
  }
  .checkout {
    max-width: 720px;
    min-width: 720px;
  }
  .toolbar_menu_hide {
    display: none !important;
  }
  .toolbar_account_hide {
    display: none !important;
  }
  .d-none .d-lg-flex {
    display: none !important;
  }
  .toolbar_basket_hide {
    display: none !important;
  }
  .v-divider_hide {
    display: none !important;
  }
}
</style>