import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  snackbar: {
    showing: false,
    type: 'success',
    callback: null,
    text: ''
  }
}

const mutations = {
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = snackbar
  }
}

const actions = {
  async setSnackbar({ commit }, snackbar) {
    snackbar.showing = true
    commit('SET_SNACKBAR', snackbar)
  }
}
const getters = {
  isShowing() {
    return state.snackbar.showing
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
