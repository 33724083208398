import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import { Auth0Plugin } from '../auth'
import { loadLanguage } from './plugins/i18n'
import store from './store'
import router from './router'
import moment from 'moment'
import * as filters from '@/helpers/filters'

Vue.config.productionTip = false

Vue.prototype.moment = moment

// filters
Object.keys(filters).forEach((key: string) => {
  Vue.filter(key, (filters as any)[key])
})

// const locale = 'en-gb'
const locale = localStorage.getItem('locale') || 'en-gb'
loadLanguage(locale)

const domain = process.env.VUE_APP_AUTH0_DOMAIN
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
const audience = process.env.VUE_APP_AUTH0_AUDIENCE

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState: any) => {
    router
      .push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
      .catch(_ => {
        // console.log('failure', failure)
      })
  }
})

Vue.filter('avatarNickname', function(value: string) {
  if (!value) return ''
  return value.charAt(0).toUpperCase()
})

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
