s<template>
  <v-col
    cols="12"
    :sm="colSize"
    class="py-0"
  >
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <span class="caption">{{ caption }}</span>
        <span
          v-if="mandatory"
          class="mandatory"
        > *</span>
      </v-col>
      <v-col
        cols="12"
        class="field py-0"
      >
        <v-text-field
          v-model="mValue"
          :backup-value="backupValue"
          :placeholder="placeholder"
          :name="name"
          :mandatory="mandatory"
          :show-info="showInfo"
          :checking-vat="checkingVat"
          :readonly="isReadonly"
          :class="!isReadonly ?'textfield': 'disabled-field'"
          :tooltip-title="tooltipTitle"
          :tooltip-text="tooltipText"
          :background-color="fieldValidation ? 'pale-two' : 'pink-light'"
          :error-messages="fieldValidation ? '' : errorMessage"
          autocomplete="off"
          maxlength="255"
          filled
          rounded
          dense
          lazy
          @input="onValueChange($event, name)"
        >
          <template v-slot:append>
            <v-icon
              v-if="name==='vatNumber' && mValue.length > 0 && fieldValidation && !checkingVat"
              slot="append"
              size="18"
              color="green"
              :title="$t('payment_information.txt.vat_checked')"
            >
              mdi-check
            </v-icon>
            <v-progress-circular
              v-if="checkingVat"
              size="16"
              width="2"
              color="peacock"
              indeterminate
            />
          </template>
        </v-text-field>
        <v-tooltip
          v-if="showInfo"
          content-class="info-tooltip"
          right
        >
          <template v-slot:activator="{ on }">
            <v-icon
              class="info-tooltip d-none d-sm-block"
              v-on="on"
            >
              mdi-information-variant
            </v-icon>
          </template>
          <v-card
            elevation="0"
            flat
          >
            <span class="tooltip-title">{{ tooltipTitle }}</span>
            <p class="tooltip-text">
              {{ tooltipText }}
            </p>
          </v-card>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'PaymentInformationInput',
  props: {
    caption: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    tooltipTitle: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    backupValue: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: null
    },
    colSize: {
      type: Number,
      default: 1
    },
    fieldValidation: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    checkingVat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mValue: this.value || ''
    }
  },
  methods: {
    onValueChange(event) {
      this.$emit('valueChange', event)
    }
  }
}
</script>

<style lang="scss">
button,
input,
select,
textarea {
  font-size: 0.875rem;
}
.caption {
  font-size: 1.375rem;
  font-weight: bold !important;
  color: var(--v-peacock-base) !important;
  text-transform: uppercase;
}
.mandatory {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--v-dark-base) !important;
}
.disabled-field {
  input {
    color: darkgray !important;
  }
}
.field {
  position: relative;
  .textfield {
    .v-input__slot {
      border: 1px solid var(--v-pale-three-base) !important;
    }
    .placeholder {
      font-size: 0.75rem;
      color: var(--v-reddish-base);
    }
  }

  .info-tooltip {
    color: var(--v-bluegrey-base) !important;
    border-radius: 8px;
    border: solid 1px;
    height: 16px;
    width: 16px;
    font-size: 0.875rem !important;
    position: absolute;
    top: 12px;
    right: -12px;
  }
}
.info-tooltip {
  &.v-tooltip__content {
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2);
    background: white !important;
    max-width: 300px;
    color: white;
    border-radius: 4px;
    display: inline-block;
    padding: 16px;
    position: absolute;
    text-transform: initial;
    width: auto;
    opacity: 1;
    pointer-events: none;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid white;
      position: absolute;
      top: calc(50% - 12px);
      left: -12px;
      transform: rotate(180deg);
    }
    .tooltip-title {
      font-size: 1rem;
      font-weight: bold;
      color: var(--v-dark-base);
    }
    .tooltip-text {
      font-size: 0.875rem;
      color: var(--v-slate-base);
      text-align: left;
    }
  }
}
</style>