var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service-detail-list"},[_c('v-row',{staticClass:"page-title"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"d-inline pa-3 accent-3 white--text"},[_vm._v(" "+_vm._s(_vm.service.name)+" ")])])],1),_c('ServiceDetailSearch',{attrs:{"service-name":_vm.service.name},on:{"textToSearch":_vm.textToSearchReceived,"selectAllByPeriod":_vm.selectAllByPeriod,"filterByStatus":_vm.filterByStatus,"filterByPeriod":_vm.filterByPeriod,"sortBy":_vm.sortByReceived}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredList,"loader-height":"2","loading":_vm.loading,"loading-text":"Loading vehicles...","footer-props":{
      'items-per-page-options': [10, 25, 50]
    },"item-key":"vin","show-select":"","single-select":false,"item-class":_vm.evaluateToSunscreen},on:{"update:page":_vm.onTablePageUpdate,"update:items-per-page":_vm.onTableRowCountUpdate,"toggle-select-all":_vm.setSelectedTrucks},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
return [_c('v-checkbox',{attrs:{"value":isSelected,"disabled":_vm.disabledVehicleInCart(item.subscriptionNumber)},on:{"change":function($event){return _vm.setSelectedTruck(item)}}})]}},{key:"item.vin",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"vehicle-name"},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"item.unitNumber",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.unitNumber)+" ")])]}},{key:"item.subscriptionEndDate",fn:function(ref){
    var item = ref.item;
return [(_vm.filterStatus.key === 'Renew Trucks')?_c('v-tooltip',{attrs:{"content-class":"expiration-tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(_vm.isExpired(item.subscriptionEndDate))?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v(" mdi-alert-circle-outline ")]):_c('v-icon',_vm._g({attrs:{"color":"yellow"}},on),[_vm._v(" mdi-alert-outline ")]),_c('span',[_vm._v(" "+_vm._s(item.subscriptionEndDate)+" ")])]}}],null,true)},[_c('span',{staticClass:"tooltip-span"},[_vm._v(_vm._s(_vm.isExpired(item.subscriptionEndDate) ? 'This subscription is expired.' : 'This subscriptin is expiring soon.'))])]):_c('span',[_vm._v(" "+_vm._s(item.subscriptionEndDate)+" ")])]}},{key:"item.invoiceOwner",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.invoiceOwner)+" ")])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"d-none d-sm-block text-right align-self-center",attrs:{"md":item.type != 'Month' &&
            item.type != 'Annual' &&
            item.type != 'Three_Years'
            ? 2
            : 2}},[_c('v-btn',{staticClass:"show-button elevation-0",class:_vm.selected.includes(item) ? 'buttonVisibility' : '',on:{"click":function($event){return _vm.viewVehicleDetail(item)}}},[_vm._v(" "+_vm._s(_vm.$t('service_details.txt.show_vehicle'))+" ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[(_vm.noSearchResults)?_c('div',{staticClass:"no-results"},[_c('v-icon',{attrs:{"size":"50"}},[_vm._v(" mdi-truck ")]),_c('br'),_vm._v(_vm._s(_vm.$t('vehicles_overview.txt.empty_search'))+" ")],1):_vm._e()])],2),_c('v-footer',{staticClass:"py-6 elevation-2",attrs:{"fixed":"","color":"white"}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"results_footer"},[_c('v-col',{staticClass:"py-2 py-md-0 text-center text-sm-left align-self-center",attrs:{"cols":"12","sm":"4","md":"2","lg":"1","offset-lg":"4"}},[_c('v-icon',{attrs:{"width":"22","color":"steel"}},[_vm._v(" mdi-truck ")]),_c('span',[_vm._v(" x "),_c('b',{staticClass:"results_footer_costs"},[_vm._v(_vm._s(_vm.selectedForCart.length)+" ")])])],1),_c('v-col',{staticClass:"py-2 py-md-0 text-center text-sm-right align-self-center",attrs:{"cols":"12","sm":"8","md":"4","lg":"3"}},[(
              _vm.filterStatus.key === 'Unsubscribe Trucks' ||
                _vm.filterStatus.key === 'Transfer Trucks'
            )?_c('div'):_c('div',{staticClass:"results_footer_chooseperiod"},[_c('div',{staticClass:"d-flex buttons elevation-0 align-center justify-sm-end mr-2"},[_c('span',{staticClass:"label mr-2"},[_vm._v(_vm._s(_vm.$t('service_details.txt.choose_period')))]),(_vm.selectedHasMonthlyPlan.length > 0)?_c('v-btn',{staticClass:"elevation-0 ml-4",class:_vm.activeBtn.month ? 'v-btn--active' : 'disabled',attrs:{"disabled":_vm.selected.length === 0 ||
                    (_vm.selected.length > 0 &&
                      _vm.selected[0].billingPeriod == 'Yearly') ||
                    !(_vm.selectedHasMonthlyPlan.length > 0)},on:{"click":function($event){return _vm.onActiveButtonMonthClicked()}}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.currency.symbol)+" "+_vm._s(_vm.monthlyCost))]),_vm._v(" "+_vm._s(_vm.$t('service_details.txt.month'))+" ")])]):_vm._e(),_c('v-btn',{staticClass:"elevation-0",class:_vm.activeBtn.annual ? 'v-btn--active' : 'disabled',attrs:{"disabled":_vm.selected.length === 0 ||
                    (_vm.selected.length > 0 &&
                      _vm.selected[0].billingPeriod == 'Monthly') ||
                    !(_vm.selectedHasYearlyPlan.length > 0)},on:{"click":function($event){return _vm.onActiveButtonAnnualClicked()}}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.currency.symbol)+" "+_vm._s(_vm.yearlyCost))]),_vm._v(" "+_vm._s(_vm.$t('service_details.txt.year'))+" ")])]),_c('v-icon',{staticClass:"d-none d-md-block"},[_vm._v(" mdi-chevron-right ")])],1)])]),_c('v-col',{staticClass:"py-2 py-md-0 text-center text-md-right align-self-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.filterStatus.key === 'Renew Trucks')?_c('v-btn',{staticClass:"results_footer_btn",class:_vm.disabledButton || _vm.itemsForCart.length == 0
                ? 'disabled'
                : 'proceed',attrs:{"disabled":_vm.disabledButton || _vm.itemsForCart.length == 0},on:{"click":function($event){return _vm.bulkActiveService({renew: true})}}},[_vm._v(" "+_vm._s(_vm.$t('service_details.txt.renew'))+" ")]):(_vm.filterStatus.key === 'Unsubscribe Trucks')?_c('v-btn',{staticClass:"results_footer_btn",class:_vm.itemsForCart.length == 0 ? 'disabled' : 'cancel',attrs:{"disabled":_vm.itemsForCart.length == 0},on:{"click":function($event){return _vm.bulkStopService()}}},[_vm._v(" "+_vm._s(_vm.$t('service_details.txt.unsubscribe'))+" ")]):(_vm.filterStatus.key === 'Transfer Trucks')?_c('v-btn',{staticClass:"results_footer_btn",class:_vm.itemsForCart.length == 0 ? 'disabled' : 'transfer',attrs:{"disabled":_vm.itemsForCart.length == 0},on:{"click":function($event){return _vm.bulkTransferOwnerService()}}},[_vm._v(" "+_vm._s(_vm.$t('service_details.txt.transfer_owner'))+" ")]):_c('v-btn',{staticClass:"results_footer_btn",class:_vm.disabledButton || _vm.itemsForCart.length == 0
                ? 'disabled'
                : 'proceed',attrs:{"disabled":_vm.disabledButton || _vm.itemsForCart.length == 0},on:{"click":function($event){return _vm.bulkActiveService()}}},[_vm._v(" "+_vm._s(_vm.$t('service_details.txt.subscribe'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }