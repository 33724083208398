<template>
  <nav class="left-menu">
    <v-list
      class="items"
      nav
      flat
      dense
    >
      <v-list-item-group
        active-class="peacock"
        mandatory
        class="d-inline-flex d-lg-block"
      >
        <v-list-item
          v-for="(link, index) in navLinks"
          :key="index"
          class="item mr-5 mr-lg-0 mb-0 mb-lg-5"
          :to="link.to"
          :disabled="index==1 && trucks && trucks.length===0"
          link
        >
          <v-list-item-icon
            class="item-icon"
            color="peacock"
            :class="index!=1 || (index==1 && trucks && trucks.length>0) ? 'enabled' : 'disabled'"
          >
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            class="item-title"
            :class="index!=1 || (index==1 && trucks && trucks.length>0) ? 'enabled' : 'disabled'"
          >
            {{ link.title | capitalize }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'HomeMenu',

  filters: {
    capitalize: function(value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    }
  },

  data: () => ({
    drawer: true,
    miniVariant: false,
    authorized: localStorage.getItem('authorized'),
    trucks: [{}]
  }),

  computed: {
    // ...mapState('trucks', ['trucks']),
    navLinks() {
      return [
        {
          to: { name: 'services' },
          icon: 'mdi-layers-plus',
          title: this.$t('home_menu.txt.services')
        },
        {
          to: { name: 'vehicles' },
          icon: 'mdi-truck',
          title: this.$t('home_menu.txt.vehicles')
        }
      ]
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  async created() {
    // await this.getVehicles()
  },
  methods: {
    ...mapActions('trucks', ['getVehicles']),
    onResize() {
      this.miniVariant = this.$vuetify.breakpoint.smAndDown
    },
    openSubPage(item) {
      this.$emit('vehicles_subpage', item)
    }
  }
}
</script>

<style lang="scss">
.left-menu {
  .items {
    background-color: var(--v-pale-base);
    padding: 0;

    .item {
      padding: 0 22px;
      width: 176px;
      height: 40px;
      justify-content: left;

      &.v-list-item--active {
        box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.22) !important;

        .item-title {
          color: white !important;
          letter-spacing: 0.18em;
          font-size: 0.75em;
        }

        .item-icon {
          margin-right: 12px;

          i {
            font-size: 1.375rem;
            color: white !important;
            opacity: 1;
          }
        }
      }

      &-icon {
        margin-right: 12px;
        i {
          font-size: 1.375rem;
          opacity: 0.3;
        }
        &.enabled {
          i {
            color: var(--v-peacock-base) !important;
          }
        }
        &.disabled {
          i {
            color: var(--v-cloudy-base) !important;
          }
        }
      }

      &-title {
        text-transform: none;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1px;

        &.enabled {
          color: var(--v-peacock-base) !important;
        }
        &.disabled {
          color: var(--v-cloudy-base) !important;
        }
      }
    }
  }
}
</style>