<template>
  <div class="services">
    <v-row
      v-if="loading"
      class="loader-page"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-row>
    <div v-if="!loading">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          class="pt-0"
        >
          <h1>
            {{ $t('services_overview.txt.services') }}
          </h1>
          <p>{{ $t('services_overview.txt.services_description') }}</p>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pt-0"
        >
          <div class="totals float-sm-right pl-8 pl-sm-0">
            <div
              v-if="monthlyTotalCost>0 || yearlyTotalCost>0"
              class="label"
            >
              {{ $t('services_overview.txt.total_cost') }}
            </div>
            <div>
              <span
                v-if="monthlyTotalCost>0"
                class="total"
              >{{ currency.symbol }} {{ totalMonthPrice }} </span>
              <span v-if="monthlyTotalCost>0">{{ $t('services_overview.txt.month') }}</span>
              <span v-if="monthlyTotalCost>0 && yearlyTotalCost>0"> & </span>
              <span
                v-if="yearlyTotalCost>0"
                class="total"
              >{{ currency.symbol }} {{ totalAnnualPrice }} </span>
              <span v-if="yearlyTotalCost>0">{{ $t('services_overview.txt.year') }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isCustomerAdmin">
        <v-col 
          cols="12"
          md="12"
          xl="12"
        >
          <v-alert 
            v-if="!hasDefaultPaymentMethod" 
            type="error"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t('services_overview.txt.provide_a_payment_method') }}
              </v-col>
              <v-col class="shrink">
                <v-btn 
                  outlined
                  @click="setPaymentMethod"
                >
                  {{ $t('services_overview.txt.manage_your_payment_methods') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <!-- <v-alert
            v-if="hasDefaultPaymentMethod"
            border="bottom"
            color="blue-grey"
            dark
          >
            <v-row class="d-flex align-center">
              <v-col 
                cols="12"
                md="3"
                xl="3"
              >
                <v-checkbox 
                  v-model="isFutureInvoiceOwner" 
                  @change="onCheckBoxChanged"
                >
                  <template v-slot:label>
                    <div>
                      {{ $t('services_overview.txt.future_truck_subscriber') }}
                    </div>
                  </template>
                </v-checkbox>  
                <v-dialog
                  v-model="confirmDialog"
                  persistent
                  width="500"
                >
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      {{ isFutureInvoiceOwner ? $t('services_overview.txt.enable_future_truck_invoice_owner') : $t('services_overview.txt.disable_future_truck_invoice_owner') }}
                    </v-card-title>

                    <v-card-text>
                      <h3> {{ isFutureInvoiceOwner ? $t('services_overview.txt.enable_yourself_as_future_invoice_owner') : $t('services_overview.txt.disable_yourself_as_future_invoice_owner') }}</h3>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="cancelFutureInvoiceOwner"
                      >
                        {{ $t('services_overview.txt.cancel') }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        dark
                        @click="confirmFutureInvoiceOwner"
                      >
                        {{ $t('services_overview.txt.confirm') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col 
                cols="12"
                md="9"
                xl="9"
              >
                <p>
                  {{ $t('services_overview.txt.future_truck_subscriber_banner') }}
                </p>
              </v-col>
            </v-row>   
          </v-alert>    -->
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="service in services"
          :key="service.id"
          cols="12"
          md="6"
          xl="4"
        >
          <ServicesCard
            :service="service"
            :service-annual-price="service.priceServiceAnnual"
            :service-total-annual-price="service.totalAnnual"
            :service-month-price="service.priceServiceMonth"
            :service-total-month-price="service.totalMonth"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import { mapActions, mapMutations, mapState } from 'vuex'
import ServicesCard from '@/components/ServicesCard.vue'
import { getInstance, getRoleClaim } from '../../auth/authWrapper'
import { roleRatePlanService } from '../../src/services/roleRatePlanService'

export default {
  name: 'ServiceOverview',
  components: {
    ServicesCard
  },
  data() {
    return {
      services: [],
      activeServices: [],
      monthlyTotalCost: 0,
      yearlyTotalCost: 0,
      loading: false,
      trucks: [],
      isFutureInvoiceOwner: false,
      confirmDialog: false,
      notification: false,
      notificationText: '',
      notificationType: 'success',
      hasDefaultPaymentMethod: true
    }
  },
  computed: {
    ...mapState('account', ['currency']),
    totalMonthPrice() {
      return new Intl.NumberFormat(this.$i18n.locale).format(
        this.monthlyTotalCost
      )
    },
    totalAnnualPrice() {
      return new Intl.NumberFormat(this.$i18n.local).format(
        this.yearlyTotalCost
      )
    },
    orderedServices() {
      const array = this.services
      array.sort((x, y) => {
        if (x.name < y.name) return -1
        if (x.name > y.name) return 1
        return 0
      })
      return 0
    },
    isCustomerAdmin() {
      const user = getInstance().user
      const role = getRoleClaim(user)
      if (role === 'Customer Administrator') {
        return true
      }
      return false
    }
  },
  watch: {
    currency(newValue) {
      if (newValue && newValue.code) {
        this.monthlyTotalCost = 0
        this.yearlyTotalCost = 0
        this.getServices()
      }
    }
  },
  async created() {
    this.loading = true
    await this.getServices()
    await this.getFutureInvoiceOwner()
    await this.mapProductPrismic()
    await this.validatePaymentMethod()
    this.loading = false
  },
  methods: {
    ...mapActions('notification', ['setSnackbar']),
    ...mapMutations('catalog', ['setProducts']),
    getServices() {
      return apiCalls
        .get(api.ProductsGetAll, '', '?checkForActivatedVehicles=true')
        .then(res => {
          this.services = res.data
          // this.services.sort((x, y) => {
          //   if (
          //     x.category === 'Base Products' &&
          //     y.category === 'Add On Services'
          //   )
          //     return -1

          //   if (x.name < y.name) return -1
          //   if (x.name > y.name) return 1
          //   return 0
          // })
          // New sorting to make Truck Connectivity first and OTA second
          this.services.sort((serviceA, serviceB) => {
            if (serviceA.name.includes('OTA')) return 1
            else if (serviceB.name.includes('OTA')) return -1
            else return 0
          })
          this.setProducts(this.services)
          // return []
        })
        .then(() => {
          // return apiCalls
          //   .get(api.TrucksPerService, '', '')
          //   .then(res => (this.activeServices = res.data))
          return []
        })
        .then(activeServices => {
          return apiCalls
            .getCMSContent('resources', 'catalog')
            .then(({ results }) => {
              this.services.forEach(serv => {
                serv.productRatePlans
                  .filter(
                    w =>
                      w.ratePlanType__c ==
                      roleRatePlanService.userRatePlan(
                        localStorage.getItem('role')
                      )
                  )
                  .forEach(product => {
                    product.productRatePlanCharges.forEach(bp => {
                      if (bp.billingPeriod === 'Annual') {
                        const annualPrice =
                          bp.pricing.find(
                            pr => pr.currency === this.currency.code
                          ) || 0
                        serv.priceServiceAnnual = annualPrice.price
                        serv.productPlanChargesIdYearly = bp.id
                        serv.productRatePlanIdYearly = product.id
                      } else if (bp.billingPeriod === 'Month') {
                        const monthPrice =
                          bp.pricing.find(
                            pr => pr.currency === this.currency.code
                          ) || 0
                        serv.priceServiceMonth = monthPrice.price
                        serv.productPlanChargesIdMonthly = bp.id
                        serv.productRatePlanIdMonthly = product.id
                      }
                    })
                  })
                serv.priceServiceAnnual = serv.priceServiceAnnual || 0
                serv.priceServiceMonth = serv.priceServiceMonth || 0

                activeServices.forEach(actServ => {
                  if (
                    serv.id === actServ.productID ||
                    serv.packID__c == actServ.packId
                  ) {
                    serv.activeServices = actServ.activeTrucks
                    serv.totalAnnual = actServ.totals.Annual
                    serv.totalMonth = actServ.totals.Month
                    serv.customerServiceSubscribed =
                      serv.subscriptionLevel__c == 'Company' &&
                      serv.packID__c == actServ.packId
                    serv.status = actServ.status
                  }
                })
                if (
                  typeof serv.totalMonth === 'number' &&
                  typeof serv.totalAnnual === 'number'
                ) {
                  this.monthlyTotalCost += serv.totalMonth || 0
                  this.yearlyTotalCost += serv.totalAnnual || 0
                }
                serv.activeServices = serv.activeServices || 0
                results[0].data.options.forEach(cms => {
                  if (cms.externalkey[0].text === serv.sku) {
                    serv.name = cms.title[0].text
                    serv.iconUrl = cms.iconactive.url
                    serv.description = cms.description[0].text
                  }
                })
                this.loading = false
                return serv
              })
            })
            .then(() => {
              // return apiCalls
              //   .get(api.CompanyService, '', '')
              //   .then(({ data: [data] }) => {
              //     if (data) {
              //       const { totals } = data.rateplan[0]
              //       this.monthlyTotalCost += totals.Month || 0
              //       this.yearlyTotalCost += totals.Annual || 0
              //     }
              //   })
            })
        })
    },
    getFutureInvoiceOwner() {
      return apiCalls.get(api.FutureInvoiceOwner, '', '').then(res => {
        this.isFutureInvoiceOwner = res.data
      })
    },
    mapProductPrismic() {
      const that = this

      apiCalls
        .getCMSContent('resources', 'catalog')
        .then(function({ results }) {
          that.services.forEach(serv => {
            serv.productRatePlans.forEach(product => {
              product.productRatePlanCharges.forEach(bp => {
                if (bp.billingPeriod === 'Annual') {
                  const annualPrice =
                    bp.pricing.find(pr => pr.currency === that.currency.code) ||
                    0
                  serv.priceServiceAnnual = annualPrice.price
                  serv.productPlanChargesIdYearly = bp.id
                  serv.productRatePlanIdYearly = product.id
                } else if (bp.billingPeriod === 'Month') {
                  const monthPrice =
                    bp.pricing.find(pr => pr.currency === that.currency.code) ||
                    0
                  serv.priceServiceMonth = monthPrice.price
                  serv.productPlanChargesIdMonthly = bp.id
                  serv.productRatePlanIdMonthly = product.id
                }
              })
            })
            serv.priceServiceAnnual = serv.priceServiceAnnual || 0
            serv.priceServiceMonth = serv.priceServiceMonth || 0

            // activeServices.forEach(actServ => {
            //   if (
            //     serv.id === actServ.productID ||
            //     serv.packID__c == actServ.packId
            //   ) {
            //     serv.activeServices = actServ.activeTrucks
            //     serv.totalAnnual = actServ.totals.Annual
            //     serv.totalMonth = actServ.totals.Month
            //     serv.customerServiceSubscribed =
            //       serv.subscriptionLevel__c == 'Company' &&
            //       serv.packID__c == actServ.packId
            //     serv.status = actServ.status
            //   }
            //  })
            if (
              typeof serv.totalMonth === 'number' &&
              typeof serv.totalAnnual === 'number'
            ) {
              that.monthlyTotalCost += serv.totalMonth || 0
              that.yearlyTotalCost += serv.totalAnnual || 0
            }
            // serv.activeServices = serv.activeServices || 0
            results[0].data.options.forEach(cms => {
              if (cms.externalkey[0].text === serv.sku) {
                serv.name = cms.title[0].text
                serv.iconUrl = cms.iconactive.url
                serv.description = cms.description[0].text
              }
            })
            that.loading = false
            return serv
          })
        })
    },
    cancelFutureInvoiceOwner() {
      this.isFutureInvoiceOwner = !this.isFutureInvoiceOwner
      this.confirmDialog = false
      this.notification = false
    },
    confirmFutureInvoiceOwner() {
      const body = {
        enabled: this.isFutureInvoiceOwner
      }
      apiCalls.post(api.FutureInvoiceOwner, '', '', body).then(res => {
        if (res.data) {
          this.confirmDialog = false
          if (this.isFutureInvoiceOwner) {
            this.setSnackbar({
              showing: true,
              type: 'success',
              text: this.$t(
                'Your account is successfully enabled as future invoice owner'
              )
            })
          } else {
            this.setSnackbar({
              showing: true,
              type: 'success',
              text: this.$t(
                'Your account is successfully disabled as future invoice owner'
              )
            })
          }
        }
      })
    },
    validatePaymentMethod() {
      return apiCalls.get(api.ConfirmDefaultPaymentMethod, '', '').then(res => {
        this.hasDefaultPaymentMethod = res.data
      })
    },
    setPaymentMethod() {
      this.$router.push({
        name: 'payment_method'
      })
    },
    onCheckBoxChanged(event) {
      this.confirmDialog = true
      this.isFutureInvoiceOwner = event
    }
  }
}
</script>

<style lang="scss">
.services {
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  h1 {
    margin-bottom: 22px;
    font-family: 'Arial Narrow', Arial, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: left;
  }
  .totals {
    .label {
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--v-bluegrey-base);
    }
    .total {
      font-size: 1.5rem;
      font-weight: bold;
      color: var(--v-slate-base);
      span {
        font-size: 0.875rem;
      }
    }
  }
}
</style>