<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="grey lighten-1">
          Updating your trucks...
        </v-card-title>
        <v-divider />


        <v-progress-linear
          v-model="percentageValue"
          height="25"
        >
          <strong>{{ Math.ceil(percentageValue) }}%</strong>
        </v-progress-linear>

        <v-divider />

        <v-card-actions
          height="25"
        >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarDialog',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    percentageValue: {
      type: Number,
      default: 0
    }
  }
}
</script>