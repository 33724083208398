<template>
  <v-container />
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import parseJwt from '@/helpers/parseJwt'
import { loadLanguage } from '@/plugins/i18n'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'Login',

  props: {
    hash: {
      type: String,
      required: true
    }
  },

  created() {
    this.loggedin()
  },

  methods: {
    ...mapActions('notification', ['setSnackbar']),
    ...mapMutations('checkout', ['setTotalItemsCheckout']),
    ...mapActions('checkout', ['getCheckout']),
    ...mapActions('account', ['getAccountDetails', 'getCurrencyInfo']),
    //    ...mapState('locale', ['locales']),

    async loggedin() {
      try {
        await apiCalls
          .authentication(api.AccountSignUp, {
            hash: this.hash
          })
          .then(async res => {
            const userToken = res.headers['user-token']
            this.$store.commit('account/setUserToken', userToken)
            localStorage.setItem('userToken', userToken)

            const payload = parseJwt(userToken)
            let userLanguage = payload.Language

            if (!this.locales().includes(userLanguage)) {
              userLanguage = 'en-gb'
            }

            if (userLanguage !== this.$i18n.locale) {
              await loadLanguage(userLanguage)
            }

            const { data: accountDetails } = await apiCalls.get(
              api.AccountDetails,
              '',
              ''
            )

            // Requests for initial bootup
            await this.getAccountDetails()
            await this.getCurrencyInfo()
            await this.getCheckout()
            await this.setTotalItemsCheckout()

            if (!accountDetails.CddCheck) {
              this.$router.push({
                name: 'payment_information'
              })
            } else {
              this.$router.push({
                name: 'home'
              })
            }
          })
      } catch (error) {
        this.setSnackbar({
          showing: true,
          type: 'error',
          text: error
        })
      }
    }
  }
}
</script>