<template>
  <div class="search-filter">
    <v-row>
      <p class="connectivity-header">
        {{ $t('service_details.txt.service_details_description') }}
      </p>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="textToSearch"
          class="ma-0 pa-0 search-filter-input"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('search.txt.placeholder')"
          single-line
          full-width
          hide-details
          dense
          color="slate"
        />
        <v-col
          cols="12"
          class="d-sm-none pt-6"
        >
          <div class="options">
            <span class="filter-label mobile peacock--text">{{ $t('service_details.txt.i_want_to') }}: </span>
            <v-select
              v-model="status"
              :append-icon="'mdi-chevron-down'"
              :items="statusItems"
              :menu-props="{ offsetY: true }"
              :placeholder="$t('service_details.txt.subscribe_trucks')"
              return-object
              @change="filterByStatus"
            />
          </div>
        </v-col>
        <!-- <v-col
        cols="12"
        class="d-sm-none"
      >
        <div class="options">
          <span class="filter-label mobile">{{ $t('checkout.txt.sort_by') }}: </span>
          <v-select
            v-model="sortBy"
            class="d-inline-flex filter-items mobile"
            background-color="pale-three"
            color="slate"
            :append-icon="'mdi-chevron-down'"
            :items="sortItems"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('None')"
            dense
            filled
            return-object
            @change="sortByItem"
          />
        </div>
      </v-col> -->
        <v-col
          cols="12"
          class="d-none d-sm-block d-lg-none pt-6"
        >
          <div class="options pr-4 pl-4">
            <span class="filter-label peacock--text">{{ $t('service_details.txt.i_want_to') }}: </span>
            <v-select
              v-model="status"
              class="d-inline-flex filter-items mr-2"
              background-color="pale-three"
              color="slate"
              :append-icon="'mdi-chevron-down'"
              :items="statusItems"
              :menu-props="{ offsetY: true }"
              :placeholder="$t('service_details.txt.subscribe_trucks')"
              return-object
              @change="filterByStatus"
            />
            <div class="horizontal-spacer" />
          <!-- <span class="filter-label">{{ $t('checkout.txt.sort_by') }}: </span>
          <v-select
            v-model="sortBy"
            class="d-inline-flex filter-items"
            background-color="pale-three"
            color="slate"
            :append-icon="'mdi-chevron-down'"
            :items="sortItems"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('service_details.txt.activated')"
            dense
            filled
            rounded
            return-object
            @change="sortByItem"
          /> -->
          </div>
        </v-col>
        <v-col
          cols="12"
          class="pt-6"
        >
          <div class="options pr-sm-4 pl-sm-4">
            <div class="start-spacer" />
            <!-- <span class="filter-label flex-lg-shrink-0">{{ $t('service_details.txt.select') }}:</span> -->
            <!-- <a @click="selectAllByPeriod('Monthly')">{{ $t('service_details.txt.monthly') }}</a>
          <div class="vertical-spacer" /> -->
            <span class="filter-label">{{ $t('service_details.txt.select_all') }}</span>
            <!-- <div class="vertical-spacer" /> -->
            <!-- <a @click="selectAllByPeriod(null)">{{ $t('service_details.txt.unknown') }}</a> -->
            <div class="horizontal-spacer" />
            <span class="d-none d-lg-block filter-label flex-lg-shrink-0 peacock--text">{{ $t('service_details.txt.i_want_to') }}: </span>
            <v-select
              v-model="status"
              class="d-none d-lg-inline-flex"
              :append-icon="'mdi-chevron-down'"
              style="font-size:0.875rem;"
              :items="statusItems"
              :menu-props="{ offsetY: true }"
              :placeholder="$t('service_details.txt.subscribe_trucks')"
              return-object
              @change="filterByStatus"
            />
          <!-- <div class="horizontal-spacer" /> -->
          <!-- <span class="d-none d-lg-block filter-label flex-lg-shrink-0">{{ $t('checkout.txt.sort_by') }}: </span>
          <v-select
            v-model="sortBy"
            class="d-none d-lg-inline-flex filter-items"
            background-color="pale-three"
            color="slate"
            :append-icon="'mdi-chevron-down'"
            :items="sortItems"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('None')"
            dense
            filled
            rounded
            return-object
            @change="sortByItem"
          /> -->
          </div>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ServiceDetailSearch',
  props: {
    serviceName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      textToSearch: '',
      sortBy: '',
      selectBy: '',
      status: {
        key: 'Subscribe Trucks',
        text: this.$t('service_details.txt.subscribe_trucks')
      },
      period: null
    }
  },
  computed: {
    sortItems() {
      return [
        { key: 'vinNumber', text: this.$t('service_details.txt.vin_number') },
        {
          key: 'subscriptionEndDate',
          text: this.$t('Subs End Date')
        },
        {
          key: 'unitNumber',
          text: this.$t('Unit Number')
        }
      ]
    },

    statusItems() {
      const statusItems = [
        {
          key: 'Unsubscribe Trucks',
          text: this.$t('service_details.txt.unsubscribe_trucks')
        },
        // {
        //   key: 'Transfer Trucks',
        //   text: this.$t('Transfer Trucks')
        // },
        {
          key: 'Subscribe Trucks',
          text: this.$t('service_details.txt.subscribe_trucks')
        }
      ]
      if (this.serviceName.includes('OTA'))
        statusItems.push({
          key: 'Renew Trucks',
          text: this.$t('Renew Trucks')
        })

      return statusItems
    },

    periodItems() {
      return [
        {
          key: 'Monthly',
          text: this.$t('service_details.txt.monthly')
        },
        {
          key: 'Yearly',
          text: this.$t('service_details.txt.yearly')
        },
        {
          key: null,
          text: this.$t('service_details.txt.unknown')
        }
      ]
    }
  },
  watch: {
    textToSearch() {
      this.$emit('textToSearch', this.textToSearch)
    }
  },
  methods: {
    sortByItem(item) {
      this.sortBy = item
      this.$emit('sortBy', this.sortBy)
    },

    filterByStatus() {
      this.period = null
      this.$emit('filterByStatus', this.status)
    },

    selectAllByPeriod(period) {
      this.$emit('selectAllByPeriod', period)
    }
  }
}
</script>

<style lang="scss">
.search-filter {
  margin-top: 20px;
  &-input {
    .v-input {
      &__slot {
        &:before {
          border-color: var(--v-cloudy-base) !important;
        }

        .v-icon {
          margin-top: 0 !important;
          color: var(--v-cloudy-base);
        }
      }
    }
  }

  .options {
    display: flex;
    align-items: center;

    a {
      margin: 0 10px;
      font-size: 0.875rem;
      text-decoration: none;
    }

    .start-spacer {
      width: 20px;
      height: 20px;
      margin-top: 20px;
      margin-left: 0px;
      border-top: 1px solid var(--v-cloudy-base);
      border-left: 1px solid var(--v-cloudy-base);
    }

    .vertical-spacer {
      border-left: 1px solid var(--v-cloudy-base);
      color: var(--v-cloudy-base);
      height: 16px;
      margin-top: 2px;
    }

    .horizontal-spacer {
      border-top: 1px solid var(--v-cloudy-base);
      flex-grow: 7;
    }

    .filter-label {
      font-size: 0.875rem;
      font-weight: bold;
      text-align: right;
      color: var(--v-slate-base);
      margin: 0 10px;
      &.mobile {
        text-align: left;
        width: 100px;
      }
    }

    .filter-items {
      max-width: 220px;
      height: 32px;
      font-size: 0.875rem;
      &.mobile {
        width: 100%;
        max-width: none;
      }

      .v-input {
        &__slot {
          min-height: 32px !important;
        }

        &__append-inner {
          margin-top: 0 !important;
        }
      }
    }
  }
  .connectivity-header {
    padding: 20px 0px 0px 0px;
    font-size: 15px;
  }
}
</style>