<template>
  <v-container class="invoices">
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <router-link
          class="all-services"
          :to="{name: 'services', params: {flagVehicles: false}}"
        >
          <v-icon
            color="peacock"
            width="15px"
          >
            mdi-arrow-left
          </v-icon>
          <span class="ml-4">{{ $t('service_details.txt.all_services') }}</span>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        lg="10"
      >
        <v-row>
          <v-col 
            col="12" 
            lg="12" 
            sm="12" 
            md="12" 
          >
            <h2>
              {{ $t('invoices.txt.invoices') }}
            </h2>
          </v-col>
          <v-col 
            col="12" 
            lg="12" 
            sm="12" 
            md="12" 
          >
            <v-divider />
          </v-col>
          <v-col
            col="12" 
            lg="12" 
            sm="12" 
            md="12" 
          >
            <v-expansion-panels
              v-model="panel"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h5>Filters</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      offset-lg="0"
                      offset-xl="0"
                      pt="0"
                    >
                      <v-select
                        v-model="defaultStatus"
                        class="d-inline-flex sort-by-items mobile"
                        background-color="var(--v-pale-base)"
                        color="slate"
                        :append-icon="'mdi-chevron-down'"
                        :items="filters"
                        :menu-props="{ offsetY: true }"
                        :placeholder="'Status'"
                        dense
                        filled
                        return-object
                        @change="selectedItem"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      offset-lg="0"
                      offset-xl="0"
                      pt="0"
                    >
                      <h5>Period (Months)</h5>
                      <v-slider
                        v-model="defaultPeriod"
                        max="24"
                        min="6"
                        class="invoices"
                        step="1"
                        thumb-label="always"
                        ticks
                        @change="selectedPeriod"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            pt="0"
          >
            <!-- <div 
              v-if="emptyInvoices" 
              class="no-results"
            >
              <v-icon>mdi-file-document</v-icon>
              <br>
              {{ $t('invoices.txt.empty_invoices') }}
            </div> -->
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="visibleItemsPerPages"
          :loading="loading"
          loading-text="Loading invoices..."
          loader-height="2"
          :options.sync="options"
          :server-items-length="paginationModel.totalCount"
          :footer-props="{
            'items-per-page-options': [10, 25, 50]
          }"
        >
          <template slot="no-data">
            <div
              class="no-results"
            >
              <v-icon size="60">
                mdi-file-document
              </v-icon>
              <br>
              {{ $t('invoices.txt.empty_invoices') }}
            </div>
          </template>
          <template v-slot:[`header.number`]="{ header }">
            <span class="v-data-table-header">
              {{ header.text }}
            </span>
          </template>
          <template
            v-slot:[`header.status`]="{ header }"
          >
            <span>
              {{ header.text }}
            </span>
          </template>
          <template v-slot:[`item.number`]="{ item }">
            <span style="font-weight:bold;letter-spacing: 0.1em;">
              {{ item.number }}
            </span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span style="font-weight:bold;letter-spacing: 0.1em;">
              {{ currency.symbol }} {{ item.amount }}
            </span>
          </template>
          <template v-slot:[`item.balance`]="{ item }">
            <span style="font-weight:bold;letter-spacing: 0.1em;">
              {{ currency.symbol }} {{ item.balance }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              v-if="item.status === 'Posted'"
              cols="12"
              class="paid"
            >
              <v-icon color="peacock">
                mdi-check-bold
              </v-icon>
              <span> {{ $t('invoices.txt.posted') }} </span>
            </div>
            <div 
              v-else 
              cols="12" 
              class=""
            >
              <div 
                v-if="item.status === 'Paid'" 
                class="paid"
              >
                <v-icon 
                  class="mr-1" 
                  color="peacock"
                >
                  mdi-check-bold
                </v-icon>
                {{ $t('invoices.txt.paid') }}
              </div>
              <div 
                v-else 
                class="open"
              >
                <v-icon 
                  class="mr-1" 
                  color="reddish"
                >
                  mdi-close
                </v-icon>
                {{ $t('invoices.txt.open') }} 
              </div>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div
              cols="12"
              class="align-center text-center text-sm-right flex-column"
            >
              <v-btn
                class="button elevation-0 float-sm-right"
                color="ice"
                @click="downloadInvoice(item)"
              >
                {{ $t('invoices.txt.download') }}
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import FileSaver from 'file-saver'
import { mapState } from 'vuex'

export default {
  name: 'Invoices',
  data() {
    return {
      page: 1,
      pageCount: 0,
      invoices: [],
      loading: false,
      defaultStatus: 'None',
      defaultPeriod: 24,
      filters: ['None', 'Paid', 'Open'],
      paginationModel: {},
      panel: 0,
      isNewSearch: false,
      hideAndShowFilter: false,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      headers: [
        { text: 'NUMBER', value: 'number' },
        { text: 'DATE', value: 'date', class: 'column-header' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'BALANCE', value: 'balance' },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'ACTION', value: 'action', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('account', [
      'currency',
      'accountDetails',
      'accountInformation'
    ]),
    filteredList() {
      const filteredInvoices = this.invoices
      filteredInvoices.sort((x, y) => {
        return x.date.split('-').join('') > y.date.split('-').join('')
          ? -1
          : x.date.split('-').join('') < y.date.split('-').join('')
          ? 1
          : 0
      })

      filteredInvoices.filter(invoice => {
        invoice.amount = new Intl.NumberFormat(this.$i18n.locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(invoice.amount)
        invoice.balance = new Intl.NumberFormat(this.$i18n.locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(invoice.balance)
      })
      return filteredInvoices
    },
    visibleItemsPerPages() {
      // const visibleVehiclesPerPage = this.filteredList
      // return visibleVehiclesPerPage.slice(
      //   (this.page - 1) * this.itemsPerPage,
      //   this.page * this.itemsPerPage
      // )
      const visibleVehiclesPerPage = this.invoices
      return visibleVehiclesPerPage
    },
    emptyInvoices() {
      return this.invoices && this.invoices.length == 0 && !this.loading
    }
  },
  watch: {
    options() {
      this.loading = true
      return apiCalls
        .get(
          api.InvoiceGetAll,
          '',
          '?PageNumber=' +
            this.options.page +
            `&PageSize=${this.options.itemsPerPage}&FromLastMonths=${this.defaultPeriod}&Status=${this.defaultStatus}`
        )
        .then(res => {
          this.invoices = res.data
          this.paginationModel = JSON.parse(res.headers['x-zip-pagination'])
        })
        .finally(() => {
          this.loading = false
          this.isNewSearch = false
        })
    },

    page(changePage, currentPage) {
      if (this.isNewSearch) {
        return
      }
      let result
      this.loading = true
      if (changePage > currentPage) {
        result = apiCalls.getForUrl(this.paginationModel.nextPageLink)
      } else {
        result = apiCalls.getForUrl(this.paginationModel.previousPageLink)
      }
      result
        .then(res => {
          this.invoices = res.data
          this.paginationModel = JSON.parse(res.headers['x-zip-pagination'])
        })
        .finally(() => (this.loading = false))
    }
  },
  async created() {
    await this.getInvoices()
  },
  methods: {
    selectedItem(selected) {
      this.defaultStatus = selected
      this.isNewSearch = true
      this.page = 1
      this.getInvoices()
    },
    selectedPeriod(selected) {
      this.defaultPeriod = selected
      this.isNewSearch = true
      this.page = 1
      this.getInvoices()
    },
    getInvoices() {
      this.loading = true
      return apiCalls
        .get(
          api.InvoiceGetAll,
          '',
          '?PageNumber=' +
            this.page +
            `&PageSize=${this.options.itemsPerPage}&FromLastMonths=${this.defaultPeriod}&Status=${this.defaultStatus}`
        )
        .then(res => {
          this.invoices = res.data
          this.paginationModel = JSON.parse(res.headers['x-zip-pagination'])
        })
        .finally(() => {
          this.loading = false
          this.isNewSearch = false
        })
    },
    async downloadInvoice(invoice) {
      return apiCalls
        .downloadFile(api.InvoiceDownload + '/' + invoice.id + '/files', '', '')
        .then(res => {
          if (res.status == 200) {
            FileSaver.saveAs(
              new Blob([res.data]),
              invoice.number + '_' + invoice.date + '.pdf'
            )
          }
        })
    },
    onHideAndShowFilter() {
      this.hideAndShowFilter = !this.hideAndShowFilter
    }
  }
}
</script>

<style lang="scss">
.invoices {
  .filters {
    cursor: pointer;
    padding: 10px;
  }
  .all-services {
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--v-mid-base);
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 0px;
  }
  .v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  h1 {
    margin-bottom: 22px;
    font-size: 3rem;
    font-weight: 300;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: left;
  }
  .no-results {
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    color: var(--v-bluegrey-base);
    .v-icon {
      font-size: 100px;
      color: var(--v-cloudy-base);
    }
  }
  .list {
    .item {
      border-radius: 6px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
      color: #4a5568;
      padding: 12px 24px;

      .paid {
        color: var(--v-peacock-base);
      }

      .open {
        color: var(--v-reddish-base);
      }

      .button {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: var(--v-mid-base) !important;
        height: 32px;
        border-radius: 3px;
      }
    }
  }
  .pagination {
    margin: 0 auto;
    .v-pagination {
      &__item {
        font-weight: bold;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        margin: 0 8px;
        &:focus {
          outline: none !important;
        }
        &--active {
          background-color: var(--v-secondary-base) !important;
          color: var(--v-darkgrey-blue-base) !important;
          box-shadow: none;
        }
      }
      &__navigation {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        margin: 0 40px;
      }
      .v-icon {
        font-size: 1.25rem;
        color: var(--v-slate-base);
      }
    }
  }
  .all-services {
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--v-mid-base);
    text-transform: uppercase;
    text-decoration: none;
  }
}
</style>