<template>
  <v-container
    class="main"
    fill-height
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
      >
        <h1>{{ errorHeader }}</h1>

        <v-img
          :src="$t('error.img.error.img')"
          width="200"
          class="my-8 mx-auto"
        />
        <p>{{ errorMessage }} {{ defaultErrorMessage }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {
      errorType: '',
      errorMessage: '',
      defaultErrorMessage: '',
      errorHeader: 'Access Denied'
    }
  },

  created() {
    this.errorType = this.$route.query.errorType
    this.defaultErrorMessage = 'Please contact your adminstrator.'
    switch (this.errorType) {
      case '100':
        this.errorMessage =
          "Your authentication was successful but you have don't have access to the application."
        this.errorHeader = 'Access Denied'
        break
      case '101':
        this.errorMessage = 'Something went wrong. Please try again later.'
        this.errorHeader = 'System Error'
        break
      case '102':
        this.errorMessage =
          "Your authentication was successful but you have don't have access to the application."
        this.errorHeader = 'Access Denied'
        break
      default:
        break
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  text-align: center;
  h1 {
    font-size: 3rem;
    font-weight: normal;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: center;
  }
  p {
    font-size: 0.875rem;
    color: var(--v-slate-base);
    text-align: center;
  }
  .btn {
    background-color: #3182ce !important;
    color: white !important;

    &:hover {
      opacity: 0.9;
    }
  }
}
.toolbar_menu_hide {
  display: none !important;
}
.toolbar_account_hide {
  display: none !important;
}
.d-none .d-lg-flex {
  display: none !important;
}
.toolbar_basket_hide {
  display: none !important;
}
.v-divider_hide {
  display: none !important;
}
</style>
