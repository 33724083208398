import i18n from '@/plugins/i18n'

function formatMonth(val: string) {
  switch (val) {
    case '01':
      return i18n.t('month.txt.January')
    case '02':
      return i18n.t('month.txt.February')
    case '03':
      return i18n.t('month.txt.March')
    case '04':
      return i18n.t('month.txt.April')
    case '05':
      return i18n.t('month.txt.May')
    case '06':
      return i18n.t('month.txt.June')
    case '07':
      return i18n.t('month.txt.July')
    case '08':
      return i18n.t('month.txt.August')
    case '09':
      return i18n.t('month.txt.September')
    case '10':
      return i18n.t('month.txt.October')
    case '11':
      return i18n.t('month.txt.November')
    case '12':
      return i18n.t('month.txt.December')
    default:
      return val
  }
}
export const formatDate = (date: string) => {
  if (!date) return
  const dateArray = date.split('-')
  const year = dateArray[0]
  const month = dateArray[1]
  formatMonth(dateArray[1])
  const day = dateArray[2]

  return `${month}/${day}/${year}`
}
