<template>
  <v-container class="account">
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <router-link
          class="all-services"
          :to="{name: 'services', params: {flagVehicles: false}}"
        >
          <v-icon
            color="peacock"
            width="15px"
          >
            mdi-arrow-left
          </v-icon>
          <span class="ml-4">{{ $t('service_details.txt.all_services') }}</span>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
        pt="0"
      >
        <h1>
          {{ $t('payment_information.txt.my_account_details') }}
        </h1>
        <div class="description">
          <div>
            {{ $t('payment_information.txt.verify_your_information') }}
            <a 
              href="https://paccarsolutions.com/#/nav/customer/list/" 
              target="blank"
            >
              {{ $t('payment_information.txt.verify_your_information_page') }}
            </a>.
          </div>
        </div>
        <div
          v-if="loading"
          class="loader-page"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
        offset-lg="2"
        offset-xl="3"
      >
        <v-card>
          <v-form
            v-if="showForm"
            ref="form"
            v-model="valid"
            lazy-validation
            class="form pa-8"
          >
            <!-- <img
              :src="require('@/assets/imgs/paccar_trucks.svg')"
              :alt="$t('payment_information.img.truck.alt')"
              class="payment-information-truck d-none d-sm-block"
            > -->
            <v-row>
              <v-col
                cols="12"
                sm="9"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="section-title"
                  >
                    <div class="horizontal-spacer" />
                    <h2>{{ $t('payment_information.txt.company_information') }}</h2>
                    <div class="horizontal-spacer" />
                  </v-col>
                  <!--<PaymentInformationInput
                    :key="fieldKey+1"
                    :caption="$t('payment_information.txt.company_id_number')"
                    :is-readonly="true"
                    :name="'accountNumber'"
                    :value="companyInfo.accountNumber"
                    :show-info="true"
                    :tooltip-title="$t('payment_information.txt.company_id_number')"
                    :tooltip-text="$t('payment_information.txt.company_id_number_tt')"
                    :col-size="12"
                  />
                  <v-col cols="12" />-->
                  <PaymentInformationInput
                    :key="fieldKey+2"
                    :caption="$t('payment_information.txt.company_name')"
                    :is-readonly="true"
                    :placeholder="$t('payment_information.txt.company_name_ph')"
                    :name="'companyNameName'"
                    :value="companyInfo.name"
                    :backup-value="createAccountFieldsBackup.companyName"
                    :mandatory="true"
                    :show-info="true"
                    :tooltip-title="$t('payment_information.txt.company_name')"
                    :tooltip-text="$t('payment_information.txt.company_name_tt')"
                    :col-size="12"
                    :field-validation="validateRequired(companyInfo.name)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, companyInfo.name, companyInfo, 'name', $t('payment_information.txt.company_information'), $t('payment_information.txt.company_name'), $t('payment_information.txt.company_name_change'))"
                  />

                  <v-col cols="12" />
                  <PaymentInformationInput
                    :key="fieldKey+3"
                    :caption="$t('payment_information.txt.address')"
                    :is-readonly="true"
                    :placeholder="$t('payment_information.txt.address_ph')"
                    :name="'addressInformation'"
                    :value="companyInfo.address1"
                    :mandatory="true"
                    :show-info="true"
                    :tooltip-title="$t('payment_information.txt.address')"
                    :tooltip-text="$t('payment_information.txt.address_tt')"
                    :col-size="12"
                    :field-validation="validateRequired(companyInfo.address1)"
                    @valueChange="handleChange($event, companyInfo.address1, companyInfo, 'address1', $t('payment_information.txt.company_information'), $t('payment_information.txt.address'), null)"
                  />
                  <v-col cols="py-0" />
                  <PaymentInformationInput
                    :key="fieldKey+4"
                    class="mt-n8"
                    :is-readonly="true"
                    :placeholder="$t('payment_information.txt.address2_ph')"
                    :name="'addressInformation2'"
                    :value="companyInfo.address2"
                    :tooltip-title="$t('payment_information.txt.address')"
                    :tooltip-text="$t('payment_information.txt.address_tt')"
                    :col-size="12"
                    @valueChange="handleChange($event, companyInfo.address2, companyInfo, 'address2', $t('payment_information.txt.company_information'), $t('payment_information.txt.address2'), null)"
                  />
                  <v-col cols="12" />
                  <PaymentInformationInput
                    :key="fieldKey+5"
                    :caption="$t('payment_information.txt.city')"
                    :is-readonly="true"
                    :placeholder="$t('payment_information.txt.city_ph')"
                    :name="'cityInformation'"
                    :value="companyInfo.city"
                    :mandatory="true"
                    :col-size="4"
                    :field-validation="validateRequired(companyInfo.city)"
                    :error-message="$t('payment_informativalidateRequired(data.city)on.txt.required_field')"
                    @valueChange="handleChange($event, companyInfo.city, companyInfo, 'city', $t('payment_information.txt.company_information'), $t('payment_information.txt.city'), null)"
                  />
                  <PaymentInformationInput
                    :key="fieldKey+6"
                    :caption="$t('payment_information.txt.state')"
                    :is-readonly="true"
                    :name="'stateInformation'"
                    :value="companyInfo.state"
                    :mandatory="true"
                    :col-size="4"
                    :field-validation="validateRequired(companyInfo.state)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, companyInfo.state, companyInfo, 'state', $t('payment_information.txt.company_information'), $t('payment_information.txt.state'), null)"
                  />
                  <PaymentInformationInput
                    :key="fieldKey+7"
                    :caption="$t('payment_information.txt.postal_code')"
                    :is-readonly="true"
                    :placeholder="$t('payment_information.txt.postal_code_ph')"
                    :name="'postalCodeInformation'"
                    :value="companyInfo.zipCode"
                    :mandatory="true"
                    :col-size="4"
                    :field-validation="validateRequired(companyInfo.zipCode)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, companyInfo.zipCode, companyInfo, 'zipCode', $t('payment_information.txt.company_information'), $t('payment_information.txt.postal_code'), null)"
                  />
                  <v-col
                    cols="12"
                    lass="py-0"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <span class="caption">{{ $t('payment_information.txt.country') }}</span>
                        <span class="mandatory"> *</span>
                      </v-col>
                      <v-col
                        cols="12"
                        class="field py-0"
                      >
                        <v-autocomplete
                          :key="fieldKey+8"
                          v-model="companyInfo.country"
                          class="select disabled-field"
                          :readonly="true"
                          :items="countries"
                          :placeholder="$t('payment_information.txt.country_ph')"
                          name="'countryInformation'"
                          item-text="name"
                          :return-object="true"
                          append-icon="mdi-chevron-down"
                          :menu-props="{ offsetY: true }"
                          filled
                          rounded
                          dense
                          lazy
                          :background-color="country ? 'pale-two' : 'pink-light'"
                          :error-messages="country ? '' : $t('payment_information.txt.required_field')"
                          @change="handleChange($event.name, companyInfo.country, companyInfo, 'country', $t('payment_information.txt.company_information'), $t('payment_information.txt.country'), $t('payment_information.txt.country_change'))"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="section-title mt-8"
                  >
                    <div class="horizontal-spacer" />
                    <h2>{{ $t('payment_information.txt.contact_information') }}</h2>
                    <div class="horizontal-spacer" />
                  </v-col>

                  <PaymentInformationInput
                    :key="fieldKey+9"
                    :caption="$t('payment_information.txt.first_name')"
                    :is-readonly="isReadonly"
                    :placeholder="$t('payment_information.txt.first_name_ph')"
                    :name="'firstNameLegalPerson'"
                    :value="contactInfo.firstName"
                    :col-size="6"
                    :field-validation="validateRequired(contactInfo.firstName)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, contactInfo.firstName, contactInfo, 'firstName', $t('payment_information.txt.legal_person'), $t('payment_information.txt.first_name'), null)"
                  /> 
                  <PaymentInformationInput
                    :key="fieldKey+10"
                    :caption="$t('payment_information.txt.last_name')"
                    :is-readonly="isReadonly"
                    :placeholder="$t('payment_information.txt.last_name_ph')"
                    :name="'lastNameLegalPerson'"
                    :value="contactInfo.lastName"
                    :mandatory="true"
                    :show-info="true"
                    :tooltip-title="$t('payment_information.txt.legal_person')"
                    :tooltip-text="$t('payment_information.txt.legal_person_tt')"
                    :col-size="6"
                    :field-validation="validateRequired(contactInfo.lastName)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, contactInfo.lastName, contactInfo, 'lastName', $t('payment_information.txt.legal_person'), $t('payment_information.txt.last_name'), null)"
                  />
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    lass="py-0"
                  />
                  <PaymentInformationInput
                    :key="fieldKey+11"
                    :caption="$t('payment_information.txt.email')"
                    :is-readonly="isReadonly"
                    :placeholder="'Email'"
                    :name="'contactWorkEmail'"
                    :value="contactInfo.workEmail"
                    :mandatory="true"
                    :show-info="false"
                    :col-size="6"
                    :field-validation="validateRequired(contactInfo.workEmail)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, contactInfo.workEmail, contactInfo, 'workEmail', $t('payment_information.txt.legal_person'), 'Work Email', null)"
                  />
                      
                  <!--<PaymentInformationInput
                    :key="fieldKey+12"
                    :caption="'Personal Email'"
                    :is-readonly="isReadonly"
                    :placeholder="'Personal Email'"
                    :name="'contactWorkEmail'"
                    :value="contactInfo.personalEmail"
                    :mandatory="false"
                    :show-info="false"
                    :col-size="6"
                    @valueChange="handleChange($event, contactInfo.personalEmail, contactInfo, 'personalEmail', $t('payment_information.txt.legal_person'), 'Personal Email', null)"
                  />
                  
                  <v-col cols="12" />
                  <PaymentInformationInput
                    :key="fieldKey+13"
                    :caption="$t('payment_information.txt.address')"
                    :is-readonly="isReadonly"
                    :placeholder="$t('payment_information.txt.address_ph')"
                    :name="'addressInformation'"
                    :value="contactInfo.address1"
                    :mandatory="true"
                    :show-info="true"
                    :tooltip-title="$t('payment_information.txt.address')"
                    :tooltip-text="$t('payment_information.txt.address_tt')"
                    :col-size="12"
                    :field-validation="validateRequired(contactInfo.address1)"
                    @valueChange="handleChange($event, contactInfo.address1, contactInfo, 'address1', $t('payment_information.txt.company_information'), $t('payment_information.txt.address'), null)"
                  />
                  <v-col cols="py-0" />
                  <PaymentInformationInput
                    :key="fieldKey+14"
                    class="mt-n8"
                    :is-readonly="isReadonly"
                    :placeholder="$t('payment_information.txt.address2_ph')"
                    :name="'addressInformation2'"
                    :value="contactInfo.address2"
                    :tooltip-title="$t('payment_information.txt.address')"
                    :tooltip-text="$t('payment_information.txt.address_tt')"
                    :col-size="12"
                    @valueChange="handleChange($event, contactInfo.address2, contactInfo, 'address2', $t('payment_information.txt.company_information'), $t('payment_information.txt.address2'), null)"
                  />
                  <v-col cols="12" />
                  <PaymentInformationInput
                    :key="fieldKey+15"
                    :caption="$t('payment_information.txt.postal_code')"
                    :is-readonly="isReadonly"
                    :placeholder="$t('payment_information.txt.postal_code_ph')"
                    :name="'postalCodeInformation'"
                    :value="contactInfo.zipCode"
                    :mandatory="true"
                    :col-size="6"
                    :field-validation="validateRequired(contactInfo.zipCode)"
                    :error-message="$t('payment_information.txt.required_field')"
                    @valueChange="handleChange($event, contactInfo.zipCode, contactInfo, 'zipCode', $t('payment_information.txt.company_information'), $t('payment_information.txt.postal_code'), null)"
                  />
                  <PaymentInformationInput
                    :key="fieldKey+16"
                    :caption="$t('payment_information.txt.city')"
                    :is-readonly="isReadonly"
                    :placeholder="$t('payment_information.txt.city_ph')"
                    :name="'cityInformation'"
                    :value="contactInfo.city"
                    :mandatory="true"
                    :col-size="6"
                    :field-validation="validateRequired(contactInfo.city)"
                    :error-message="$t('payment_informativalidateRequired(data.city)on.txt.required_field')"
                    @valueChange="handleChange($event, contactInfo.city, contactInfo, 'city', $t('payment_information.txt.company_information'), $t('payment_information.txt.city'), null)"
                  />

                  <v-col
                    cols="12"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <span class="caption">{{ $t('payment_information.txt.country') }}</span>
                        <span class="mandatory"> *</span>
                      </v-col>
                      <v-col
                        cols="12"
                        class="field py-0"
                      >
                        <v-autocomplete
                          :key="fieldKey+17"
                          :value="contactInfo.country"
                          class="select"
                          :items="countries"
                          :readonly="isReadonly"
                          :placeholder="$t('payment_information.txt.country_ph')"
                          name="'countryLegalPerson'"
                          item-text="name"
                          :return-object="true"
                          append-icon="mdi-chevron-down"
                          :menu-props="{ offsetY: true }"
                          filled
                          rounded
                          dense
                          lazy
                          :background-color="contactInfo.country ? 'pale-two' : 'pink-light'"
                          :error-messages="contactInfo.country ? '' : $t('payment_information.txt.required_field')"
                          @change="handleChange($event.name, contactInfo.country, contactInfo, 'country', $t('payment_information.txt.legal_person'), $t('payment_information.txt.country'), $t('payment_information.txt.country_change'))"
                        />
                        <v-tooltip
                          content-class="info-tooltip"
                          right
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon
                              class="info-tooltip d-none d-sm-block"
                              v-on="on"
                            >
                              mdi-information-variant
                            </v-icon>
                          </template>
                          <v-card
                            elevation="0"
                            flat
                          >
                            <span class="tooltip-title">{{ $t('payment_information.txt.country_ph') }}</span>
                            <p class="tooltip-text">
                              {{ $t('payment_information.txt.legal_country_tt') }}
                            </p>
                          </v-card>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>-->
                </v-row>

                <v-row v-if="!isReadonly">
                  <v-col
                    cols="12"
                    sm="6"
                    class="proceed"
                  >
                    <v-btn
                      class="mt-6"
                      @click="checkChanges"
                    >
                      {{ $t('payment_information.txt.proceed') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-dialog
                  v-model="dialogChanges"
                  width="600"
                  height="600"
                  max-width="700"
                >
                  <v-card class="overflow-hidden">
                    <v-card-title class="headline">
                      {{ $t('payment_information.txt.accept_changes') }}
                    </v-card-title>
                    <v-card-text
                      v-for="sorted in sortedChanges"
                      :key="sorted.index"
                    >
                      <v-card-subtitle class="pl-0 pb-0">
                        {{ sorted[0].type }}
                      </v-card-subtitle>
                      <div
                        v-for="change in sorted"
                        :key="change.title"
                        class="flex"
                      >
                        <label class="py-0 dialog-changes-label">
                          {{ change.title }}:
                        </label>
                        <label class="py-1 dialog-changes-value">
                          {{ change.new }}
                        </label>
                      </div>
                    </v-card-text>
                    <v-progress-linear
                      v-if="submitted"
                      indeterminate
                      color="primary"
                    />
                    <v-card-actions v-else>
                      <v-spacer />
                      <v-btn
                        color="red darken-1"
                        text
                        @click="rollbackChanges"
                      >
                        {{ $t('payment_information.txt.no') }}
                      </v-btn>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="submit"
                      >
                        {{ $t('payment_information.txt.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="notification"
      top
      :color="notificationType"
    >
      {{ notificationText }}
      <v-btn
        color="white"
        text
        @click="notification = false"
      >
        {{ $t('payment_information.txt.close') }}
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import PaymentInformationInput from '@/components/PaymentInformationInput.vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import { remove } from 'lodash'

export default {
  name: 'PaymentInformation',
  components: {
    PaymentInformationInput
  },
  data() {
    return {
      accountDetails: {},
      createAccountFields: {
        companyLegalName: '',
        companyName: ''
      },
      data: {
        lastName: '',
        workEmail: '',
        firstName: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        state: '',
        country: ''
      },
      taxInfo: {
        exemptCertificateId: ''
      },
      invoice: {
        firstName: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        state: '',
        country: '',
        workEmail: ''
      },
      legalPerson: {
        firstName: '',
        lastName: '',
        dayBirth: '',
        monthBirth: '',
        yearBirth: '',
        country: '',
        chamberCommerceNumber: ''
      },
      accountNumber: '',
      invoiceEmail: '',
      country: {},
      differentAddress: false,
      item: 0,
      monthsWith30Days: ['4', '6', '9', '11'],
      countries: [],
      dialog: false,
      valid: true,
      submitted: false,
      rules: {
        //required: value => !!value,
        numbers: value => {
          const pattern = /^[0-9]*$/
          return pattern.test(value) || 'Invalid phone number'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      validVAT: false,
      isReadonly: true,
      createAccountFieldsBackup: {},
      dataBackup: {},
      taxInfoBackup: {},
      invoiceBackup: {},
      legalPersonBackup: {},
      dialogChanges: false,
      dataChanged: [],
      newChange: false,
      sortedChanges: [],
      fieldKey: 0,
      cddCheck: '',
      notification: false,
      notificationText: '',
      notificationType: 'success',
      loading: false,
      showForm: false,
      checkingVAT: false,
      timeoutID: null,
      companyInfo: {
        accountNumber: '',
        name: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        state: '',
        country: ''
      },
      contactInfo: {
        firstName: '',
        lastName: '',
        workEmail: '',
        personalEmail: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        state: '',
        country: ''
      }
    }
  },
  computed: {
    years() {
      const year = []
      const currentYear = new Date().getFullYear()
      for (let i = 1960; i <= currentYear; ++i) {
        year.push({ text: i, value: i.toString() })
      }
      return year
    },
    months() {
      const month = []
      const tt = moment.localeData(this.$i18n.locale).months()
      tt.forEach((element, i) => {
        i++
        month.push({ name: element, number: i.toString() })
      })
      return month
    },
    days() {
      const day = []
      let lastday
      if (this.monthsWith30Days.includes(this.month)) {
        lastday = 30
      } else if (this.leapYear(this.year)) {
        lastday = 29
      }
      if (this.legalPerson.monthBirth === '02' && !this.leapYear(this.year)) {
        lastday = 28
      } else {
        lastday = 31
      }
      for (let i = 1; i <= lastday; ++i) {
        day.push({ text: i, value: i.toString() })
      }
      return day
    },
    isDisabled: function() {
      return (
        !this.dataChanged.length ||
        !this.validateRequired(this.createAccountFields.companyName) ||
        !this.validateRequired(this.createAccountFields.companyLegalName) ||
        !this.validateRequired(this.data.address1) ||
        !this.validateRequired(this.data.zipCode) ||
        !this.validateRequired(this.data.city) ||
        !this.validateRequired(this.data.state) ||
        !this.validateEmail(this.data.workEmail) ||
        !this.validateRequired(this.data.firstName) ||
        !this.validateRequired(this.data.lastName) ||
        !this.validateEmail(this.invoice.workEmail) ||
        !this.validateRequired(this.legalPerson.firstName) ||
        !this.validateRequired(this.legalPerson.lastName) ||
        !this.validateBirth(this.legalPerson.yearBirth) ||
        (this.differentAddress &&
          (!this.validateRequired(this.invoice.address1) ||
            !this.validateRequired(this.invoice.zipCode) ||
            !this.validateRequired(this.invoice.city) ||
            !this.validateRequired(this.invoice.state)))
      )
    },

    formatVatNumber() {
      if (this.taxInfo.exemptCertificateId === '') return ''

      const vatNumber = this.taxInfo.exemptCertificateId
      const includeCountryCode = this.countries.find(item =>
        vatNumber.startsWith(item.alpha2Code)
      )
      if (includeCountryCode) {
        return vatNumber
      } else {
        return `${this.country.alpha2Code}${vatNumber}`
      }
    }
  },
  async created() {
    // await this.getCountries()
    await this.getAccountInformation()
    await this.setCountry()
    //await this.setEditUserData()
    await this.validateVAT()
    await this.checkAddress()
  },
  methods: {
    ...mapActions('notification', ['setSnackbar']),
    leapYear: function(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
    },
    checkAddress() {
      if (
        this.data.address1 === this.invoice.address1 &&
        this.data.address2 === this.invoice.address2 &&
        this.data.zipCode === this.invoice.zipCode &&
        this.data.city === this.invoice.city &&
        this.data.state === this.invoice.state &&
        this.data.country === this.invoice.country
      )
        this.differentAddress = false
      else this.differentAddress = true
    },
    handleChange(
      newValue,
      defaultValue,
      object,
      field,
      dialogtype,
      dialogtitle,
      dialogtext
    ) {
      this.$set(object, field, newValue)

      if (newValue != defaultValue) {
        const recorded = this.dataChanged.find(
          data => data.type === dialogtype && data.title === dialogtitle
        )
        if (recorded) {
          recorded.new = newValue
        } else {
          this.dataChanged.push({
            type: dialogtype,
            title: dialogtitle,
            new: newValue,
            default: defaultValue
          })
        }
        if (dialogtext && this.cddCheck) {
          this.setSnackbar({
            showing: true,
            type: 'navy',
            text: dialogtext
          })
        }
      } else if (this.dataChanged.length) {
        remove(
          this.dataChanged,
          item => item.type === dialogtype && item.title === dialogtitle
        )
      }
      if (field === 'country') {
        if (this.formatVatNumber.startsWith(this.country.alpha2Code)) {
          this.validateVAT()
        } else {
          this.validVAT = this.taxInfo.exemptCertificateId === ''
        }
      }
      if (field === 'exemptCertificateId') {
        this.validateVAT()
      }
    },
    getAccountInformation() {
      this.loading = true
      return apiCalls
        .get(api.AccountDetails, '', '')
        .then(res => {
          //map company info
          this.companyInfo.accountNumber =
            res.data.companyInfo.basicInfo.accountNumber
          this.companyInfo.name = res.data.companyInfo.basicInfo.name
          this.companyInfo.address1 =
            res.data.companyInfo.billToContact.address1
          this.companyInfo.address2 =
            res.data.companyInfo.billToContact.address2
          this.companyInfo.zipCode = res.data.companyInfo.billToContact.zipCode
          this.companyInfo.city = res.data.companyInfo.billToContact.city
          this.companyInfo.state = res.data.companyInfo.billToContact.state
          this.companyInfo.country = res.data.companyInfo.billToContact.country
          this.countries.push(this.companyInfo.country)

          //map contact info
          this.contactInfo.firstName = res.data.userInfo.billToContact.firstName
          this.contactInfo.lastName = res.data.userInfo.billToContact.lastName
          this.contactInfo.workEmail = res.data.userInfo.billToContact.workEmail
          this.contactInfo.personalEmail =
            res.data.userInfo.billToContact.personalEmail
          this.contactInfo.address1 = res.data.userInfo.billToContact.address1
          this.contactInfo.address2 = res.data.userInfo.billToContact.address2
          this.contactInfo.zipCode = res.data.userInfo.billToContact.zipCode
          this.contactInfo.city = res.data.userInfo.billToContact.city
          this.contactInfo.state = res.data.userInfo.billToContact.state
          this.contactInfo.country = res.data.userInfo.billToContact.country

          // I want the My Account page to be read only for Users that are registered in Paccar Solutions
          // and to be “updatetable” for Users not registered in Paccar Solutions (direct entry),
          // so that customer & User information between Paccar Solutions and Zuora keep in sync.
          this.isReadonly = res.data.userInfo.basicInfo.isPaccarUser
        })
        .finally(() => {
          this.loading = false
          this.showForm = true
        })
    },
    getCountries() {
      return apiCalls.get(api.Countries, '', '').then(res => {
        this.countries = res.data
      })
    },
    setCountry() {
      this.countries.forEach(country => {
        if (country.name === this.data.country) {
          this.country = country
        }
      })
    },
    validateVAT() {
      if (this.taxInfo.exemptCertificateId === '') {
        this.validVAT = true
        return
      }

      if (this.timeoutID) {
        clearTimeout(this.timeoutID)
        this.timeoutID = null
      }
      this.timeoutID = setTimeout(async () => {
        try {
          this.checkingVAT = true
          const res = await apiCalls.post(
            api.CheckVAT,
            '',
            '',
            "{country:'" +
              this.country.alpha2Code +
              "', vat_ID:'" +
              this.formatVatNumber +
              "'}"
          )
          if (res.status === 200) {
            this.validVAT = true
          } else {
            this.validVAT = false
          }
        } finally {
          this.checkingVAT = false
        }
      }, 500)
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    resetForm() {
      this.$refs.form.reset()
    },
    checkChanges() {
      if (this.dataChanged.length > 0) {
        this.sortedChanges = this.dataChanged.reduce(function(r, a, i) {
          if (!i || r[r.length - 1][0].type !== a.type) {
            return r.concat([[a]])
          }
          r[r.length - 1].push(a)
          return r
        }, [])
        this.dialogChanges = true
      } else this.submit()
    },
    async rollbackChanges() {
      this.dialogChanges = false
      this.dataChanged = []
      await this.getAccountInformation()
      this.fieldKey = Math.random()
    },
    async submit() {
      try {
        this.submitted = true
        if (!this.differentAddress) {
          this.invoice.address1 = this.data.address1
          this.invoice.address2 = this.data.address2
          this.invoice.zipCode = this.data.zipCode
          this.invoice.city = this.data.city
          this.invoice.state = this.data.state
          this.invoice.country = this.data.country
          this.invoice.firstName = this.data.firstName
        }
        const response = await apiCalls.put(
          api.AccountContactUpdate,
          '',
          '',
          this.contactInfo
        )
        if (response.status == 200) {
          if (!this.cddCheck) {
            this.$router.push({
              name: 'payment_method'
            })
          } else {
            this.$router.push({
              name: 'home'
            })
          }

          this.setSnackbar({
            showing: true,
            type: 'success',
            text: this.$t('payment_information.txt.notification_success')
          })
        } else {
          this.setSnackbar({
            showing: true,
            type: 'error',
            text: this.$t('payment_information.txt.notification_error')
          })
        }
      } finally {
        this.submitted = false
        this.dialogChanges = false
      }
    },
    validateRequired(value) {
      return !!value
    },
    validateBirth(yearBirth) {
      return parseInt(yearBirth) >= 1960
    },
    validateEmail(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.account {
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  h1 {
    margin-bottom: 22px;
    font-size: 3rem;
    font-weight: 300;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: left;
  }
  .description {
    font-size: 0.875rem;
    color: var(--v-slate-base);
  }
  .form {
    font-size: 0.875rem;
    position: relative;
    overflow: initial;
    @media screen and (max-width: 960px) {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
    }
    .disabled-field {
      input {
        color: darkgray !important;
      }
    }
    .field {
      position: relative;
      .select {
        font-size: 0.875rem;
        .v-input__slot {
          border: 1px solid var(--v-pale-three-base) !important;
        }
        .v-icon {
          margin-top: 0 !important;
        }
      }
      .info-tooltip {
        color: var(--v-bluegrey-base) !important;
        border-radius: 8px;
        border: solid 1px;
        height: 16px;
        width: 16px;
        font-size: 0.875rem !important;
        position: absolute;
        top: 12px;
        right: -12px;
      }
    }
    .payment-information-truck {
      top: 80px;
      right: -204px;
      display: flex;
      width: 450px;
      position: absolute;
      z-index: 0;
    }
    .section-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 16px 30px;
      h2 {
        margin: 0 10px;
        color: var(--v-bluegrey-base) !important;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: normal;
      }
      .horizontal-spacer {
        border-top: 1px solid var(--v-pale-three-base);
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
    }
    .invoice-checkbox {
      margin-bottom: 20px;
      .v-label {
        font-size: 0.875rem;
        color: var(--v-dark-base);
      }
    }
    .payment-buttons {
      .v-btn {
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
        border: solid 1px var(--v-cloudy-base) !important;
        border-radius: 4px !important;
        width: 100%;
      }
    }
    .proceed {
      .v-btn {
        height: 48px !important;
        border-radius: 5px;
        background-image: linear-gradient(
          to bottom,
          #266cab 0%,
          #01529c
        ) !important;
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
        color: white !important;
        width: 100%;
        &--disabled {
          background-image: none !important;
        }
      }
    }
  }
  .vat-checked {
    margin-top: -25px;
    padding: 0 24px;
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--v-success-darken1);
  }
}
.dialog-changes-label {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--v-mid-base) !important;
  text-transform: uppercase;
}
.dialog-changes-value {
  font-size: 0.75rem;
}
.payment-iframe {
  width: 100%;
  height: 900px;
}
</style>