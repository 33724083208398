<template>
  <div class="search">
    <v-row>
      <p class="vehicle-overview-search-text">
        {{ $t('vehicles_overview.txt.vehicles_description') }}        
      </p>
    </v-row>
    <v-card-title>
      <v-row>
        <v-col
          class="search_filter"
          cols="6" 
        >
          <v-icon color="cloudy">
            mdi-filter
          </v-icon>
          <v-chip
            v-for="(item, index) in filteredProducts"
            :key="item.text"
            close
            close-icon="mdi-close"
            color="cloudy"
            text-color="slate"
            class="chip-filter"
            center
            @click:close="filteredProducts.splice(index, 1)"
          >
            {{ item.name }}
          </v-chip>
          <v-menu 
            close-on-content-click 
            offset-x 
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn 
                text 
                color="peacock" 
                class="add-filter" 
                v-on="on"
              >
                + {{ $t('search.txt.add_filter') }}
              </v-btn>
            </template>
            <v-card>
              <v-list 
                dense 
                rounded
              >
                <template v-for="(item, index) in normalizedProducts">
                  <v-list-item
                    v-if="!filteredProducts.includes(item)"
                    :key="index"
                    @click="filteredProducts.push(item)"
                  >
                    <v-list-item-title v-text="item.name" />
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="textToSearch"
            append-icon="mdi-magnify"
            label="Search vin, unit number, managed by"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>
  </div>
</template>

<script>
export default {
  name: 'VehiclesSearch',

  props: {
    products: {
      type: Array,
      required: true
    },

    productsPrismic: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      filteredProducts: [],
      textToSearch: '',
      sortBy: 'licensePlate',
      viewType: 'list'
    }
  },

  computed: {
    normalizedProducts() {
      return this.products.map(p => {
        const prodcutPrismic = this.productsPrismic.find(pp =>
          pp.externalkey[0].text.includes(p.sku)
        )
        return {
          ...p,
          externalKey:
            (prodcutPrismic && prodcutPrismic.externalkey[0].text) || p.id,
          name: (prodcutPrismic && prodcutPrismic.title[0].text) || p.name
        }
      })
    },

    sortItems() {
      return [
        { key: 'vin', text: this.$t('search.txt.vin_number') },
        { key: 'unitNumber', text: this.$t('search.txt.unit_number') },
        { key: 'invoiceOwner', text: this.$t('search.txt.invoice_owner') }
      ]
    }
  },

  watch: {
    textToSearch() {
      this.$emit('textToSearch', this.textToSearch)
    },

    filteredProducts: {
      handler: function(val) {
        const filteredProductsKey = val.map(item => item.externalKey)
        this.$emit('productsFilter', filteredProductsKey)
      },
      deep: true
    }
  },

  async created() {
    this.$emit('sortBy', this.sortBy)
  },

  methods: {
    sortByItem(item) {
      this.sortBy = item
      this.$emit('sortBy', this.sortBy)
    },

    changeView(view) {
      this.viewType = view
      this.$emit('viewType', this.viewType)
    }
  }
}
</script>

<style lang="scss">
.search {
  &-input {
    .v-input {
      &__slot {
        &:before {
          border-color: var(--v-cloudy-base) !important;
        }

        .v-icon {
          margin-top: 0 !important;
          color: var(--v-cloudy-base);
        }
      }
    }
  }

  .sort-label {
    font-size: 0.875rem;
    font-weight: bold;
    text-align: right;
    color: var(--v-slate-base);
    margin-right: 8px;
  }

  .sort-items {
    max-width: 200px;
    height: 32px;
    font-size: 0.875rem;

    .v-input {
      &__slot {
        min-height: 32px !important;
      }

      &__append-inner {
        margin-top: 0 !important;
      }
    }
  }

  .view-btn {
    &.active {
      i {
        color: var(--v-peacock-base);
      }
    }
  }

  &_filter {
    .chip-filter {
      font-size: 0.75rem;
      margin-left: 16px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0 16px;
    }

    .add-filter {
      font-weight: bold;
      letter-spacing: normal;
      font-size: 0.8125rem;
    }
  }
  .vehicle-overview-search-text {
    padding: 0px 0px 0px 20px;
    font-size: 16px;
  }
}
</style>
