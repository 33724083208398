<template>
  <v-container class="account">
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <router-link
          class="all-services"
          :to="{name: 'services', params: {flagVehicles: false}}"
        >
          <v-icon
            color="peacock"
            width="15px"
          >
            mdi-arrow-left
          </v-icon>
          <span class="ml-4">{{ $t('service_details.txt.all_services') }}</span>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
        pt="0"
      >
        <h1>
          {{ $t('payment_method.txt.payment_methods') }} 
        </h1>
        <div class="description">
          <v-divider
            class="mx-4"
            horizontal
          />
          <!-- <div>
            {{ $t('payment_method.txt.description') }}
          </div> -->
        </div>
      </v-col>
    </v-row>
    <v-row> 
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
        offset-lg="2"
        offset-xl="3"
      >
        <div style="padding-bottom:1rem;">
          <h3>{{ $t('payment_method.txt.my_default_payment_method') }} </h3>
        </div>
        <v-expansion-panels v-if="hasDefaultPayment">
          <v-expansion-panel>
            <div
              v-if="loadingCurrentPayment"
              class="loader"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <v-expansion-panel-header expand-icon="">
              <v-img
                max-height="20"
                max-width="35"
                :src="evaluatePaymentType(defaultPayment.type)"
              />
              <v-divider
                class="mx-4"
                vertical
              />
              <span class="caption">{{ defaultPayment.accountName }}</span>
              <span class="caption">{{ defaultPayment.accountNumberMask }} </span>
              <div 
                v-if="defaultPayment.expired" 
                class="d-flex align-center"
              >
                <v-icon
                  color="red"
                  width="15px"
                >
                  mdi-close-octagon
                </v-icon>

                <span class="card-expired"> Expired </span>
              </div>
            </v-expansion-panel-header>
            <!-- <v-expansion-panel-content v-if="defaultPayment.type!='ACH'">
              <p><strong class="caption">Billing Address:</strong> {{ item.creditCardAddress1 }}</p>
              <p><strong class="caption">Expiration:</strong> {{ item.creditCardExpirationMonth }}-{{ item.creditCardExpirationYear }}</p>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="defaultPayment.type='ACH'">
              <p><strong class="caption">Aba:</strong> {{ item.achAbaCode }}</p>
              <p><strong class="caption">Name:</strong> {{ item.achAccountName }}</p>
              <p><strong class="caption">Account Type:</strong> {{ item.achAccountType }}</p>
            </v-expansion-panel-content> -->
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels v-else>
          <v-expansion-panel-header
            v-if="!loadingCurrentPayment"
            expand-icon=""
          >
            <v-alert
              type="error"
              outlined
              border="bottom"
              prominent
              elevation="2"
            >
              <p>To get started with subscriptions, please add a new default payment method.</p>
            </v-alert>
          </v-expansion-panel-header>
        </v-expansion-panels>
        <div style="padding-top:1rem;">
          <h3>{{ $t('payment_method.txt.add_a_new_payment_method') }} </h3>
        </div>
      </v-col>   
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
        offset-lg="2"
        offset-xl="3"
      >
        <div
          v-if="loading"
          class="loader"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
        <v-card>
          <v-form
            v-if="showForm"
            ref="form"
            v-model="valid"
            lazy-validation
            class="form pa-8"
          >
            <!-- <img
              :src="require('@/assets/imgs/paccar_trucks.svg')"
              :alt="$t('payment_information.img.truck.alt')"
              class="truck d-none d-sm-block"
            > -->
            <v-row>
              <v-col
                cols="12"
                sm="9"
              >
                <v-row>
                  <v-col cols="12">
                    <span class="caption">{{ $t('payment_method.txt.choose_payment_method') }}</span>
                  </v-col>
                  <v-dialog
                    v-model="dialogCCard"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-col
                        cols="12"
                        sm="6"
                        class="payment-buttons py-1"
                      >
                        <v-btn
                          class="elevation-0"
                          :class="{active : activePaymentMethod == 'BankTransfer' }"
                          outlined
                          height="48"
                          min-width="140"
                          color="mid"
                          v-on="on"
                          @click="handleClick('directDebit')"
                        >
                          <span class="payment-choosen">ACH</span>
                        </v-btn>                        
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        class="payment-buttons py-1"
                      >
                        <v-btn
                          class="elevation-0"
                          :class="{active : activePaymentMethod == 'CreditCard' }"
                          outlined
                          height="48"
                          color="mid"
                          v-on="on"
                          @click="handleClick('creditCard')"
                        >
                          <span
                            class="payment-choosen"
                          >{{ $t('payment_method.txt.credit_card') }} </span>
                        </v-btn>
                      </v-col>
                    </template>
                    <v-card>
                      <div
                        v-if="loadingCCard"
                        class="loader-dialog"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        />
                      </div>
                      <div id="zuora_placeholder">
                        <div id="zuora_payment" />
                      </div>
                    </v-card>
                  </v-dialog>
                  <!--<v-col
                    cols="12"
                    sm="6"
                    class="proceed"
                  >
                    <v-btn
                      class="mt-6"
                      :disabled="isDisabled"
                      @click="handleSubmit"
                    >
                      {{ $t('payment_method.txt.proceed') }}
                    </v-btn>
                  </v-col>-->
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="6"
        offset-lg="2"
        offset-xl="3"
      >
        <div v-if="hasCreditCards && showCreditCards">
          <div>
            <h3>{{ $t('payment_method.txt.my_credit_cards') }} </h3>
          </div>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="">
                <span
                  class="caption"
                  style="color:var(--v-bluegrey-base) !important"
                >{{ $t('payment_method.txt.name') }} </span>
                <span 
                  class="caption float-right"
                  style="color:var(--v-bluegrey-base) !important"
                > {{ $t('payment_method.txt.number') }} </span>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item,i) in availablePaymentMethods"
              :key="i"
            >
              <div
                v-if="loadingCreditCards"
                class="loader"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                />
              </div>
              <v-expansion-panel-header
                :color="IsCurrentPayment(item) ? 'green': 'white'"
              >
                <v-img
                  max-height="20"
                  max-width="35"
                  :src="evaluatePaymentType(item.creditCardType)"
                />
                <v-divider
                  class="mx-4"
                  vertical
                />
                <span class="caption">{{ item.creditCardHolderName }}</span>
                <span class="float-right caption"> {{ item.creditCardNumberMask }} </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p><strong class="caption">Billing Address:</strong></p>
                <p>{{ item.creditCardAddress1 }}<br>{{ item.creditCardCity }}, {{ item.creditCardState }} {{ item.creditCardPostalCode }}</p>
                <p><strong class="caption">Expiration:</strong> {{ item.creditCardExpirationMonth }}-{{ item.creditCardExpirationYear }}</p>
                <v-btn
                  depressed
                  color="primary"
                  outlined
                  class="float-right"
                  @click="confirmDefault(item)"
                >
                  <span>Set as Default</span>
                </v-btn>
                <v-btn
                  depressed
                  color="error"
                  outlined
                  @click="confirmDelete(item)"
                >
                  Remove
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="hasAchs && showAchs">
          <div style="padding-bottom:1rem; padding-top:1rem;">
            <h3>My ACH Accounts</h3>
          </div>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item,i) in availableAchs"
              :key="i"
            >
              <v-expansion-panel-header>
                <v-img
                  max-height="20"
                  max-width="35"
                  :src="evaluatePaymentType('ACH')"
                />
                <v-divider
                  class="mx-4"
                  vertical
                />
                <span class="caption">{{ item.achBankName }}</span>
                <span class="caption float-right">{{ item.achAccountNumber }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p><strong class="caption">Aba:</strong> {{ item.achAbaCode }}</p>
                <p><strong class="caption">Name:</strong> {{ item.achAccountName }}</p>
                <p><strong class="caption">Account Type:</strong> {{ item.achAccountType }}</p>
                <v-btn
                  depressed
                  color="error"
                  outlined
                  @click="confirmDelete(item)"
                >
                  Remove
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  outlined
                  class="float-right"
                  @click="confirmDefault(item)"
                >
                  Set as Default
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="notification"
      top
      :color="notificationType"
    >
      {{ notificationText }}
      <v-btn
        color="white"
        text
        @click="notification = false"
      >
        {{ $t('payment_method.txt.close') }}
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn 
          v-model="dialog" 
          v-on="on"
        >
          <template v-slot:label>
            <div>
              Set as default 
            </div>
          </template>
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title class="text-h6">
          {{ isToSetAsDefault ? 'Default Confirmation' : 'Remove Confirmation' }}
        </v-card-title>

        <v-card-text>
          <h3>{{ isToSetAsDefault ? `Are you sure you want to set ${popupModel.creditCardNumberMask || popupModel.achAccountNumber} as default?` : `Are you sure you want to delete ${popupModel.creditCardNumberMask || popupModel.achAccountNumber}?` }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            outlined
            @click="cancelPopup"
          >
            {{ $t('payment_method.txt.cancel') }}
          </v-btn>
          <v-btn
            color="error"
            dark
            outlined
            @click="SetOrDeletePayments(popupModel.id, isToSetAsDefault)"
          >
            {{ $t('payment_method.txt.confirm') }}
          </v-btn>
        </v-card-actions>
        <div
          v-if="loadingPopup"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          />
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PaymentInformation',
  data() {
    return {
      popupModel: {},
      showCreditCards: false,
      showAchs: false,
      isToSetAsDefault: false,
      dialog: false,
      cCardPaymentURL: '',
      dialogCCard: false,
      dialogDDebit: false,
      valid: true,
      submitted: false,
      isReadonly: false,
      notification: false,
      notificationText: '',
      notificationType: 'success',
      loading: false,
      loadingCreditCards: false,
      loadingCCard: false,
      loadingPopup: false,
      showForm: false,
      activePaymentMethod: '',
      loadingCurrentPayment: false,
      accountId: '',
      availablePaymentMethods: [
        {
          creditCardType: '',
          creditCardHolderName: '',
          creditCardNumberMask: '',
          id: ''
        }
      ],
      availableAchs: [
        {
          achAccountName: '',
          achAccountNumber: '',
          achAccountType: '',
          achBankName: '',
          id: ''
        }
      ],
      defaultPayment: {},
      cardExpired: true
    }
  },
  computed: {
    ...mapState('account', [
      'currency',
      'accountDetails',
      'accountInformation',
      'payload',
      'appContext'
    ]),
    isDisabled: function() {
      // TODO: Adjust the disable button rules
      return false
    },
    hasDefaultPayment() {
      if (!this.defaultPayment.type) {
        return false
      }
      return true
    }
  },
  // * Adds the zuora js file to the header, for rendering and callback handling
  mounted() {
    const zuora = document.createElement('script')
    zuora.setAttribute(
      'src',
      'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js'
    )
    zuora.async = true
    document.head.appendChild(zuora)
  },
  async created() {
    await this.getDefaultPaymentMethod()
    await this.getAvailablePaymentMethods()
    await this.getAccountInformation()
  },
  methods: {
    ...mapActions('notification', ['setSnackbar']),
    async handleClick(e) {
      // clear iframe
      const iframePlaceholder = document.getElementById('zuora_placeholder')
      if (iframePlaceholder)
        iframePlaceholder.innerHTML = '<div id="zuora_payment"></div>'

      Z.setEventHandler('onloadCallback', () => {
        // * Turns off the loading when the rendering is done.
        this.loadingCCard = false
        // console.log('loadingCCard callback', this.loadingCCard)
      })
      this.loadingCCard = true
      // console.log('loadingCCard', this.loadingCCard)
      // * Gets the params for the zuora rendering function
      // const cCardPaymentURL = await this.getPaymentURl()
      const cCardPaymentURL = await apiCalls
        .post('/api/payment/hpp/zuora', '', '', {
          PaymentMethodType: e == 'directDebit' ? 4 : 1
        })
        .then(res => {
          return res.data
        })

      // * Build the params for the Z.render function
      const params = {
        tenantId: cCardPaymentURL.tenantId,
        id: cCardPaymentURL.pageId,
        token: cCardPaymentURL.token,
        signature: cCardPaymentURL.signature,
        key: cCardPaymentURL.key,
        url: cCardPaymentURL.url,
        style: 'inline',
        submitEnabled: true,
        locale: 'en-us' //ocalStorage.getItem('language')
      }

      const prepopulateFields = {}
      // * Renders the credit card form in the specified element with id="zuora_payment"
      Z.render(params, prepopulateFields, this.handleCallback)
    },
    hasCreditCards() {
      if (
        this.availablePaymentMethods !== undefined ||
        this.availablePaymentMethods !== null
      ) {
        return true
      } else {
        return false
      }
    },
    hasAchs() {
      if (this.availableAchs !== undefined || this.availableAchs !== null) {
        return true
      } else {
        return false
      }
    },
    cancelPopup() {
      this.dialog = false
    },
    SetOrDeletePayments(paymentId, setDefault) {
      setDefault
        ? this.confirmedDefault(paymentId)
        : this.confirmedDelete(paymentId)
    },
    confirmedDelete(paymentId) {
      this.loadingCreditCards = true
      this.loadingPopup = true
      return apiCalls
        .delete(api.DeletePaymentMethod, '', paymentId)
        .then(() => (this.dialog = false))
        .then(() => apiCalls.get(api.GetAllPaymentMethods, '', 'TestTest'))
        .then(res => {
          this.availablePaymentMethods = res.data.creditCards
          this.availableAchs = res.data.debitDirects
          this.accountId = res.data.accountId
        })
        .then(() =>
          this.setSnackbar({
            showing: true,
            type: 'success',
            text: this.$t('Successfully deleted payment_method')
          })
        )
        .catch(error => {
          if (error.request.status === 400) {
            this.dialog = false
            this.setSnackbar({
              showing: true,
              type: 'warning',
              text: this.$t(
                'A default payment method is required. Please select another payment method as default before deleting.'
              )
            })
          }
        })
        .finally(() => {
          this.loadingCreditCards = false
          this.loadingPopup = false
        })
    },
    confirmedDefault(paymentId) {
      this.loadingCurrentPayment = true
      this.loadingPopup = true
      return apiCalls
        .post(api.SetDefaultPaymentMethod, '', '', {
          accountId: this.accountId,
          paymentMethodId: paymentId
        })
        .then(() => (this.dialog = false))
        .then(() => apiCalls.get(api.GetDefaultPayment, '', ''))
        .then(res => {
          this.defaultPayment = res.data
        })
        .then(() =>
          this.setSnackbar({
            showing: true,
            type: 'success',
            text: this.$t('Successfully set payment-method as default')
          })
        )
        .finally(() => {
          this.loadingCurrentPayment = false
          this.loadingPopup = false
        })
    },
    confirmDefault(paymentMethod) {
      this.popupModel = paymentMethod
      this.isToSetAsDefault = true
      this.dialog = true
    },
    confirmDelete(paymentMethod) {
      this.popupModel = paymentMethod
      this.isToSetAsDefault = false
      this.dialog = true
    },
    evaluatePaymentType(type) {
      if (type === 'Visa') {
        return require('@/assets/imgs/visa_logo.svg')
      }
      if (type === 'MasterCard') {
        return require('@/assets/imgs/mc_logo.svg')
      }
      if (type === 'ACH') {
        return require('@/assets/imgs/ach.svg')
      }
    },
    async getDefaultPaymentMethod() {
      this.loadingCurrentPayment = true
      return apiCalls
        .get(api.GetDefaultPayment, '', '')
        .then(res => {
          this.defaultPayment = res.data
        })
        .finally(() => {
          this.loadingCurrentPayment = false
        })
    },
    async getAvailablePaymentMethods() {
      this.loadingCreditCards = true
      return apiCalls
        .get(api.GetAllPaymentMethods, '', 'TestTest')
        .then(res => {
          this.availablePaymentMethods = res.data.creditCards
          this.availableAchs = res.data.debitDirects
          this.accountId = res.data.accountId
          if (
            this.availablePaymentMethods &&
            this.availablePaymentMethods.length
          ) {
            this.showCreditCards = true
          }
          if (this.availableAchs && this.availableAchs.length) {
            this.showAchs = true
          }
        })
        .finally(() => {
          this.loadingCreditCards = false
          this.dialog = false
        })
    },
    async getAccountInformation() {
      this.loading = true
      return apiCalls
        .get(api.AccountDetails, '', '')
        .then(res => {
          this.activePaymentMethod =
            res.data.paymentMethodInfo?.paymentMethodType
          this.loading = false
          this.showForm = true
        })
        .finally(() => {
          this.loading = false
          this.showForm = true
        })
    },
    getPaymentURl(apiEndpoint) {
      return apiCalls.get(apiEndpoint, '', '').then(res => {
        return res.data
      })
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    resetForm() {
      this.$refs.form.reset()
    },
    handleSubmit() {
      this.$router.push({
        name: 'home'
      })
    },
    IsCurrentPayment(item) {
      if (this.defaultPayment.id === item.id) {
        return true
      }
      return false
    },
    // * Handles the callback for the credit card form submit

    handleCallback(response) {
      this.dialogCCard = false
      this.loadingCurrentPayment = true
      if (response.success) {
        apiCalls
          .post(api.SetDefaultPaymentMethod, '', '', {
            accountId: this.appContext.childId,
            paymentMethodId: response.refId
          })
          .then(() => {
            this.setSnackbar({
              showing: true,
              type: 'success',
              text: this.$t('Successfully set payment_method as default')
            })
          })
          .then(() => apiCalls.get(api.GetDefaultPayment, '', ''))
          .then(res => {
            this.defaultPayment = res.data
            this.loadingCurrentPayment = false
          })
          .then(() =>
            apiCalls.get(api.GetAllPaymentMethods, '', 'TestTest').then(res => {
              this.availablePaymentMethods = res.data.creditCards
              this.availableAchs = res.data.debitDirects
              this.accountId = res.data.accountId
              if (
                this.availablePaymentMethods &&
                this.availablePaymentMethods.length
              ) {
                this.showCreditCards = true
              }
              if (this.availableAchs && this.availableAchs.length) {
                this.showAchs = true
              }
            })
          )
        // Handle the response after the Payment Page is
        // successfully submitted.
        this.setSnackbar({
          showing: true,
          type: 'success',
          text: this.$t('payment_method.txt.notification_success')
        })
        const payload = {
          referenceID: response.refId,
          autoPay: true
        }
        apiCalls.post(api.PaymentSetMethod, '', '', payload).then(res => {
          if (res.status == 200) {
            this.$router.push({
              name: 'payment_confirmation'
            })
          } else {
            this.setSnackbar({
              showing: true,
              type: 'error',
              text: res.data.message
            })
          }
        })
      } else {
        if (response.responseFrom == 'Response_From_Submit_Page') {
          this.setSnackbar({
            showing: true,
            type: 'error',
            text: response.errorMessage
          })
        } else {
          // Handle the error response when the Payment Page
          // request failed.
          this.setSnackbar({
            showing: true,
            type: 'error',
            text: response.errorMessage
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.account {
  .loader {
    text-align: center;
    padding-top: 48px;
  }
  h1 {
    margin-bottom: 22px;
    font-size: 3rem;
    font-weight: 300;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: left;
  }
  .description {
    font-size: 0.875rem;
    color: var(--v-slate-base);
  }
  .form {
    font-size: 0.875rem;
    position: relative;
    overflow: initial;
    @media screen and (max-width: 960px) {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
    }
    .truck {
      top: -100px;
      right: -254px;
      display: flex;
      width: 450px;
      position: absolute;
      z-index: 2;
    }
    .section-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 16px 30px;
      h2 {
        margin: 0 10px;
        color: var(--v-bluegrey-base) !important;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: normal;
      }
      .horizontal-spacer {
        border-top: 1px solid var(--v-pale-three-base);
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
    }
    .payment-buttons {
      .v-btn {
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
        border: solid 1px var(--v-cloudy-base) !important;
        border-radius: 4px !important;
        width: 100%;
      }
      .active {
        background-color: var(--v-cloudy-base) !important;
      }
    }
    .proceed {
      .v-btn {
        height: 48px !important;
        border-radius: 5px;
        background-image: linear-gradient(
          to bottom,
          #266cab 0%,
          #01529c
        ) !important;
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
        color: white !important;
        width: 100%;
        &--disabled {
          background-image: none !important;
        }
      }
    }
  }
  .vat-checked {
    margin-top: -25px;
    padding: 0 24px;
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--v-success-darken1);
  }
  .all-services {
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--v-mid-base);
    text-transform: uppercase;
    text-decoration: none;
  }
}
.loader-dialog {
  text-align: center;
  padding: 8px;
}
.dialog-changes-label {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--v-mid-base) !important;
  text-transform: uppercase;
}
.dialog-changes-value {
  font-size: 0.75rem;
}
.payment-iframe {
  width: 100%;
  height: 900px;
}
.card-expired {
  color: red;
  margin-left: 5px;
}
</style>