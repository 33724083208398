<template>
  <v-container>
    <v-row
      v-if="loading"
      class="loader-page"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-row>
    <div
      v-else
      class="vehicle pb-6"
    >
      <v-row>
        <v-col>
          <router-link
            class="back-vehicles"
            :to="prevRoute && prevRoute.name ? prevRoute.path : { name: 'vehicles', params: { flagVehicles: true } }"
          >
            <v-icon
              color="mid"
              width="15px"
            >
              mdi-arrow-left
            </v-icon>
            <span v-if="prevRoute && prevRoute.name==='service-detail'">
              {{ $t('vehicle_details.txt.back') }}
            </span>
            <span v-else>
              {{ $t('vehicle_details.txt.all_vehicles') }}
            </span>
          </router-link>
        </v-col>
      </v-row>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="vehicle_info"
          >
            <v-row>
              <v-col
                cols="12"
                class="truck-info-title pb-6"
              >
                <div>
                  {{ $t('vehicle_details.txt.vehicle_information') }}
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="12"
                lg="6"
                class="label"
              >
                <div
                  class="label-title"
                >
                  {{ $t('vehicle_details.txt.vin_number') }}
                </div>
                <div class="label-text">
                  {{ vehicle.vin }} 
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="12"
                lg="6"
                class="label"
              >
                <div
                  class="label-title"
                >
                  {{ $t('vehicle_details.txt.unit_number') }}
                </div>
                <div class="label-text">
                  {{ vehicle.unitNumber }}
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="12"
                lg="6"
                class="label"
              >
                <div
                  class="label-title"
                >
                  {{ $t('vehicle_details.txt.make') }}
                </div>
                <div class="label-text">
                  {{ vehicle.truckMake }} 
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="12"
                lg="6"
                class="label"
              >
                <div
                  class="label-title"
                >
                  {{ $t('vehicle_details.txt.model') }}
                </div>
                <div class="label-text">
                  {{ vehicle.truckModel }} 
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="12"
                lg="6"
                class="label"
              >
                <div
                  class="label-title"
                >
                  {{ $t('vehicle_details.txt.engine') }}
                </div>
                <div class="label-text">
                  {{ vehicle.truckEngineNo }}
                </div>
              </v-col>
              <!-- <img
                v-if="vehiclesServices.length>0"
                :src="require('@/assets/imgs/paccar_trucks.svg')"
                :alt="$t('payment_information.img.truck.alt')"
                class="truck d-none d-sm-block"
              > -->
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="vehicle_services"
          >
            <h1>
              {{ $t('vehicle_details.txt.configure_services') }}
            </h1>
            <span>{{ $t('vehicle_details.txt.add_services') }}</span>
            <v-row
              v-if="vehiclesServices.length>0"
              class="mt-6"
            >
              <v-col
                cols="12"
                class="section-title"
              >
                <div class="horizontal-spacer" />
                <h2>{{ $t('vehicle_details.txt.vehicle_services') }}</h2>
                <div class="horizontal-spacer" />
              </v-col>
            </v-row>

            <v-row 
              v-if="vehiclesServices.length>0 && vehicle"
              class="pt-1 pb-10"
            >
              <v-col
                v-for="(service, index) in vehiclesServices"
                :key="index"
                cols="12"
                sm="6"
                md="12"
                lg="6"
                xl="6"
              >
                <VehicleDetailsCard
                  :key="fieldKey"
                  :service="service"
                  :vehicle="vehicle"
                  :index="index"
                  :billing-period-month="billingPeriodMonth"
                  :billing-period-year="billingPeriodYear"
                  :period="billingPeriod"
                  :selected-items-sended="selectedItems"
                  :product-id="services[index].id"
                  @selected="servicesReceived"
                  @remove="removeService"
                  @period="billingPeriodType"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-footer
        fixed
        color="white"
        class="py-6 elevation-2  hide-footer"
      >
        <v-container class="py-0">
          <v-row class="results_footer hide-footer">
            <v-col
              class="py-2 py-md-0 text-center text-md-left align-self-center"
              cols="12"
              md="6"
              lg="5"
              offset-lg="4"
            >
              <div
                v-if="items && items.length == 0"
                class="no-services-selected mr-md-12 mt-3"
              >
                {{ $t('vehicle_details.txt.no_selected') }}
              </div>
              <div v-else>
                <div class="d-flex justify-center justify-md-start">
                  <span
                    v-for="(item, index) in items"
                    :key="index"
                    class="services-resume-icon"
                  >
                    <v-img
                      v-if="item.iconUrl!==null"
                      :src="item.iconUrl"
                      width="24"
                    />

                  </span>
                  <span
                    v-if="items.length"
                    class="services-resume-selected"
                  >
                    {{ items.length }} {{ $t('vehicle_details.txt.extra_selected') }}
                  </span>
                </div>
                <div class="services-resume-totals">
                  + {{ currency.symbol }} {{ extraMonthlyCost }}
                  <span>{{ $t('vehicle_details.txt.month') }}</span>
                  <span>
                    &
                  </span>
                  {{ currency.symbol }} {{ extraYearlyCost }}
                  <span>{{ $t('vehicle_details.txt.year') }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              class="py-2 py-md-0 text-center text-md-right align-self-center"
              cols="12"
              md="6"
              lg="3"
            >
              <v-btn
                depressed
                large
                :disabled="items && items.length == 0"
                class="btn-proceed"
                :class="{ disabled : items && items.length == 0}"
                @click="submit()"
              >
                {{ $t('vehicle_details.txt.proceed') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
    <v-snackbar
      v-model="notification"
      top
      center
      :color="notificationType"
    >
      {{ notificationText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import VehicleDetailsCard from '@/components/VehicleDetailsCard.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'VehicleDetail',
  components: {
    VehicleDetailsCard
  },
  data() {
    return {
      loading: false,
      activatedReadMoreIndex: null,
      isDialogVisible: false,
      services: [],
      vehicle: {},
      optionsIcon: '',
      renewIcon: '',
      prevRoute: null,
      notification: false,
      notificationText: '',
      notificationType: 'success',
      selectedItems: [],
      fieldKey: 0,
      allServices: [],
      billingPeriodMonth: false,
      billingPeriodYear: false,
      selectedBillingPeriodMonth: true,
      selectedBillingPeriodYear: true,
      billingPeriod: '',
      todayDate: moment().format('YYYY-MM-DD'),
      num: 0
      // productId: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapState('account', ['currency', 'payload']),
    ...mapState('trucks', ['trucks', 'billingPeriodStore', 'selectedTruck']),
    ...mapGetters('catalog', ['getCatalogProducts']),
    ...mapGetters('trucks', ['getSelectedTruck']),
    unsubscribedAndRenewSubscription() {
      return (
        !this.service.subscribed ||
        (this.service.subscribed && this.service.daysToExpire <= 60)
      )
    },
    monthlyCost() {
      return (
        this.vehicle.totalCost &&
        this.vehicle.totalCost.find(cost => cost.servicePeriod === 'Month')
      )
    },
    yearlyCost() {
      return (
        this.vehicle.totalCost &&
        this.vehicle.totalCost.find(cost => cost.servicePeriod === 'Annual')
      )
    },
    extraMonthlyCost() {
      return this.items
        .filter(item => item.monthlyCost)
        .reduce((acc, item) => acc + item.monthlyCost, 0)
    },
    extraYearlyCost() {
      return this.items
        .filter(item => item.yearlyCost)
        .reduce((acc, item) => acc + item.yearlyCost, 0)
    },
    totalMonthlyCost() {
      if (!this.vehicle.totalCost || !this.monthlyCost) {
        return 0
      }
      return this.monthlyCost.servicePrice + this.extraMonthlyCost
    },
    totalYearlyCost() {
      if (!this.vehicle.totalCost || !this.yearlyCost) {
        return 0
      }
      return this.yearlyCost.servicePrice + this.extraYearlyCost
    },

    items() {
      const items = this.selectedItems
      const array = []
      items.forEach(item => {
        for (const [key, value] of Object.entries(item)) {
          if (value === true) {
            this.services.forEach(serv => {
              if (serv.productRatePlanIdMonthly === key) {
                array.push({
                  productId: serv.prodId,
                  iconUrl: serv.iconUrl,
                  productRatePlanId: key,
                  productRatePlanChargesId: serv.productPlanChargesIdMonthly,
                  monthlyCost: serv.monthlyCost
                })
              } else if (serv.productRatePlanIdYearly === key) {
                array.push({
                  productId: serv.prodId,
                  iconUrl: serv.iconUrl,
                  productRatePlanId: key,
                  productRatePlanChargesId: serv.productPlanChargesIdYearly,
                  yearlyCost: serv.yearlyCost
                })
              }
            })
          }
        }
      })
      return array
    },

    vehiclesServices() {
      return this.services
    },
    customerServices() {
      return this.services
    }
  },
  watch: {
    currency(newValue) {
      if (newValue && newValue.code) {
        this.loadPageData()
      }
    }
  },
  async created() {
    this.loading = true
    this.loadPageData()
  },
  methods: {
    ...mapMutations('checkout', ['addTotalCheckout', 'setCheckout']),
    ...mapMutations('trucks', ['setBillingPeriod', 'getBillingPeriod']),
    ...mapMutations('catalog', ['setProducts']),
    ...mapActions('trucks', ['getVehicles']),
    ...mapActions('notification', ['setSnackbar']),
    async loadPageData() {
      await this.getAllServices()
      await this.getSubscriptionDetail()
      await this.getServices()
    },

    billingPeriodType(period) {
      if ((period && this.num == 0) || (!period && this.num == 1))
        this.billingPeriod = period
      if (period) ++this.num
      else --this.num
    },
    servicesReceived(selected) {
      const arrayAux = []
      this.selectedItems.push(selected)
      this.selectedItems.forEach(x => {
        let found = false
        arrayAux.forEach(y => {
          found |= x === y
        })
        if (!found) arrayAux.push(x)
      })
      this.selectedItems = arrayAux
    },
    getSubscriptionDetail() {
      const truckVIN = this.$router.history.current.params.vin
      const selectedTruck = this.getSelectedTruck
      const productIds = selectedTruck
        ? selectedTruck.activeProducts.map(product => product.productId)
        : this.allServices.map(service => service.id)
      //   JSON.parse(
      //   this.$router.history.current.params.productIds
      // )
      return apiCalls
        .post(api.TruckSubscriptionsByVin, '', '', {
          subscriptionVin: truckVIN,
          productIds
        })
        .then(res => {
          this.vehicle = {
            truckType: res.data[0].truckType ?? res.data[1].truckType ?? '',
            truckEngineNo:
              res.data[0].truckEngineNo ?? res.data[1].truckEngineNo ?? '',
            truckMake: res.data[0].truckMake ?? res.data[1].truckMake ?? '',
            truckModel: res.data[0].truckModel ?? res.data[1].truckModel ?? '',
            unitNumber: res.data[0].unitNumber ?? res.data[1].unitNumber ?? '',
            vin: res.data[0].vin ?? res.data[1].vin ?? truckVIN,
            services: res.data
          }
        })
      // const id = this.$router.history.current.params.id
      // this.productId = this.$router.history.current.params.productId
      // return apiCalls
      //   .get(api.TrucksPerServiceDetail, id, `?productId=${this.productId}`)
      //   .then(res => {
      //     this.vehicle = res.data
      //   })
    },
    // getVehicleDetail() {
    //   const id = this.$router.history.current.params.id
    //   const items = []

    //   return apiCalls.get(api.TrucksPerServiceDetail, id, '').then(res => {
    //     this.vehicle = res.data
    //     if (this.vehicle.billingPeriod == 'Monthly')
    //       this.billingPeriodMonth = true
    //     else if (this.vehicle.billingPeriod == 'Yearly')
    //       this.billingPeriodYear = true
    //     else {
    //       this.billingPeriodMonth = true
    //       this.billingPeriodYear = true
    //     }
    //     if (this.vehicle.billingPeriod == '') {
    //       if (
    //         localStorage.getItem('billingPeriod') === null ||
    //         localStorage.getItem('billingPeriod') === 'null'
    //       ) {
    //         this.trucks.forEach(tr => {
    //           if (!tr.billingPeriod) {
    //             items.push({
    //               subscriptionNumber: tr.subscriptionNumber,
    //               billingPeriod: tr.billingPeriod
    //             })
    //           }
    //         })
    //         this.setBillingPeriod(items)
    //       } else {
    //         const x = localStorage.getItem('billingPeriod')
    //         const billingPeriod = JSON.parse(x)
    //         billingPeriod.forEach(bp => {
    //           if (bp.subscriptionNumber == this.vehicle.subscription_number) {
    //             if (bp.billingPeriod == 'month') this.billingPeriodYear = false
    //             else if (bp.billingPeriod == 'year')
    //               this.billingPeriodMonth = false
    //           }
    //         })
    //       }
    //     }
    //     return apiCalls
    //       .getCMSContent('resources', 'catalog')
    //       .then(({ results }) => {
    //         this.vehicle.activeServices.forEach(actserv => {
    //           results[0].data.options.forEach(cms => {
    //             if (cms.externalkey[0].text === actserv.sku)
    //               actserv.serviceName = cms.title[0].text
    //           })
    //         })
    //       })
    //   })
    // },
    getAllServices() {
      const catalogProducts = this.getCatalogProducts
      if (catalogProducts && catalogProducts.length > 0) {
        this.allServices = catalogProducts
        return this.allServices
      } else {
        const newProducts = apiCalls
          .get(api.ProductsGetAll, '', '?checkForActivatedVehicles=false')
          .then(res => {
            this.allServices = res.data
          })
        this.setProducts(newProducts)
        return newProducts
      }
    },
    getServices() {
      this.loading = false
      // const id = this.$router.history.current.params.id

      this.services = this.allServices
        .reduce((serviceList, service) => {
          const sameService = this.vehicle.services.find(
            s => s.productId === service.id
          )
          if (sameService) {
            const aux = { ...sameService, ...service }
            serviceList.push(aux)
          }
          return serviceList
        }, [])
        //Sorting the services cards so that Truck Connectivity comes first and OTA second
        .sort((serviceA, serviceB) => {
          if (serviceA.name.includes('OTA')) return 1
          else if (serviceB.name.includes('OTA')) return -1
          else return 0
        })
      // return this.services
      // return apiCalls
      //   .get(api.TrucksServices, id, '')
      //   .then(res => (this.services = res.data))
      //   .then(() => {
      //     return apiCalls
      //       .getCMSContent('resources', 'catalog')
      //       .then(({ results }) => {
      //         this.services.forEach(serv => {
      //           this.allServices.forEach(serviceItem => {
      //             if (serv.packid == serviceItem.packID__c) {
      //               serv.subscriptionLevel = serviceItem.subscriptionLevel__c
      //               serv.category = serviceItem.category
      //             }
      //             results[0].data.options.filter(cms => {
      //               if (cms.externalkey[0].text === serv.sku) {
      //                 serv.name = cms.title[0].text
      //                 serv.iconUrl = cms.iconactive.url
      //                 serv.descriptionShort = cms.description[0].text.slice(
      //                   0,
      //                   100
      //                 )
      //                 serv.description = cms.description
      //                 serv.rateplans.forEach(product => {
      //                   product.ratePlanCharges.forEach(bp => {
      //                     if (bp.period === 'Annual') {
      //                       serv.productRatePlanIdYearly =
      //                         product.productRateplanID
      //                       bp.price.forEach(pr => {
      //                         serv.productPlanChargesIdYearly = bp.id
      //                         if (pr.currency === this.currency.code)
      //                           serv.yearlyCost = pr.price
      //                       })
      //                     } else if (bp.period === 'Month') {
      //                       serv.productRatePlanIdMonthly =
      //                         product.productRateplanID
      //                       bp.price.forEach(pr => {
      //                         serv.productPlanChargesIdMonthly = bp.id
      //                         if (pr.currency === this.currency.code)
      //                           serv.monthlyCost = pr.price
      //                       })
      //                     }
      //                     if (bp.subscribed === true) {
      //                       serv.ratePlanID = product.ratePlanID
      //                       serv.productRatePlanID = product.productRateplanID
      //                       serv.productRatePlanChargesID = bp.id
      //                       serv.activeUntil = bp.activeUntil
      //                       serv.daysToExpire = bp.daysToExpire
      //                     }
      //                   })
      //                 })
      //               }
      //             })
      //             this.fieldKey = Math.random()
      //             return serv
      //           })
      //         })
      //       })
      //   })
      // .finally(() => {
      //   this.loading = false
      // })
    },
    removeService(id) {
      this.services.forEach(item => {
        if (item.prodId === id) {
          item.rateplans.forEach(rateplan => {
            rateplan.ratePlanCharges.forEach(ratePlanCharge => {
              if (ratePlanCharge.subscribed) {
                apiCalls
                  .put(api.CartAdd, '', '', {
                    subscriptionID: this.vehicle.subscription_number,
                    productRateplanID: rateplan.productRatePlanID,
                    rateplanID: rateplan.ratePlanID,
                    productRateplanChargeID: ratePlanCharge.id,
                    productID: item.prodId,
                    addSubscription: 0,
                    metaData: { '': '' }
                  })
                  .then(res => {
                    if (res.status == 200) {
                      this.setCheckout(res.data.products)
                      this.addTotalCheckout(1)
                    }
                  })
              }
            })
          })
        }
      })
    },

    submit() {
      this.items.forEach(item => {
        apiCalls
          .put(api.CartAdd, '', '', {
            subscriptionID: this.vehicle.subscription_number,
            productRateplanID: item.productRatePlanId,
            rateplanID: '',
            productRateplanChargeID: item.productRatePlanChargesId,
            productID: item.productId,
            addSubscription: 1,
            metaData: { '': '' }
          })
          .then(res => {
            if (res.status == 200) {
              this.setCheckout(res.data.products)
              this.addTotalCheckout(this.items.length)
              this.selectedItems = []
              if (this.vehicle.billingPeriod == '') {
                this.billingPeriodStore.forEach(bp => {
                  if (
                    bp.subscriptionNumber == this.vehicle.subscription_number
                  ) {
                    const item = {
                      subscriptionNumber: bp.subscriptionNumber,
                      billingPeriod: this.billingPeriod
                    }
                    this.getBillingPeriod(item)
                  }
                })
              }
              this.setSnackbar({
                showing: true,
                type: 'success',
                text: this.$t('vehicle_details.txt.notification_addcart')
              })
            } else {
              this.setSnackbar({
                showing: true,
                type: 'error',
                text: this.$t('vehicle_details.txt.notification_error')
              })
            }
          })
      })
    },
    subscribedProduct(productId) {
      const id = this.$router.history.current.params.id
      return id === productId
    }
  }
}
</script>

<style lang="scss">
.loader {
  text-align: center;
  padding-top: 48px;
}
.vehicle {
  margin-bottom: 170px;
  @media screen and (min-width: 960px) {
    margin-bottom: 100px;
  }
  .back-vehicles {
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--v-mid-base);
    text-transform: uppercase;
    text-decoration: none;
    i {
      text-decoration: none;
    }
  }
  &_info {
    background-image: linear-gradient(
      to bottom,
      var(--v-peacock-base) 19%,
      var(--v-windows-base) 88%
    );
    padding: 28px;
    position: relative;
    overflow: initial;
    border-radius: 10px 0 0 10px;

    @media screen and (max-width: 960px) {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
    }

    .truck {
      position: absolute;
      width: 500px;
      top: 200px;
      right: -90px;
      z-index: 2;
    }
    .truck-info-title {
      font-size: 1rem;
      font-weight: bold;
      color: var(--v-light-base);
    }
    .label {
      .label-title {
        font-size: 0.75rem;
        font-weight: bold;
        color: var(--v-light-base);
        text-transform: uppercase;
      }
      .label-text {
        font-size: 1rem;
        color: white !important;
        &-vehiclename {
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 2rem;
          color: white;
          text-decoration: underline;
          &.rtl {
            direction: rtl;
          }
        }
        ul {
          padding: 0;
          li {
            list-style: none;
            padding: 0;
            margin-bottom: 8px;
            .service-value {
              font-size: 0.875rem;
            }
          }
        }
        .total {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }
  &_services {
    border-radius: 0 10px 10px 0;
    background-color: white;
    padding: 40px 28px 28px 100px;

    .section-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 16px 30px;

      h2 {
        margin: 0 10px;
        color: var(--v-bluegrey-base) !important;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: normal;
      }

      .horizontal-spacer {
        border-top: 1px solid var(--v-pale-three-base);
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
    }

    @media screen and (max-width: 960px) {
      border-radius: 0 0 10px 10px;
      padding: 40px 28px 28px 28px;
    }

    h1 {
      font-size: 2rem;
      color: var(--v-dark-base);
      font-weight: normal;
      line-height: normal;
      text-align: left;
    }
    span {
      font-size: 0.875rem;
      color: var(--v-steel-base);
    }
  }
}
.services-resume {
  &-icon {
    float: left;
    margin-right: -10px;
  }
  &-selected {
    margin-left: 20px;
    color: var(--v-dark-base);
    font-size: 0.875rem;
  }
  &-totals {
    font-size: 1.5rem;
    color: var(--v-dark-base);
    font-weight: bold;
    span {
      color: var(--v-dark-base);
      font-size: 1rem;
      font-weight: normal;
    }
  }
}

.v-footer {
  height: 184px;

  @media screen and (min-width: 960px) {
    height: 108px;
  }

  .no-services-selected {
    font-size: 0.875rem;
    color: var(--v-bluegrey-base);
    padding-left: 10px;
  }

  .btn-proceed {
    height: 48px;
    border-radius: 5px;
    background-image: linear-gradient(
      to bottom,
      var(--v-mid-base) 0%,
      var(--v-peacock-base)
    );
    width: 224px;

    &.disabled {
      color: white !important;
      background-image: none;
    }

    span {
      font-size: 0.875rem;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
    }
  }
}

.list-item-stop-service {
  color: var(--v-reddish-base);
}
.more-dialog {
  max-width: 600px;
  .dialog-card {
    padding: 16px;
    .dialog-card-header {
      h2 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--v-slate-base);
      }
      &-icon {
        float: left;
        margin-right: 10px;
      }
      &-close {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
    .dialog-card-content {
      clear: both;
      padding: 20px 4px;
      font-size: 0.875rem;
      color: var(--v-slate-base);
    }
    .dialog-card-footer {
      color: var(--v-slate-base);
      font-size: 0.875rem;
      font-weight: bold;
      &-buttons.v-btn-toggle {
        display: block;
        margin-top: 4px;

        .v-btn {
          border: solid 1px var(--v-cloudy-base) !important;
          background-color: white !important;
          font-size: 0.875rem;
          color: var(--v-steel-base) !important;
          text-transform: none;
          min-width: 110px !important;
          height: 32px;
          border-radius: 4px;
          margin: 4px;
          font-weight: bold;
          opacity: 1;

          &.active {
            background-color: var(--v-peacock-base) !important;
            span {
              color: white !important;
            }
          }

          small {
            font-size: 0.75rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}
.vehiclevin-tooltip {
  min-height: 32px !important;
  border-radius: 16px !important;
  .vehiclevin-span {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
  }
}
.hide-footer {
  display: none !important;
}
.loader-height {
  height: calc(100vh - 100px);
}
</style>
