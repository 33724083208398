import { getInstance } from './authWrapper'
import store from '../src/store'

export const authGuard = (to, from, next, connection) => {
  const authService = getInstance()

  const fn = () => {
    const hasWebShopAccess = store.state.account.roleDetails.hasWebShopAccess
    const hasOEMOrDealerAccess =
      store.state.account.roleDetails.hasOEMOrDealerAccess
    const hasAccountonZuora = store.state.account.roleDetails.hasAccountonZuora
    if (
      authService.isAuthenticated &&
      !hasAccountonZuora &&
      to.name !== 'no-access'
    ) {
      //redirects authenticated users with no account on zuora
      return next({ name: 'no-access', query: { errorType: '102' } })
    }
    if (authService.isAuthenticated && hasWebShopAccess) {
      //ensures a working account is selected
      if (
        hasOEMOrDealerAccess &&
        !localStorage.getItem('uid') &&
        to.name !== 'search'
      ) {
        return next({ name: 'search' })
      }
      //prevent access to serach page to non-dealers and non-oem users
      if (!hasOEMOrDealerAccess && to.name == 'search') {
        return next({ name: 'services' })
      }
      return next()
    } else if (authService.isAuthenticated && !hasWebShopAccess) {
      //redirects authenticated users with invalid role
      return next({ name: 'no-access', query: { errorType: '100' } })
    }

    if (connection == 'default') {
      connection = ''
    }

    authService.loginWithRedirect({
      connection,
      appState: { targetUrl: to.fullPath }
    })
  }
  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading == undefined) {
    }
    if (authService.loading === false) {
      return fn()
    }
  })
}
