import Vue from 'vue'
import Vuex from 'vuex'
// import apiCalls from '@/helpers/apiCall'
// import api from '@/helpers/api'
import basketService from '@/services/basketService'

Vue.use(Vuex)

const state = {
  totalCheckout: 0,
  checkout: []
}
const mutations = {
  increment(state) {
    state.totalCheckout++
  },
  setTotalCheckout(state, n) {
    state.totalCheckout = n
  },
  addTotalCheckout(state, n) {
    state.totalCheckout += n
  },
  removeFromTotalCheckout(state, n) {
    state.totalCheckout -= n
  },
  decrement(state) {
    state.totalCheckout--
  },
  setCheckout(state, checkoutData) {
    // console.log('checkout state set', checkoutData)
    state.checkout = checkoutData
  },
  cleanCart(state) {
    state.checkout = []
  },
  setTotalItemsCheckout() {
    state.totalCheckout = 0
    //#region #Paccar-POC
    if (state.checkout && state.checkout.products != undefined) {
      state.checkout.products.forEach(product => {
        state.totalCheckout += product.rateplans.length
      })
    }
  }
}

const actions = {
  async getCheckout({ commit }) {
    commit('cleanCart')

    // console.log('loading cart')

    //#region #Paccar-POC
    basketService
      .loadCheckout()
      .then(checkout => commit('setCheckout', checkout))

    // return await apiCalls.get(api.CartGetAll, '', '').then(res => {
    //   commit('setCheckout', res.data.products)
    // })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
