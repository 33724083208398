<template>
  <v-snackbar
    v-model="showSnackbar"
    bottom
    center
    vertical
    :timeout="-1"
    color="grey lighten-4"
    class="snackbar"
  >
    <span>
      {{ $t('cookies_alert.txt.description') }}
    </span>
    <div style="padding-top:20px;">
      <v-btn
        color="mid"
        outlined
        class="ms-8"
        @click="showURL($t('cookies_alert.txt.cookies_url'))"
      >
        {{ $t('cookies_alert.txt.cookies_button_label') }}
      </v-btn>
      <v-btn
        color="mid"
        outlined
        class="ms-8"
        @click="showURL($t('cookies_alert.txt.privacy_url'))"
      >
        {{ $t('cookies_alert.txt.privacy_button_label') }}
      </v-btn>
      <v-btn
        color="mid"
        outlined
        class="ms-8"
        @click="acceptCookies"
      >
        {{ $t('cookies_alert.txt.accept_button_label') }}
      </v-btn>
    </div>
  </v-snackbar>
</template>
<script>
export default {
  name: 'CookiesSnackbar',
  data() {
    return {
      showSnackbar: localStorage.getItem('cookiesSnackbar')
        ? JSON.parse(localStorage.getItem('cookiesSnackbar'))
        : true
    }
  },
  created() {
    localStorage.getItem('cookiesSnackbar')
      ? (this.showSnackbar = JSON.parse(
          localStorage.getItem('cookiesSnackbar')
        ))
      : (this.showSnackbar = true)
  },
  methods: {
    acceptCookies(e) {
      e.preventDefault()
      localStorage.setItem('cookiesSnackbar', false)
      this.showSnackbar = false
    },
    showURL(languageURL) {
      window.open(languageURL)
    }
  }
}
</script>
<style lang="scss">
.snackbar {
  span {
    font-size: 0.85rem;
    color: var(--v-slate-base);
    line-height: 1.25;
  }
  button {
    // background-image: -webkit-gradient(
    //   linear,
    //   left top,
    //   left bottom,
    //   from(#266cab),
    //   to(#01529c)
    // );
    // background-image: linear-gradient(to bottom, #266cab 0%, #01529c);
    span {
      color: black;
    }
  }
}
</style>