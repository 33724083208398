<template>
  <div class="resume">
    <v-row class="mx-0 py-2 px-4 resume-service">
      <v-col cols="9">
        <v-row>
          <h2>{{ service.name }}</h2>
        </v-row>
        <v-row>
          <span class="totals">
            <span v-if="serviceMonthPrice">
              <b>{{ currency.symbol }} {{ serviceMonthPrice }}</b> {{ $t('service_details.txt.month') }} &
            </span> 
            <span v-if="serviceAnnualPrice">
              <b>{{ currency.symbol }} {{ serviceAnnualPrice }}</b>
              {{ $t('service_details.txt.per_truck_per_year') }}</span>
          </span>
          
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                :src="require('@/assets/imgs/info.svg')"
                v-bind="attrs"
                max-height="24"
                max-width="24"
                v-on="on"
              />
            </template>
            <div
              class="info-tooltip"
              style="width:300px"
            >
              Subscribing to this service means that when the subscription end date has been reached, the service will renew automatically for another year at $200 per year per truck, which will be charged to your stored payment method. You will receive email notifications on upcoming renewals, invoices, and payment confirmations. All pre-paid service coverage (ie. base warranty, extended warranty) will be honored prior to the first auto-renewal. If the truck is sold during the year, the remaining service coverage will follow the truck to the next owner, per our no cancellation policy.
            </div>
          </v-tooltip> -->
          <v-tooltip
            content-class="info-tooltip"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-img
                :src="require('@/assets/imgs/info.svg')"
                v-bind="attrs"
                max-height="24"
                max-width="24"
                v-on="on"
              />
            </template>
            <v-card
              elevation="0"
              flat
            >
              <!-- <span class="tooltip-title">Info</span> -->
              <div class="tooltip-text">
                {{ $t('service_details.txt.subscription_tooltip') }}
              </div>
            </v-card>
          </v-tooltip>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-img
          v-if="service.iconUrl!==null"
          class="float-right"
          width="38"
          :src="service.iconUrl"
        />
      </v-col>
      <v-col
        cols="12"
        class="description"
      >
        {{ service.description }}
      </v-col>
    </v-row>
    <!--<v-divider />
    <v-row class="mx-0 py-2 px-4 resume-activated">
      <v-col
        cols="8"
        class="align-self-center"
      >
        <span class="d-flex">{{ $t('service_details.txt.activated_vehicles') }}</span>
      </v-col>
      <v-col cols="4">
        <span class="d-flex justify-end">
          <v-icon class="truck_icon">
            mdi-truck
          </v-icon>
          x <label>{{ service.activatedVehicles }}</label>
        </span>
      </v-col>
    </v-row>
    <v-divider class="mx-6" />
    <v-row class="mx-0 py-2 px-4 resume-totals">
      <v-col
        v-if="totalMonthly>0 || totalYearly>0"
        cols="12"
        sm="4"
        lg="12"
        xl="4"
        class="align-self-center"
      >
        {{ $t('service_details.txt.total_cost') }}
      </v-col>
      <v-col
        class="text-sm-right text-lg-left text-xl-right align-self-center pt-0 pt-sm-3 pt-lg-0 pt-xl-3"
        cols="12"
        sm="8"
        lg="12"
        xl="8"
      >
        <span
          v-if="totalMonthly>0"
          class="totals"
        >{{ currency.symbol }} {{ totalMonthly }} <label>{{ $t('service_details.txt.month') }} </label></span>
        <label v-if="totalMonthly>0 && totalYearly>0"> & </label>
        <span
          v-if="totalYearly>0"
          class="totals"
        >{{ currency.symbol }} {{ totalYearly }} <label>{{ $t('service_details.txt.year') }}</label></span>
      </v-col>
    </v-row>-->
  </div>
</template>

<script>
// import apiCalls from '@/helpers/apiCall'
// import api from '@/helpers/api'
import { mapState } from 'vuex'

export default {
  name: 'ServiceDetailCard',
  props: {
    service: {
      type: Object,
      default: () => ({})
    },
    serviceAnnualPrice: {
      type: Number,
      default: 0
    },
    serviceMonthPrice: {
      type: Number,
      default: 0
    },
    month: {
      type: Number,
      default: 0
    },
    year: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newService: {},
      totalAnnual: 0,
      totalMonth: 0,
      vehicles: [
        {
          ratePlanID: '8adcc7af7b8c208b017b9e3cf68a36be',
          subscriptionID: '8adcc7af7b8c208b017b9e3cf67936bb',
          subscriptionNumber: 'S00000924',
          vin: 'XLRTEH4300G1_DF_769_46',
          truckName: '',
          plateNumber: 'XLRTEH4300G1',
          activated: '2018-10-01',
          type: 'Three_Years',
          price: 0.0,
          billingPeriod: 'Yearly',
          totalCost: [
            {
              productRatePlanId: '8adcd9eb778c215b0177ab76712a1b21',
              prodRatePlanChargeId: null,
              servicePeriod: 'OneTime',
              subscriptionLevel: 'Truck',
              servicePrice: null
            },
            {
              productRatePlanId: '8adce421718b46c4017197c31ba8741a',
              prodRatePlanChargeId: '8adce421718b46c4017197c460b6747d',
              servicePeriod: 'Annual',
              subscriptionLevel: 'Truck',
              servicePrice: [
                {
                  currency: 'NOK',
                  price: 0.0
                },
                {
                  currency: 'PLN',
                  price: 0.0
                },
                {
                  currency: 'CHF',
                  price: 0.0
                },
                {
                  currency: 'CZK',
                  price: 0.0
                },
                {
                  currency: 'GBP',
                  price: 216.0
                },
                {
                  currency: 'EUR',
                  price: 240.0
                }
              ]
            },
            {
              productRatePlanId: '8adce421718b46c4017197c0bc7071f6',
              prodRatePlanChargeId: '8adc9dee718b378f017197c1e9413172',
              servicePeriod: 'Monthly',
              subscriptionLevel: 'Truck',
              servicePrice: [
                {
                  currency: 'GBP',
                  price: 18.0
                },
                {
                  currency: 'EUR',
                  price: 20.0
                },
                {
                  currency: 'PLN',
                  price: 0.0
                },
                {
                  currency: 'CHF',
                  price: 0.0
                },
                {
                  currency: 'CZK',
                  price: 0.0
                },
                {
                  currency: 'NOK',
                  price: 0.0
                }
              ]
            },
            {
              productRatePlanId: '8adc9dee718b378f017197b935fd29e8',
              prodRatePlanChargeId: '8adc8f99718b3796017197bae2976b22',
              servicePeriod: 'Three Years',
              subscriptionLevel: 'Truck',
              servicePrice: [
                {
                  currency: 'EUR',
                  price: 0.0
                },
                {
                  currency: 'GBP',
                  price: 0.0
                },
                {
                  currency: 'PLN',
                  price: 0.0
                },
                {
                  currency: 'NOK',
                  price: 0.0
                },
                {
                  currency: 'CHF',
                  price: 0.0
                },
                {
                  currency: 'CZK',
                  price: 0.0
                }
              ]
            }
          ],
          hasBaseProduct: true,
          hasAddOnProduct: false
        },
        {
          ratePlanID: '8adcc7af7b8c208b017b9e3cf68a36be',
          subscriptionID: '8adcc7af7b8c208b017b9e3cf67936bb',
          subscriptionNumber: 'S00000924',
          vin: 'XLRTEH4300G1_DF_769',
          truckName: '',
          plateNumber: 'XLRTEH4300G1',
          activated: '2018-10-01',
          type: 'Three_Years',
          price: 0.0,
          billingPeriod: 'Yearly',
          totalCost: [
            {
              productRatePlanId: '8adcd9eb778c215b0177ab76712a1b21',
              prodRatePlanChargeId: null,
              servicePeriod: 'OneTime',
              subscriptionLevel: 'Truck',
              servicePrice: null
            },
            {
              productRatePlanId: '8adce421718b46c4017197c31ba8741a',
              prodRatePlanChargeId: '8adce421718b46c4017197c460b6747d',
              servicePeriod: 'Annual',
              subscriptionLevel: 'Truck',
              servicePrice: [
                {
                  currency: 'NOK',
                  price: 0.0
                },
                {
                  currency: 'PLN',
                  price: 0.0
                },
                {
                  currency: 'CHF',
                  price: 0.0
                },
                {
                  currency: 'CZK',
                  price: 0.0
                },
                {
                  currency: 'GBP',
                  price: 216.0
                },
                {
                  currency: 'EUR',
                  price: 240.0
                }
              ]
            },
            {
              productRatePlanId: '8adce421718b46c4017197c0bc7071f6',
              prodRatePlanChargeId: '8adc9dee718b378f017197c1e9413172',
              servicePeriod: 'Monthly',
              subscriptionLevel: 'Truck',
              servicePrice: [
                {
                  currency: 'GBP',
                  price: 18.0
                },
                {
                  currency: 'EUR',
                  price: 20.0
                },
                {
                  currency: 'PLN',
                  price: 0.0
                },
                {
                  currency: 'CHF',
                  price: 0.0
                },
                {
                  currency: 'CZK',
                  price: 0.0
                },
                {
                  currency: 'NOK',
                  price: 0.0
                }
              ]
            },
            {
              productRatePlanId: '8adc9dee718b378f017197b935fd29e8',
              prodRatePlanChargeId: '8adc8f99718b3796017197bae2976b22',
              servicePeriod: 'Three Years',
              subscriptionLevel: 'Truck',
              servicePrice: [
                {
                  currency: 'EUR',
                  price: 0.0
                },
                {
                  currency: 'GBP',
                  price: 0.0
                },
                {
                  currency: 'PLN',
                  price: 0.0
                },
                {
                  currency: 'NOK',
                  price: 0.0
                },
                {
                  currency: 'CHF',
                  price: 0.0
                },
                {
                  currency: 'CZK',
                  price: 0.0
                }
              ]
            }
          ],
          hasBaseProduct: true,
          hasAddOnProduct: false
        }
      ]
    }
  },
  computed: {
    ...mapState('account', [
      'currency',
      'accountDetails',
      'accountInformation'
    ]),
    totalYearly() {
      if (!this.year) return this.totalAnnual
      return this.totalAnnual + this.year
    },
    totalMonthly() {
      if (!this.month) return this.totalMonth
      return this.totalMonth + this.month
    }
  },
  async created() {
    await this.getServiceCosts()
  },
  methods: {
    getServiceCosts() {
      // const id = this.$router.history.current.params.id
      // return apiCalls
      //   .get(
      //     api.TrucksPerServiceDetail,
      //     '',
      //     `?pageIndex=1&productId=${id}&accountNumber=`
      //   )
      //   .then(res => {
      //     // #Paccar-POC
      //     // const productService = res.data.find(
      //     //   service => service.productID === id
      //     // )
      //     // if (productService) {
      //     //   this.totalAnnual = productService.totals.Annual || 0
      //     //   this.totalMonth = productService.totals.Month || 0
      //     // }
      //     this.totalAnnual = res.data.reduce(
      //       (total, truck) => total + truck.costForService,
      //       0
      //     )
      //   })
    }
  }
}
</script>

<style lang="scss" scoped>
.resume {
  background-color: var(--v-pale-two-base);

  &-service {
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--v-slate-base);
    }

    .totals {
      font-size: 0.875rem;
      color: var(--v-bluegrey-base);
    }

    .description {
      font-size: 1rem;
      color: var(--v-slate-base);
    }
  }

  &-activated {
    background-color: white;
    color: var(--v-steel-base);
    font-size: 0.875rem;
    span {
      align-items: center;
      .truck_icon {
        color: var(--v-steel-base);
        margin-right: 10px;
      }
      label {
        font-size: 1.125rem;
        font-weight: bold;
        color: var(--v-slate-base);
        margin-left: 10px;
      }
    }
  }

  &-totals {
    background-color: white;
    border-radius: 8px;
    font-size: 0.875rem;
    color: var(--v-steel-base);
    span {
      color: var(--v-slate-base);
      font-size: 1.25rem;
      font-weight: bold;
      label {
        font-size: 0.875rem;
        font-weight: normal;
      }
    }
  }
}
</style>