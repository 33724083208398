<template>
  <v-container class="main-page">
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <HomeMenu />
      </v-col>
      <v-col
        cols="12"
        lg="10"
      >      
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeMenu from '@/components/HomeMenu.vue'

export default {
  name: 'Home',

  components: {
    HomeMenu
  }
}
</script>
