<template>
  <v-container
    fill-height
    fluid
    class="bg"
  >
    <v-row
      align="center"
      justify="center"
      class="d-flex flex-column"
    >
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          color="blue darken-3"
          dark
        >
          <v-card-title class="text-h5 blue">
            Search for a Customer
          </v-card-title>
          <v-card-text>
            Please select the account that you intend to work on.
          </v-card-text>
          <v-card-text>
            <v-autocomplete
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              color="white"
              hide-no-data
              hide-selected
              item-text="customerName"
              item-value="API"
              label="Accounts"
              placeholder="Start typing to Search"
              prepend-icon="mdi-database-search"
              return-object
            />
          </v-card-text>
          <v-card-text>
            <p>
              {{ errorMessage }}
            </p>
          </v-card-text>
          <v-divider />
          <v-expand-transition>
            <v-list
              v-if="detailedModel"
              class="grey darken-3"
            >
              <v-list-item
                v-for="(field, i) in fields"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="field.value" />
                  <v-list-item-subtitle v-text="field.key" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expand-transition>
          <v-card-actions>
            <v-btn
              :disabled="!appContextWorkingAccount.name && !appContextWorkingAccount.number"
              outlined
              @click="backToServiceOverview"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
              Service Overview
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="disableAccountSelection"
              color="green darken-3"
              @click="goToServices()"
            >
              Choose Account
              <v-icon right>
                mdi-close-circle
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="!model"
              color="red darken-3"
              @click="clear()"
            >
              Clear
              <v-icon right>
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          outlined
          class="back-arrow"
          @click="$router.back()"
        >
          <v-icon
            color="black"
            width="15px"
          >
            mdi-arrow-left
          </v-icon>
          <span class="ml-4">{{ $t('Back') }}</span>
        </v-btn>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import apiCalls from '@/helpers/apiCall'
import basketService from '@/services/basketService'
import api from '../helpers/api'
import { getInstance, getUserEmail } from '../../auth/authWrapper'
import store from '/src/store'

export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    detailedModel: null,
    search: null,
    errorMessage: '',
    disableAccountSelection: true,
    details: null,
    selectedCustomerKey: ''
  }),
  computed: {
    ...mapState('account', ['appContextWorkingAccount']),
    fields() {
      if (!this.detailedModel) return []
      return Object.keys(this.detailedModel).map(key => {
        return {
          key,
          value: this.detailedModel[key] || 'n/a'
        }
      })
    },
    items() {
      return this.entries.map(entry => {
        const customerName =
          entry.customerName.length > this.descriptionLimit
            ? entry.customerName.slice(0, this.descriptionLimit) + '...'
            : entry.customerName

        return Object.assign({}, entry, { customerName })
      })
    }
  },

  watch: {
    async search(searchTerm) {
      await this.queryCustomers(searchTerm)
    },
    model(newModel) {
      this.disableAccountSelection = true
      if (!newModel) return
      this.isLoading = true
      this.detailedModel = null
      this.errorMessage = ''

      apiCalls
        .getForUrl(
          `${process.env.VUE_APP_API}/api/account/${newModel.customerKey}/details`
        )
        .then(response => response.data)
        .then(details => {
          this.detailedModel = {
            Customer: details.companyInfo.basicInfo.name,
            Address: details.companyInfo.billToContact.address1,
            City: details.companyInfo.billToContact.city,
            State: details.companyInfo.billToContact.state
          }

          this.details = details
          this.selectedCustomerKey = newModel.customerKey
          this.isLoading = false
          this.disableAccountSelection = false
        })
        .catch(() => {
          this.isLoading = false
          this.detailedModel = null
          this.model = null
          this.errorMessage =
            'Could not validate account: ' + newModel.customerName
          this.disableAccountSelection = true
        })
    }
  },
  methods: {
    ...mapMutations('account', [
      'setAccountInformation',
      'setAccountDetails',
      'setAppContextWorkingAccount',
      'setParentAccount'
    ]),
    ...mapMutations('checkout', ['setTotalCheckout']),
    clear() {
      this.model = null
      this.detailedModel = null
    },
    async goToServices() {
      this.cleanBasket()
      //set working acount before navigating away
      await this.setWorkingAccount()
      this.$router.push({
        name: 'services',
        query: { uid: localStorage.getItem('uid') }
      })
    },
    backToServiceOverview() {
      this.$router.push({ name: 'services' })
    },
    cleanBasket() {
      const totalCheckout = 0
      return basketService.clearBasket().then(response => {
        if (response.status == 200) {
          this.$forceUpdate()
          localStorage.setItem('billingPeriod', null)
          this.setTotalCheckout(totalCheckout)
          this.$emit('close-drawer')
        }
      })
    },
    async queryCustomers(searchTerm) {
      try {
        this.isLoading = true
        this.count = 0
        if (searchTerm && searchTerm.length > 1) {
          this.disableAccountSelection = true
          let email = ''
          const user = getInstance().user
          if (user) {
            email = getUserEmail(user)
          }
          const res = await apiCalls.getCustomersFromTrimble(
            api.GetCustomersFromTrimble,
            { email: email, search: searchTerm }
          )
          if (res.status == 200) {
            this.entries = res.data
            this.isLoading = false
            this.count = res.data.length
          }
        }
      } catch (e) {
        this.isLoading = false
        this.count = 0
        this.disableAccountSelection = true
      }
      this.isLoading = false
    },
    async setWorkingAccount() {
      const res = await apiCalls.get(
        api.AppAccountsContext,
        '',
        '?childtck=' +
          store.state.account.roleDetails.trimbleEntityKey +
          '&workingtck=' +
          this.selectedCustomerKey || ''
      )
      if (res.status == 200) {
        store.commit('account/setAppToken', res.data)
      }
      localStorage.setItem('uid', this.selectedCustomerKey)
      this.setAppContextWorkingAccount({
        name: this.details.companyInfo.basicInfo.name,
        number: this.details.companyInfo.basicInfo.accountNumber,
        id: this.details.companyInfo.basicInfo.id
      })
      this.setAccountInformation(this.details.companyInfo)
    }
  }
}
</script>

<style lang="scss">
.main {
  text-align: center;
  h1 {
    font-size: 3rem;
    font-weight: normal;
    line-height: normal;
    color: var(--v-dark-base);
    text-align: center;
  }
  p {
    font-size: 0.875rem;
    color: var(--v-slate-base);
    text-align: center;
  }
  .btn {
    background-color: #3182ce !important;
    color: white !important;

    &:hover {
      opacity: 0.9;
    }
  }
}
.back-arrow {
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--v-mid-base);
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 0px;
  text-align: start !important;
}
.bg {
  background: url('../assets/imgs/search_account_bg.svg') no-repeat center
    center;
  background-size: cover;
}
</style>
