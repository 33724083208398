<template>
  <v-card
    class="service"
    :class="{
      customerservicesubscribed: isCompanyService && service.customerServiceSubscribed == true,
      remind: companyServiceRemind
    }
    "
  >
    <v-row class="service-header">
      <v-col
        cols="12"
        sm="10"
      >
        <h2>{{ service.name }}</h2>
        <span v-if="service.priceServiceMonth > 0"><b>{{ currency.symbol }} {{ service.priceServiceMonth }}</b> {{ $t('services_overview.txt.month') }}</span>
        <span v-if="service.priceServiceMonth > 0 && service.priceServiceAnnual > 0"> | </span>
        <span v-if="service.priceServiceAnnual > 0"><b>{{ currency.symbol }} {{ service.priceServiceAnnual }}</b> 
          {{ $t('services_overview.txt.per_truck_per_year') }} 
        </span>
      </v-col>
      <v-col
        cols="2"
        class="d-none d-sm-inline"
      >
        <v-img
          v-if="service.iconUrl"
          width="34"
          :src="service.iconUrl"
          class="float-right"
        />
      </v-col>
      <v-col class="description">
        {{ service.description }}
      </v-col>
    </v-row>

    <div
      v-if="!isCompanyService"
      class="service-footer"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          class="py-0 pl-8"
        >
          <div
            v-if="service.totalMonth >0 || service.totalAnnual >0"
            class="label"
          >
            {{ $t('services_overview.txt.total_cost') }}
          </div>
          <div>
            <b v-if="service.totalMonth >0">{{ currency.symbol }} {{ totalMonthPrice }}</b>
            <span v-if="service.totalMonth >0"> {{ $t('services_overview.txt.month') }} </span>
            <span v-if="service.totalMonth >0 && service.totalAnnual >0"> & </span>
            <b v-if="service.totalAnnual >0">{{ currency.symbol }} {{ totalAnnualPrice }}</b>
            <span v-if="service.totalAnnual >0"> {{ $t('services_overview.txt.year') }} </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="py-0 pt-4 pt-sm-0 pl-8 pl-sm-2 pr-8 text-left text-sm-right"
        >
          <div class="label">
            {{ $t('services_overview.txt.vehicles') }}
          </div>
          <div>
            <v-icon color="slate">
              mdi-truck
            </v-icon>
            <span> x </span>
            <b>{{ service.activatedVehicles }}</b>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="pt-5"
        >
          <v-btn
            :to="{ name: 'service-detail', params: { id: service.id }}"
            :disabled="(trucks.length!==0) "
            depressed
            large
            class="add-athlete"
            :class="(trucks && trucks.length>0) || (accountInformation.hasBaseProduct && service.category==='Base Products') ? 'enabled' : 'enabled'"
          >
            {{ $t('services_overview.txt.manage_vehicles') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div
      v-else
      class="service-footer"
      :class="{
        customer: !service.customerServiceSubscribed,
        subscribedfooter: service.customerServiceSubscribed == true
      }
      "
    >
      <v-row>
        <v-col
          v-if="service.customerServiceSubscribed == true"
          cols="12"
          sm="8"
          class="py-0 pl-8"
        >
          <div
            v-if="service.totalMonth >0 || service.totalAnnual >0"
            class="label"
          >
            {{ $t('services_overview.txt.total_cost') }}
          </div>
          <div v-if="service.totalMonth >0">
            <b>{{ currency.symbol }} {{ totalMonthPrice }}</b> <span>{{ $t('services_overview.txt.month') }}</span>
          </div>
          <div v-if="service.totalAnnual >0">
            <b>{{ currency.symbol }} {{ totalAnnualPrice }}</b> <span>{{ $t('services_overview.txt.year') }}</span>
          </div>
        </v-col>
        <v-col
          :class="service.customerServiceSubscribed == true ? 'pt-3': 'pt-12'"
          cols="12"
        >
          <v-divider />
        </v-col>
        <v-row class="px-3">
          <v-col
            cols="12"
            sm="6"
            class="d-flex align-center"
          >
            <p
              v-if="companyServiceInfo && companyServiceInfo.activeUntil && service.customerServiceSubscribed == true"
              class="d-flex flex-column align-start mb-0 text-left"
            >
              <span class="label">{{ $t('vehicle_details.txt.active_until') }}</span>
              <b>
                {{ companyServiceInfo.activeUntil | formatDate }}
                ({{ companyServiceInfo.daysToExpire }} {{ $t('vehicle_details.txt.days') }})
              </b>
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="align-center"
          >
            <v-btn
              v-if="service.customerServiceSubscribed == true"
              :disabled="companyServiceInCart && companyServiceInCart.length > 0 || service.status == 'Remove'"
              depressed
              large
              class="unsubscribe float-right"
              :class="(companyServiceInCart && companyServiceInCart.length > 0) || service.status == 'Remove' ? 'disabled' : 'enabled'"
              @click="unsubscribeService(service.id)"
            >
              {{ $t('services_overview.txt.unsubscribe') }}
            </v-btn>
            <v-btn
              v-else
              :disabled="(trucks && trucks.length==0) || (companyServiceInCart && companyServiceInCart.length > 0)"
              depressed
              large
              class="unsubscribe float-right"
              :class="(trucks && trucks.length==0) || (companyServiceInCart && companyServiceInCart.length > 0)
                ? 'disabled' : 'enabled'"
              @click="dialogSubscribe=true"
            >
              {{ $t('services_overview.txt.subscribe') }}
            </v-btn>

            <v-dialog
              v-model="dialogSubscribe"
              max-width="600"
              content-class="subscribe-dialog"
              @click="dialogSubscribe = !dialogSubscribe"
            >
              <v-card class="dialog-card">
                <div class="dialog-card-header">
                  <v-img
                    v-if="service.iconUrl"
                    :src="service.iconUrl"
                    width="30"
                    class="dialog-card-header-icon"
                  />
                  <h2>{{ service.name }}</h2>
                  <v-btn
                    icon
                    class="dialog-card-header-close"
                    @click="
                      dialogSubscribe = !dialogSubscribe
                    "
                  >
                    <v-icon
                      width="18"
                      color="reddish"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div class="dialog-card-content">
                  {{ service.description }}
                </div>
                <div
                  class="dialog-card-footer"
                >
                  <span class="pl-1">{{ $t('vehicle_details.txt.select_subscription') }}</span>
                  <v-row>
                    <v-col
                      class="align-center justify-sm-end"
                      cols="6"
                    >
                      <v-btn-toggle
                        group
                        dense
                        class="d-flex dialog-card-footer-buttons justify-space-between"
                      >
                        <v-btn
                          v-if="service.priceServiceMonth >0"
                          v-model="activeBtn['month']"
                          active-class="active"
                          :disabled="(!accountInformation.hasBaseProduct && service.category!='Base Products')"
                        >
                          {{ currency.symbol }} {{ service.priceServiceMonth }} <small>{{ $t('vehicle_details.txt.month') }}</small>
                        </v-btn>
                        <v-btn
                          v-if="service.priceServiceAnnual >0"
                          v-model="activeBtn['annual']"
                          active-class="active"
                          :disabled="(!accountInformation.hasBaseProduct && service.category!='Base Products')"
                        >
                          {{ currency.symbol }} {{ service.priceServiceAnnual }} <small>{{ $t('vehicle_details.txt.year') }}</small>
                        </v-btn>
                        <v-icon class="d-none d-md-block align-self-center float-right">
                          mdi-chevron-right
                        </v-icon>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        :disabled="!enableButtonsDialog"
                        depressed
                        large
                        class="subscribe-btn float-right"
                        :class="enableButtonsDialog ? 'proceed': 'disabled'"
                        @click="subscribeService(service.id)"
                      >
                        {{ $t('services_overview.txt.subscribe') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import apiCalls from '@/helpers/apiCall'
import api from '@/helpers/api'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'ServicesCard',
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    }
  },
  props: {
    service: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      notification: false,
      notificationText: '',
      notificationType: 'success',
      dialogSubscribe: false,
      activeBtn: {},
      num: 0,
      companyServiceInfo: null,
      totalMonthPrice: 0,
      totalAnnualPrice: 0,
      trucks: [],
      accountInformation: { hasBaseProduct: true }
    }
  },
  computed: {
    ...mapState('account', ['currency']),
    // ...mapState('trucks', ['trucks']),
    ...mapState('checkout', ['checkout']),
    companyServiceInCart() {
      return this.checkout
        ? this.checkout.filter(x => x.productID == this.service.id)
        : false
    },
    enableButtonsDialog() {
      return (
        Object.keys(this.activeBtn).length > 0 &&
        (this.activeBtn.annual === true || this.activeBtn.month === true)
      )
    },
    isCompanyService() {
      return this.service.subscriptionLevel__c === 'Company'
    },

    companyServiceRemind() {
      return (
        this.companyServiceInfo &&
        this.companyServiceInfo.daysToExpire &&
        this.companyServiceInfo.daysToExpire <= 60
      )
    }
  },
  watch: {
    service(newValue) {
      this.setTotalMonthPrice(newValue)
      this.setTotalAnnualPrice(newValue)
    }
  },
  created() {
    this.setTotalMonthPrice(this.service)
    this.setTotalAnnualPrice(this.service)
    // this.getVehicles()
    if (this.isCompanyService) {
      apiCalls.get(api.CompanyService, '', '').then(({ data: [data] }) => {
        if (data) {
          this.companyServiceInfo = {
            subscriptionNumber: data.subscriptionNumber,
            startDate: data.contractEffectiveDate,
            activeUntil:
              (data.rateplan[0] && data.rateplan[0].activeUntil) || null,
            daysToExpire:
              (data.rateplan[0] && data.rateplan[0].daysToExpire) || null
          }
        }
      })
    }
  },
  methods: {
    ...mapMutations('checkout', ['addTotalCheckout', 'setCheckout']),
    ...mapActions('trucks', ['getVehicles']),
    ...mapActions('notification', ['setSnackbar']),
    setTotalMonthPrice(service) {
      if (service.totalMonth && service.totalMonth >= 0)
        this.totalMonthPrice = new Intl.NumberFormat(this.$i18n.locale).format(
          service.totalMonth
        )
    },
    setTotalAnnualPrice(service) {
      if (service.totalAnnual && service.totalAnnual >= 0)
        this.totalAnnualPrice = new Intl.NumberFormat(this.$i18n.locale).format(
          service.totalAnnual
        )
    },
    subscribeService(id) {
      if (this.activeBtn.annual && this.activeBtn.annual === true) {
        this.service.productRatePlanChargesId = this.service.productPlanChargesIdYearly
        this.service.productRatePlanId = this.service.productRatePlanIdYearly
      } else if (this.activeBtn.month && this.activeBtn.month === true) {
        this.service.productRatePlanChargesId = this.service.productPlanChargesIdMonthly
        this.service.productRatePlanId = this.service.productRatePlanIdMonthly
      }

      apiCalls
        .put(api.CartAdd, '', '', {
          subscriptionID: '',
          productRateplanID: this.service.productRatePlanId,
          rateplanID: '',
          productRateplanChargeID: this.service.productRatePlanChargesId,
          productID: id,
          addSubscription: 1,
          metaData: { '': '' }
        })
        .then(res => {
          if (res.status == 200) {
            this.dialogSubscribe = false
            const number = 1
            this.addTotalCheckout(number)
            this.setCheckout(res.data.products)
            this.setSnackbar({
              showing: true,
              type: 'success',
              text: this.$t('service_details.txt.notification_addcart')
            })
          } else {
            this.setSnackbar({
              showing: true,
              type: 'error',
              text: this.$t('service_details.txt.notification_error')
            })
          }
        })
    },
    async unsubscribeService(id) {
      await apiCalls
        .get(api.CompanyService, '', '')
        .then(({ data: [data] }) => {
          const { productRatePlanId } = data.rateplan[0]
          this.service.productRatePlans.forEach(product => {
            if (product.id === productRatePlanId) {
              this.service.productRatePlanId = productRatePlanId
              this.service.productRatePlanChargesId =
                product.productRatePlanCharges[0].id
            }
          })
        })
      await apiCalls
        .put(api.CartAdd, '', '', {
          subscriptionID: '',
          productRateplanID: this.service.productRatePlanId,
          rateplanID: '',
          productRateplanChargeID: this.service.productRatePlanChargesId,
          productID: id,
          addSubscription: 0,
          metaData: { '': '' }
        })
        .then(res => {
          if (res.status == 200) {
            const number = 1
            this.addTotalCheckout(number)
            this.setCheckout(res.data.products)
            this.setSnackbar({
              showing: true,
              type: 'success',
              text: this.$t('service_details.txt.notification_addcart')
            })
          } else {
            this.setSnackbar({
              showing: true,
              type: 'error',
              text: this.$t('service_details.txt.notification_error')
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.service {
  padding: 16px 32px 176px;
  height: 100%;
  vertical-align: bottom;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px 0 #00000033;

  @media screen and (max-width: 600px) {
    padding-bottom: 216px;
  }

  &-header {
    h2 {
      font-weight: bold;
      font-size: 1.25rem;
      color: var(--v-slate-base);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span {
      font-size: 0.875rem;
      color: var(--v-bluegrey-base);
    }
    .description {
      font-size: 1rem;
      color: var(--v-slate-base);
      line-height: 1.25;
    }
  }
  &-footer {
    height: 160px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 12px;
    background-color: var(--v-pale-two-base);
    font-size: 1rem;
    color: var(--v-slate-base);

    @media screen and (max-width: 600px) {
      padding-bottom: 200px;
    }

    span {
      font-size: 0.75rem;
    }

    .label {
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--v-bluegrey-base);
    }

    .add-athlete {
      height: 64px !important;
      width: 100%;
      letter-spacing: 0.25em;

      span {
        font-size: 1rem;
        font-weight: bold;
        color: white;
      }
      &.enabled {
        background-image: linear-gradient(to bottom, #266cab 0%, #01529c);
      }
      &.disabled {
        background-color: var(--v-cloudy-base) !important;
      }
    }

    .status-label {
      font-size: 1rem;
      font-weight: bold;
    }

    .unsubscribe {
      height: 48px !important;
      width: 179px;

      span {
        font-size: 1rem;
        font-weight: bold;
        color: white;
      }
      &.enabled {
        background-image: linear-gradient(to bottom, #266cab 0%, #01529c);
      }
      &.disabled {
        background-color: var(--v-cloudy-base) !important;
      }
    }
    &.customer {
      background-color: white;
    }
    &.subscribedfooter {
      background-color: var(--v-duck-egg-base) !important;
    }
  }
  &.customerservicesubscribed {
    background-color: var(--v-duck-egg-base) !important;
  }

  &.remind {
    background-color: var(--v-oasis-base) !important;

    .service-footer {
      background-color: var(--v-oasis-base) !important;
    }
  }
}
.subscribe-dialog {
  max-width: 600px;
  .dialog-card {
    padding: 16px;
    .dialog-card-header {
      h2 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--v-slate-base);
      }
      &-icon {
        float: left;
        margin-right: 10px;
      }
      &-close {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
    .dialog-card-content {
      clear: both;
      padding: 20px 4px;
      font-size: 0.875rem;
      color: var(--v-slate-base);
    }
    .dialog-card-footer {
      color: var(--v-slate-base);
      font-size: 0.875rem;
      font-weight: bold;

      &-buttons.v-btn-toggle {
        display: block;
        margin-top: 4px;

        .v-btn {
          border: solid 1px var(--v-cloudy-base) !important;
          background-color: white !important;
          font-size: 0.875rem;
          color: var(--v-steel-base) !important;
          text-transform: none;
          min-width: 110px !important;
          height: 32px;
          border-radius: 4px;
          margin: 4px;
          font-weight: bold;
          opacity: 1;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;

          &.active {
            background-color: var(--v-peacock-base) !important;
            span {
              color: white !important;
            }
          }

          small {
            font-size: 0.75rem;
            font-weight: normal;
          }
        }
      }

      .subscribe-btn {
        width: 100%;
        height: 48px !important;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        &.proceed {
          background-image: linear-gradient(to bottom, #266cab 0%, #01529c);
          color: white;
        }
        &.disabled {
          background-color: var(--v-cloudy-base) !important;
          color: white !important;
        }
      }
    }
  }
}
</style>