<template>
  <div>
    <v-tooltip
      :disabled="serviceIsOwned || isEmuxTCOwned"
      top
      color="#005ca9"
      class="card-tooltip"
    >
      <template v-slot:activator="{ on }">
        <v-card
          elevation="0"
          class="service-card"
          :class="{
            selected: serviceSelected,
            subscribed: serviceSubscribed && serviceActivated,
            renew: serviceNeedsRenew
          }
          "
          :disabled="serviceInCart && serviceInCart.length > 0"
          v-on="on"
        >
          <div class="service-card-header">
            <v-img
              v-if="service.iconUrl"
              :src="service.iconUrl"
              width="30"
              class="service-card-icon"
            />
            <div class="service-card-titles">
              <h2>{{ service.name }}</h2>  
              <h5 v-if="service.invoiceOwner && service.invoiceOwner.length > 0">
                Managed by: <strong>{{ service.invoiceOwner }}</strong>
              </h5>  
            </div>
            <v-menu
              v-if="serviceIsOwned || (!serviceIsOwned && isEmuxTCOwned)"
              offset-x
              right
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                > 
                  <v-icon
                    color="mid"
                    width="15px"
                  >
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>   

              <v-list>
                <v-list-item
                  v-if=" service.activated && service.invoiceOwnerAccountId === appContext.childId"
                  class="list-item"
                  :disabled="(itemsToAdd.length > 0) || serviceIsUnsubscribing(vehicle.vin, service.name)"
                  @click="bulkStopService()"
                >
                  <v-list-item-title> {{ $t('vehicle_details.txt.unsubscribe') }} </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-else-if="! service.activated || ( service.activated && service.invoiceOwnerAccountId === appContext.childId)"
                  class="list-item"
                  :disabled="(itemsToAdd.length > 0) || serviceIsSubscribing(vehicle.vin, service.name)"
                  @click="bulkActiveService()"
                >
                  <v-list-item-title> {{ $t('vehicle_details.txt.subscribe') }} </v-list-item-title>
                </v-list-item>
                <!--v-list-item
              v-if="  service.activated &&
                  service.invoiceOwnerAccountId !== '' &&
                  service.invoiceOwnerAccountId !== appContext.childId"
              class="list-item"
              :disabled="itemsToAdd.length > 0"
            >
              <v-list-item-title>Take Invoice Ownership</v-list-item-title>
            </v-list-item-->
              </v-list>
            </v-menu>





            <v-menu
              v-if="service.subscribed && service.subscriptionLevel=='Truck'"
              offset-x
              right
              bottom
              class="service-card-options"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  dark
                  icon
                  class="service-card-options-btn"
                  v-on="on"
                >
                  <v-img
                    v-if="servicesDaysToExpire"
                    :src="$t('vehicle_details.img.options_subscribed.img')"
                    width="16"
                  />
                  <v-img
                    v-else
                    :src="$t('vehicle_details.img.options_renew.img')"
                    width="16"
                  />
                </v-btn>
              </template>

              <v-list
                dense
                style="padding:0"
                class="elevation-2"
              >
                <v-list-item-group color="primary">
                  <v-list-item
                    :disabled="serviceStatus === 'Remove'"
                  >
                    <v-list-item-title
                      class="list-item-stop-service"
                      @click="handleStopService(service, vehicle)"
                    >
                      {{ $t('vehicle_details.txt.stop_service') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <div class="service-card-description">
            <div v-if="service.subscribed">
              <span v-if="serviceStatus === 'Remove'">{{ $t('vehicle_details.txt.active_until') }}</span>
              <span v-else-if=" autoRenew">{{ $t('vehicle_details.txt.auto_renews') }}</span>
              <span v-else>{{ $t('vehicle_details.txt.expires') }}</span>
              <p class="text-left">
                <b>{{ service.activeUntil | formatDate }} ({{ service.daysToExpire }}
                  {{ $t('vehicle_details.txt.days') }})</b>
              </p>
            </div>
            <div>
              <div
                v-if="service.description"
                class="card-description"
              >
                {{ service.description.slice(0, descriptionLimit) }}...
                <a
                  class="card-description-more"
                  @click="activateReadMore()"
                >
                  {{ $t('vehicle_details.txt.more') }}
                </a>
                <v-dialog
                  v-model="isDialogVisible"
                  max-width="600"
                  content-class="more-dialog"
                  @click="isDialogVisible = !isDialogVisible"
                >
                  <v-card class="dialog-card">
                    <div class="dialog-card-header">
                      <v-img
                        v-if="service.iconUrl"
                        :src="service.iconUrl"
                        width="30"
                        class="dialog-card-header-icon"
                      />
                      <h2>{{ service.name }}</h2>
                      <v-btn
                        icon
                        class="dialog-card-header-close"
                        @click="
                          isDialogVisible = !isDialogVisible
                        "
                      >
                        <v-icon
                          width="18"
                          color="reddish"
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                    <div class="dialog-card-content">
                      <span>{{ service.description }}</span>
                    </div>
                    <div
                      v-if="service.subscriptionLevel==='Truck' && canSelectPeriod && serviceRatePlan"
                      class="dialog-card-footer"
                    >
                      <span
                        v-if="!service.subscribed"
                        class="pl-1"
                      >{{ $t('vehicle_details.txt.select_subscription') }}</span>
                      <span
                        v-else-if="vehicle.autoRenew && serviceStatus !== 'Remove'"
                        class="pl-1"
                      >{{ $t('vehicle_details.txt.prices') }}</span>
                      <span
                        v-else
                        class="pl-1"
                      >{{ $t('vehicle_details.txt.renew_subscription') }}</span>
                      <v-btn-toggle
                        group
                        dense
                        class="dialog-card-footer-buttons"
                        @change="setPeriod"
                      >
                        <v-btn
                          v-if="service.monthlyCost"
                          v-model="selectedItems[service.productRatePlanIdMonthly]"
                          active-class="active"
                          :disabled="(vehicle.autoRenew && service.daysToExpire <= 60 && serviceStatus !== 'Remove') || (!vehicle.hasBaseProduct && service.category!='Base Products') || !billingPeriodMonth || period =='year'"
                          value="month"
                          @click="sendItems()"
                        >
                          {{ currency.symbol }} {{ service.monthlyCost }} <small>{{ $t('vehicle_details.txt.month') }}</small>
                        </v-btn>
                        <v-btn
                          v-if="service.yearlyCost"
                          v-model="selectedItems[service.productRatePlanIdYearly]"
                          active-class="active"
                          :disabled="(vehicle.autoRenew && service.daysToExpire <= 60 && serviceStatus !== 'Remove')|| (!vehicle.hasBaseProduct && service.category!='Base Products') || !billingPeriodYear || period =='month'"
                          value="year"
                          @click="sendItems()"
                        >
                          {{ currency.symbol }} {{ service.yearlyCost }} <small>{{ $t('vehicle_details.txt.year') }}</small>
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
          <!-- <div
            v-if="service.monthlyCost || service.yearlyCost"
            class="service-card-subscription"
          >
            <div v-if="service.subscriptionLevel==='Truck'">
              <div v-if="canSelectPeriod && serviceRatePlan">
                <span
                  v-if="!service.subscribed"
                  class="pl-1"
                >{{ $t('vehicle_details.txt.select_subscription') }}</span>
                <span
                  v-else-if="vehicle.autoRenew && serviceStatus !== 'Remove'"
                  class="pl-1"
                >{{ $t('vehicle_details.txt.prices') }}</span>
                <span
                  v-else
                  class="pl-1"
                >{{ $t('vehicle_details.txt.renew_subscription') }}</span>
                <v-btn-toggle
                  group
                  dense
                  class="service-card-subscription-buttons"
                  @change="setPeriod"
                >
                  <v-btn
                    v-if="service.monthlyCost"
                    v-model="selectedItems[service.productRatePlanIdMonthly]"
                    active-class="active"
                    :disabled="(vehicle.autoRenew && service.daysToExpire <= 60 && serviceStatus !== 'Remove') || (!vehicle.hasBaseProduct && service.category!='Base Products') || !billingPeriodMonth || period =='year'"
                    value="month"
                    @click="sendItems()"
                  >
                    {{ currency.symbol }} {{ service.monthlyCost }} <small>{{ $t('vehicle_details.txt.month') }}</small>
                  </v-btn>

                  <v-btn
                    v-if="service.yearlyCost"
                    v-model="selectedItems[service.productRatePlanIdYearly]"
                    active-class="active"
                    :disabled="(vehicle.autoRenew && service.daysToExpire <= 60 && serviceStatus !== 'Remove') || (!vehicle.hasBaseProduct && service.category!='Base Products') || !billingPeriodYear || period =='month'"
                    value="year"
                    @click="sendItems()"
                  >
                    {{ currency.symbol }} {{ service.yearlyCost }} <small>{{ $t('vehicle_details.txt.year') }}</small>
                  </v-btn>
                </v-btn-toggle>
              </div>
              <div
                v-else
                class="service-subscribed"
              >
                <v-icon
                  width="16"
                  color="peacock"
                >
                  mdi-check
                </v-icon>
                <span>
                  {{ $t('vehicle_details.txt.subscribed') }}
                </span>
              </div>
            </div>
            <div v-else>
              <div
                v-if="service.subscribed && service.daysToExpire > 60"
                class="service-subscribed"
              >
                <v-icon
                  width="16"
                  color="peacock"
                >
                  mdi-check
                </v-icon>
                <span>
                  {{ $t('vehicle_details.txt.subscribed') }}
                </span>
              </div>
              <div
                v-else
                class="service-to-subscribe pl-0 ml-0"
              >
                <router-link
                  v-if="!vehicle.autoRenew && (vehicle.hasBaseProduct || service.category==='Base Products')"
                  class="enabled"
                  :to="{ name: 'services'}"
                >
                  {{ $t('vehicle_details.txt.subscribe') }}
                </router-link>
                <span
                  v-else
                  class="disabled"
                >
                  {{ $t('vehicle_details.txt.subscribe') }}
                </span>
              </div>
            </div>
          </div> -->
        </v-card>
      </template>
      
      <span class="tooltip-span"> {{ `${vehicle.truckType !== 'EMUX' && service.name && service.name.includes('OTA') ? 'OTA only available for EMUX Trucks' : `Only the manager can modify this service${service.invoiceOwner ? `, this service is managed by: ${service.invoiceOwner }` : '.'}` }` }}</span>
    </v-tooltip>
    
    <v-snackbar
      v-model="notification"
      top
      center
      :color="notificationType"
    >
      {{ notificationText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import basketService from '@/services/basketService'
import { roleRatePlanService } from '@/services/roleRatePlanService'

export default {
  name: 'VehicleDetailsCard',
  props: {
    service: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    vehicle: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    },
    billingPeriodMonth: {
      type: Boolean,
      default: false
    },
    billingPeriodYear: {
      type: Boolean,
      default: false
    },
    period: {
      type: String,
      default: ''
    },
    selectedItemsSended: {
      type: Array,
      default: () => []
    },
    productId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      disabledButton: false,
      activatedReadMoreIndex: null,
      isDialogVisible: false,
      services: [],
      optionsIcon: '',
      renewIcon: '',
      selectedItems: {},
      notification: false,
      notificationText: '',
      notificationType: 'warning',
      addToCartLimit: 60,
      itemsToAdd: [],
      descriptionLimit: 300
    }
  },
  computed: {
    ...mapState('account', [
      'currency',
      'accountInformation',
      'appContext',
      'appContextWorkingAccount'
    ]),
    ...mapState('checkout', ['checkout']),
    isEmuxTCOwned() {
      const TCService = this.vehicle.services.find(
        service => service.title && service.title.includes('Truck Connectivity')
      )
      return (
        this.service?.name?.includes('OTA') &&
        (!this.service.invoiceOwner || !this.service.invoiceOwnerAccountId) &&
        this.vehicle.truckType === 'EMUX' &&
        TCService &&
        (TCService.invoiceOwner.length > 0 ||
          TCService.invoiceOwnerAccountId.length > 0)
      )
    },
    serviceIsOwned() {
      return (
        this.service.invoiceOwnerAccountId === this.appContext.childId ||
        this.service.invoiceOwnerAccountId ===
          this.appContext.parentAccountId ||
        this.service.invoiceOwnerAccountId === this.appContext.workingAccountId
      )
    },
    serviceActivated() {
      return this.service.activated
    },
    serviceSelected() {
      return (
        this.selectedItems[this.service.productRatePlanIdMonthly] ||
        this.selectedItems[this.service.productRatePlanIdYearly]
      )
    },
    serviceSubscribed() {
      return this.productId == this.service.id
    },
    serviceNeedsRenew() {
      return this.service.daysToExpire <= 60
    },
    servicesDaysToExpire() {
      return this.service.daysToExpire > 60
    },
    canSelectPeriod() {
      return (
        !this.service.subscribed ||
        this.service.daysToExpire <= 60 ||
        this.serviceStatus === 'Remove'
      )
    },
    serviceRatePlan() {
      return this.service.rateplans && this.service.rateplans.length > 0
    },
    serviceInCart() {
      const array = []
      const x =
        this.checkout && this.checkout.products
          ? this.checkout.products.filter(
              x => x.productID == this.service.prodId
            )
          : false
      if (x && x.length > 0) {
        x.forEach(product => {
          product.rateplans.forEach(rp => {
            rp.subscriptions.forEach(sub => {
              if (sub.subscriptionID == this.vehicle.subscription_number)
                array.push(rp)
            })
          })
        })
      }
      return array
    },
    vehicleInCart() {
      const subscriptionArray = []
      const x =
        this.checkout && this.checkout.products ? this.checkout.products : false
      if (x && x.length > 0) {
        x.forEach(product => {
          product.rateplans.forEach(rp => {
            subscriptionArray.push(...rp.subscriptions)
          })
        })
      }
      return subscriptionArray
    },
    /**
     * Get flag canStop (true/false) from vehicle active services
     * Used to enable/disable "Stop service" options button
     */
    serviceCanStop() {
      const serv = this.vehicle.activeServices.filter(x => {
        return (
          x.sku === this.service.sku &&
          this.service.subscribed === true &&
          x.canStop
        )
      })
      if (serv.length > 0) return true
      else return false
    },
    serviceStatus() {
      const activeService = this.vehicle.activeServices.find(service => {
        return service.sku === this.service.sku
      })
      return activeService?.status
    }
  },
  methods: {
    ...mapActions('notification', ['setSnackbar']),
    setPeriod(period) {
      this.$emit('period', period)
    },
    sendItems() {
      this.$emit('selected', this.selectedItems)
    },
    activateReadMore() {
      this.isDialogVisible = true
    },
    sendRemoveID(id) {
      this.$emit('remove', id)
    },
    handleStopService(service, vehicle) {
      // * Checks if the selected service has 'Three_Years' as period
      const threeYears = service.rateplans.find(rateplan =>
        rateplan.ratePlanCharges.find(
          ratePlanCharge =>
            ratePlanCharge.period == 'Three_Years' && ratePlanCharge.subscribed
        )
      )
      // * If there's a 'Three_Years' period on this service, show the warning message for three years
      if (threeYears && Object.keys(threeYears).length > 0) {
        this.setSnackbar({
          showing: true,
          type: 'warning',
          text: this.$t(
            'vehicle_details.txt.notification_warning_stop_three_years'
          )
        })
      } else if (
        service.category === 'Base Products' &&
        vehicle.hasAddOnProduct
      ) {
        // * If the service is a Base Product and there are add on products subscribed, show the warning message for base product
        this.setSnackbar({
          showing: true,
          type: 'warning',
          text: this.$t('vehicle_details.txt.notification_warning_stop_base')
        })
      } else if (this.serviceCanStop) {
        this.sendRemoveID(service.prodId)
      } else {
        this.setSnackbar({
          showing: true,
          type: 'warning',
          text: this.$t('vehicle_details.txt.notification_warning_stop')
        })
      }
    },
    bulkActiveService() {
      if (this.$store.state.checkout.totalCheckout + 1 > this.addToCartLimit) {
        this.setSnackbar({
          showing: true,
          text: `Sorry! You can add up to ${this.addToCartLimit} subscription actions to your Cart at this time.`,
          icon: 'mdi-alert',
          position: 'top',
          right: 'right',
          timeout: 7500,
          type: 'warning'
        })
      } else {
        if (this.itemsToAdd.length == 0) {
          const ratePlanType = this.service.productRatePlans.filter(
            w =>
              w.ratePlanType__c ==
              roleRatePlanService.userRatePlan(localStorage.getItem('role'))
          )
          const serviceWithSubscriptionNumber = this.vehicle.services.find(
            service =>
              (service.number && service.title?.includes(this.service.name)) ||
              (!this.service.number && service.number)
          )

          this.itemsToAdd.push({
            subscriptionID: serviceWithSubscriptionNumber.number,
            productRateplanID: ratePlanType[0].id,
            rateplanID: '',
            productRateplanChargeID: this.service.productRatePlans[0]
              .productRatePlanCharges[0].id,
            productID: this.productId,
            productName: this.service.name,
            addSubscription: 1,
            metaData: {
              vin: this.vehicle.vin,
              service: this.service.name,
              type: 'SUBSCRIBE'
            }
          })
          basketService.sendToCheckout(this.itemsToAdd).then(result => {
            if (result.success) {
              this.setSnackbar({
                showing: true,
                type: 'success',
                timeout: 2000,
                position: 'top',
                right: 'right',
                title: 'Success',
                text: this.$t('service_details.txt.notification_addcart')
              })
              this.selected = []
              //this.activeBtn?.annual = false
            } else {
              this.setSnackbar({
                showing: true,
                type: 'error',
                text: this.$t('service_details.txt.notification_error')
              })
            }
          })
        }
      }
    },
    bulkStopService() {
      if (this.$store.state.checkout.totalCheckout + 1 > this.addToCartLimit) {
        this.setSnackbar({
          showing: true,
          type: 'error',
          text: `Sorry! You can add up to ${this.addToCartLimit} subscription actions to your Cart at this time.`
        })
      } else {
        if (this.itemsToAdd.length == 0) {
          const ratePlanType = this.service.productRatePlans.filter(
            w =>
              w.ratePlanType__c ==
              roleRatePlanService.userRatePlan(localStorage.getItem('role'))
          )
          const currentRatePlan = this.service.ratePlans.find(
            x => x.productRatePlanId == ratePlanType[0].id
          )
          this.itemsToAdd.push({
            subscriptionID: this.service.number,
            productRateplanID: ratePlanType[0].id,
            rateplanID: currentRatePlan ? currentRatePlan.id : '',
            productRateplanChargeID: this.service.productRatePlans[0]
              .productRatePlanCharges[0].id,
            productID: this.productId,
            productName: this.service.name,
            addSubscription: 0,
            metaData: {
              vin: this.vehicle.vin,
              service: this.service.name,
              type: 'UNSUBSCRIBE'
            }
          })
          basketService.sendToCheckout(this.itemsToAdd).then(result => {
            if (result.success) {
              this.setSnackbar({
                showing: true,
                type: 'success',
                text: this.$t('service_details.txt.notification_addcart')
              })
            } else {
              this.setSnackbar({
                showing: true,
                type: 'error',
                text: this.$t('service_details.txt.notification_error')
              })
            }
          })
        }
      }
    },
    isVehicleInCart(vin) {
      return this.vehicleInCart.some(x => x.metaData.vin == vin)
    },
    serviceIsUnsubscribing(vin, service) {
      return this.vehicleInCart.some(x => {
        return (
          x.metaData.vin == vin &&
          x.metaData.service == service &&
          x.metaData.type == 'UNSUBSCRIBE'
        )
      })
    },
    serviceIsSubscribing(vin, service) {
      return this.vehicleInCart.some(
        x =>
          x.metaData.vin == vin &&
          x.metaData.service == service &&
          x.metaData.type == 'SUBSCRIBE'
      )
    }
  }
}
</script>

<style lang="scss">
.v-list-item--disabled {
  :first-child {
    color: var(--v-secondary-darken2);
  }
}
// .v-tooltip__content {
//   max-width: 250px;
// }
.v-tooltip__content::before {
  border-top: solid 8px #005ca9;
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}
.service-card {
  padding: 16px 16px 120px;
  vertical-align: bottom;
  border-radius: 6px !important;
  background-color: var(--v-pale-two-base) !important;
  height: 300px;
  vertical-align: bottom;
  .service-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 216px;
  }

  &.selected {
    background-color: var(--v-pale-four-base) !important;
  }
  &.subscribed {
    background-color: var(--v-light-base) !important;
  }
  &.disabled {
    opacity: 0.3;
  }
  &.renew {
    background-color: var(--v-oasis-base) !important;
  }
  &-titles {
    h2 {
      width: 100%;
    }
    h5 {
      width: 100%;
      font-weight: 300;
      color: var(--v-slate-base);
      strong {
        font-weight: 700;
        color: var(--v-slate-base);
      }
    }
  }
  &-header {
    h2 {
      font-size: 1rem;
      font-weight: bold;
      color: var(--v-slate-base);
    }
    .service-card-icon {
      float: left;
      margin-right: 10px;
    }
    .service-card-options-btn {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 16px;
      height: 16px;
    }
    .service-card-options-menu {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-bottom: 12px solid transparent;
      border-left: 12px solid var(--v-darkgrey-blue-two-base);
      -webkit-transform: rotate(225deg);
      position: absolute;
      top: 28px;
      left: 45%;
    }
  }

  &-description {
    font-size: 0.875rem;
    color: var(--v-slate-base);
    clear: both;
    padding-top: 8px;
    padding-left: 4px;
  }

  &-subscription {
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;

    span {
      font-size: 0.875rem;
      font-weight: bold;
      color: var(--v-slate-base);
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 200px;
    }

    &-buttons.v-btn-toggle {
      display: block;
      margin-top: 4px;

      .v-btn {
        border: solid 1px var(--v-cloudy-base) !important;
        background-color: white !important;
        font-size: 0.875rem;
        color: var(--v-steel-base) !important;
        text-transform: none;
        min-width: 110px !important;
        height: 32px;
        border-radius: 4px;
        margin: 4px;
        font-weight: bold;
        opacity: 1;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;

        &.active {
          background-color: var(--v-peacock-base) !important;
          span {
            color: white !important;
          }
        }

        small {
          font-size: 0.75rem;
          font-weight: normal;
        }
      }
    }

    .service-subscribed {
      span {
        color: var(--v-peacock-base);
      }
    }
    .service-to-subscribe {
      align-items: left !important;
      text-align: left;
      justify-content: left;
      text-decoration: underline !important;
      &.enabled {
        color: var(--v-peacock-base);
      }
      &.disabled {
        color: var(--v-bluegrey-base);
      }
    }
    .list-item {
      cursor: pointer;
    }
  }
}
</style>
